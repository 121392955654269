.deleteConfirm-Modal .modal-content {
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgba(189, 189, 189, 1);
}
.deleteConfirm-Modal .modal-body {
  width: 90%;
  margin: auto;
}
.deleteConfirm-Modal .modal-dialog {
  max-width: 45% !important;
}
.deleteConfirm-text1 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  /* black */

  color: #151313;
  margin: auto;
  text-align: center;
}
.deleteConfirm-text2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  /* identical to box height, or 250% */

  text-align: center;

  /* black */

  color: #151313;
}
