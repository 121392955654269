.status-card {
    background-color:white;
    color: #606F88;
    border: 1.5px solid #E3EAF6;
    border-radius: 12px;
    padding: 1.5rem;
}

@media (min-width:767px) and (max-width: 1060px){

    .status-card {
        min-height: 180px;
    }
}

@media (max-width:767px){

    .status-card {
        padding: .5rem 1rem;
        min-height: unset;
    
    }

    .status-icon {
        width: 70%;
    }

}