.empty-state-container {
    width: 96%;
    border-radius: 10px;
    background: white;
    padding: 6rem;
    margin: 0 auto;
}

@media (min-width: 768px){
    .empty-state-container {
        padding:8rem;
        width: 98%;
    }
}