@media screen and (max-width: 1024px) {}
/* REMOVED BECAUSE IT BREAKS SIDEBAR AND SECTION LAYOUT */
@media screen and (max-width: 901px) {
    html,
    body,
    main,
    .middle-sec,
    .main-flex,
    .main-flex .inner-flex,
    .lft-chatlist,
    .chat-detail,
    .chating-sec,
    .chating-sec ul {
        /* height: auto; */
    }
    /*
    .right-side-box {
        padding: 0 !important;
    }
    .main-flex {
        margin: 0 0px;
    }
    header {
        top: 90px;
        position: absolute;
    }
    .hdr-rght {
        padding-left: 6px;
    }
    .header-main {
        margin-left: 0;
        padding: 17px 15px;
    }
    .middle-sec {
        padding: 0 0px;
    }
    .expand-lftmenu {
        display: none;
    } */
    /* .side-menu {
        width: 100%;
        bottom: unset;
    }
    .side-menu .side-nav {
        height: 90px;
        position: relative;
        padding: 20px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    } */
   /*  .logo {
        top: unset;
        bottom: 0;
        right: unset;
        position: inherit;
        text-align: inherit;
        padding: 0;
    }
    .logo img {
        width: 96px;
    } */
   /*  body:not(.menu-open) .main-side {
        transform: translateX(-120%);
    } */

    .chat-detail{

    }
    
    .main-side {
        position: fixed;
        overflow: auto;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: #222;
        background-image: url('../../images/images-chat/sidebar-bg.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        padding: 20px 0;
        z-index: 55;
        transition: all 300ms;
        width: 90%;
        max-width: 250px;
    }
    .menu-icon {
        display: block;
    }
    .close-menu {
        position: fixed;
        top: 0;
        left: 250px;
        background: #222;
        border-radius: 0px 6px 6px 0;
        padding: 4px;
        box-shadow: 4px 0 2px 0px rgb(84 84 84 / 17%);
    }
    main {
        /* padding: 104px 0 0; */
        margin-left: 0;
    }
    .main-flex .inner-flex.lftcht-sec {
        width: 100%;
        float: unset;
    }
    .main-flex .inner-flex.rghtcht-sec {
        margin-left: 0;
    }
    .main-flex .inner-flex {
        padding: 0 0;
    }
    .chatlist-hd {
        position: sticky;
        background: #f1f2f3;
        top: 170px;
        z-index: 1;
        padding: 0 15px 18px;
    }
    .top-sec {
        position: sticky;
        top: 121px;
        Z-INDEX: 1;
        BACKGROUND: #f1f2f3;
        margin-bottom: 0px;
        padding: 10px 0;
    }
    .lft-chatlist {
        padding-top: 0;
    }
    .withoutsrch-addpop {
        display: none;
    }
    .chatlst-srch {
        position: relative;
        margin-right: 0;
        margin-left: 15px;
        width: 100%;
    }
    .chat-fltrbtn {
        display: none;
    }
    .chatlist-card {
        margin-top: 10px;
        padding: 0px 15px 0;
    }
    .chat-header {
        border-radius: 0;
    }
    .chat-footer .chat-typinput input {
        border: 1px solid rgba(35, 39, 56, 0.5);
        box-shadow: 0px 4px 20px -5.71709px rgb(0 0 0 / 15%);
        border-radius: 4px;
        padding: 15px 78px 15px 15px;
    }
    .chatftr-main .sendmain-btn {
        display: none;
    }
    .chatftr-main {
        padding: 0 0px 0 40px;
    }
    .atachment-icon .dropdown-toggle svg {
        width: 34px;
    }
    .chating-sec ul {
        padding-bottom: 100px;
    }
    .chat-footer {
        position: fixed;
        background: #f2f2f2;
        padding: 20px 15px;
    }
    .reply-resultmsg {
        padding: 15px 0 5px;
    }
    .chating-sec ul li .card .modify-status p {
        font-size: 14px;
        line-height: 18px;
    }
    .chating-sec {
        padding: 25px 0px 0;
        border: unset;
    }
    .reply-resultmsg .rply-bg h6 {
        font-size: 14px;
    }
    .bruno-name p {
        font-size: 14px;
        line-height: 22px;
    }
    .chating-sec ul li .card .msg-descrp p {
        font-size: 14px;
    }
    .chating-sec ul li .card .msg-descrp .elumina-status p {
        padding-top: 10px;
        font-size: 14px;
    }
    .msginnerdtl-lst .lampadine-tp h5+h5 {
        margin-left: 0;
    }
    .msg-sender .elipsv-icon.dropdown.open .drpdown-chatdesrp.edit-elpsdrp {
        top: 0;
    }
    .elipsv-icon.dropdown.open .drpdown-chatdesrp ul {
        padding-bottom: 15px;
    }
    .msg-reciver .elipsv-icon.dropdown.open .drpdown-chatdesrp.edit-elpsdrp {
        top: 0;
    }
    .close-respns span svg {
        width: 24px;
    }
    .respondi-detail h6 {
        font-size: 13px;
        margin-bottom: 8px;
    }
    .respondi-detail p {
        font-size: 14px;
    }
    .mbil-chatfltr {
        position: sticky;
        background: #f1f2f3;
        top: 233px;
        z-index: 1;
        padding: 0 15px 10px;
        display: flex;
        justify-content: flex-end;
    }
    .top-sec h2 {
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
    }
    .mbil-chatfltr span {
        display: block;
        padding: 0 2px;
        cursor: pointer;
        margin-right: 3px;
    }
    .atchment-drpdown ul {
        padding-bottom: 15px;
    }
    .withsrch-addpop {
        display: block;
    }
    .msgsend-svgbtn {
        display: block;
    }
    .chat-mic+.msgsend-svgbtn {
        margin-left: 5px;
    }
    .navbar-menu {
        display: block;
    }
    .header-main .hdr-lft h3 {
        font-weight: 600;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 148px;
    }
    .header-main .login-prflnm h3 {
        font-weight: 600;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 92px;
    }
    .login-prflnm {
        margin-right: 8px;
    }
    .noti-count span {
        font-size: 10px;
        line-height: 21px;
        width: 18px;
        height: 18px;
    }
    .noti-count {
        top: -6px;
        right: -3px;
    }
    .notification img {
        width: 26px;
    }
    .menu-icon,
    .close-menu {
        display: block;
    }
    .drpdown-chatdesrp {
        z-index: 1;
    }
}


@media screen and (min-width: 768px) {}

@media screen and (max-width: 767px) {
    .container {
        padding: 0 15px;
    }
    .chathead-user h2 {
        font-size: 16px;
        line-height: 20px;
    }
    .chathead-user p {
        font-size: 14px;
        line-height: 20px;
        padding-top: 2px;
    }
    .chating-sec ul li .card {
        max-width: 100%;
        width: 100%;
        padding: 15px 15px;
    }
    .chating-sec ul li.msg-sender .card .badge-crclname {
        margin-left: 0;
        margin-right: 0;
        position: absolute;
        left: -35px;
        top: 0;
    }
    .badge-crclname span {
        font-size: 11px;
        line-height: 14px;
        width: 30px;
        height: 30px;
    }
    .chating-sec ul li .card .msg-head p {
        order: 2;
        font-size: 12px;
    }
    .chating-sec ul li .card .msg-head .msg-sendername p {
        font-size: 14px;
        font-weight: 600;
    }
    .chating-sec ul li .card .msg-head {
        flex-wrap: wrap;
    }
    .chating-sec ul li .card .msg-head .msg-sendername {
        margin-bottom: 5px;
    }
    .chating-sec ul li.msg-sender {
        padding-left: 40px;
    }
    .chating-sec ul li.msg-reciver {
        padding-right: 40px;
    }
    .chating-sec ul li.msg-reciver .card .badge-crclname {
        margin-right: 0;
        position: absolute;
        right: -35px;
        top: 0;
    }
    .chating-sec ul li .card .msg-descrp .sucess-preventomsg p {
        line-height: 20px;
        align-items: flex-start;
    }
    .chatdelet-modal .innerpop-descrp .pop-heading h4 {
        font-size: 20px;
        line-height: 24px;
    }
    .chating-sec ul li .msg-chk.slect-chat {
        left: -20px;
    }
    .chating-sec ul li .elipsv-icon {
        bottom: unset;
        top: 1px;
    }
    .msginnerdtl-lst .d-flex {
        flex-wrap: wrap;
    }
    /* .dualbtn-main {
        justify-content: center;
    } */
    .chating-sec ul li .card .msg-descrp .error-preventomsg p {
        align-items: flex-start;
    }
    .add-popup {
        padding: 40px 10px;
    }
    .pop-footer p {
        font-size: 14px;
        line-height: 20px;
    }
    .suppoty-link h4 {
        font-size: 16px;
        line-height: 20px;
    }
    .pop-dtllist ul li {
        font-size: 14px;
        line-height: 20px;
    }
    .innerpop-descrp .pop-heading h4 {
        font-size: 16px;
        line-height: 20px;
    }
    .atchmnt-tabngmenu {
        max-width: 345px;
        width: 345px;
    }
    .atchmnt-tabngmenu .nav-tabs:before {
        height: 0;
    }
    .atchmnt-tabngmenu .nav-tabs .tab-item {
        width: 46%;
        margin: 0px 5px;
    }
    .poptbng-scrol {
        max-height: 35vh;
    }
    .atchmnt-tabngmenu .nav-tabs .tab-item a {
        padding: 10px;
        text-align: center;
    }
    .contenuti-modal .innerpop-descrp {
        padding: 25px 15px;
    }
    .documnti-card {
        width: 340px;
    }
    .atchmnt-tabngmenu .nav-tabs .tab-item .a:before {
        height: 6px;
    }
    .documnti-card .creato-flx {
        flex-wrap: wrap;
    }
    .btn-gp .btn {
        font-size: 14px;
    }
    .chatlist-card ul li .card {
        padding: 10px;
    }
}

@media screen and (max-width: 375px) {
    .atchmnt-tabngmenu {
        max-width: 270px;
        width: 270px;
    }
    .documnti-card {
        width: 245px;
        padding: 10px 12px;
    }
    .atchmnt-tabngmenu .nav-tabs .tab-item {
        margin: 0px 0px;
    }
    .atchmnt-tabngmenu .nav-tabs .tab-item a {
        padding: 10px 4px;
        text-align: center;
    }
    .documnti-card .msginner-dtlhead p {
        font-size: 14px;
    }
    .documnti-card .msginner-dtlhead {
        padding: 10px 0;
    }
    .pdfatcmnt-icon svg {
        width: 18px;
    }
    .anteprima-modal .rlsttpwb-tabs {
        max-width: 300px;
    }
    .anteprima-modal .rlsttpwb-tabs .innerpop-descrp {
        padding: 25px 20px;
    }
    .aggiungi-pop .rlsttpwb-tabs {
        max-width: 296px;
    }
    .aggiungi-pop .rlsttpwb-tabs .innerpop-descrp {
        padding: 35px 20px;
    }
    .aggiungi-pop .rlsttpwb-tabs .innerpop-descrp .bl-single textarea {
        height: 150px;
    }
}