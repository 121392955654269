.step-show .tabs-div .tab-icon div {
    font-size: 14px;
}

.step-show .content-container {
    width: 100%;
    margin-top: 30px;
    border-radius: 10px;
    background: white;
/*     padding: 3rem 1rem;
 */}

.tab-bar.active-tab-bar-create {
    background: var(--primary);
    z-index: 2;
}

.general-btn {
    font-size: 16px;
    padding: 10px 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    border: none;
}

.white-btn {
    background-color: white;
    color: black;
}

.pink-btn {
    background-color: var(--primary);
    color: white;
}

.step-show .tab-icon .general-size {
    width: 126px;
    height: 58px;
}


.step-show .tab-icon .tab-icon-title {
    position: absolute;
    margin-left: 28px;
    margin-top: 16px;
}

.step-show .tabs-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 25px;
}

.step-show .affitti-comment {
    font-size: 14px;
    font-weight: 400;
    color: var(--lightgrey);
}

.step-show .comment-container {
    margin: 10px 0px;
}


.unit-empty-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 0px;
}

.popup-blue {
    border: 2px solid var(--lightblue);
}

.blue-popup-header {
    background: var(--lightblue);
}

.popup-purple {
    border: 2px solid var(--purple);

}

.purple-popup-header {
    background: var(--purple);
}

.popup-grey {
    border: 2px solid var(--grey);

}

.grey-popup-header {
    background: var(--grey);
}

.link-text {
    font-weight: 600;
    font-size: 16px;
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
}

.printer-btn {
    padding: 10px;
}

.empty-img {
    position: absolute;
    left: 45%;
    top: 45%;
}

.split-line {
    width: calc(100% - 50px);
}

.delete-icon-btn {
    background-color: #F1F2F3 !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    border: none;
}

.filter_sort_bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.filter_sort_bar .filter,
.sort {
    font-size: 14px;
    color: var(--lightgrey);
    cursor: pointer;
}

.filter_sort_bar>div>img {
    padding: 2px 6px;
}

.affitti-card-date {
    font-size: 12px;
    color: #333333;
    display: flex;
    flex-wrap: wrap;
}

.affitti-card-date span {
    padding-right: 15px;
}

.state-bar {
    display: flex;
    flex-direction: column;
    /* padding: 0px 40px 0px 10px; */
}

.state-bar .round-real-estate {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 180px;
}

.state-bar .round-real-estate img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
}

.state-bar .round-real-estate span {
    font-size: 20px;
    font-weight: 500;
    color: #3A3033;
}

.state-bar .round-real-estate .img-subtitle {
    font-size: 14px;
}

/* BARRA EDIT MODE*/
.state-bar .state-btn-group {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    margin-top: .5rem;
}

.state-bar .state-btn-group div {
    /* width: 160px;
    padding: 20px;
    border: 2px solid var(--edit-color);
    border-radius: 10px;
    color: var(--edit-color);
    margin-bottom: 24px;
    text-align: center; */
}

.state-bar .state-btn-group div:hover {
    background-color: var(--edit-color);
    color: white;
}

.state-bar .state-btn-group div.active {
    background-color: var(--edit-color);
    color: white;
}


.edit-btn {
    background-color: var(--edit-color);
    padding: 10px;
    color: white;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}

.edit-icon-btn {
    width: 44px;
    height: 44px;
    border-color: var(--edit-color) !important;
}

.slider-wrapper {
    overflow: hidden;
}

.slides-container {
    width: 100%;
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth;
    list-style: none;
    margin: 0;
    padding: 0;
}

.slide {
    width: 100%;
    height: 100%;
    flex: 1 0 100%;
}

.prev,
.next {
    position: absolute;
    top: 55%;
    cursor: pointer;
}

.prev {
    left: -5px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid var(--edit-color);
}

.next {
    right: -5px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid var(--edit-color);
}

.edit-white {
    background-color: transparent;
    border: 2px solid var(--edit-color);
    color: var(--edit-color);
}

.mobile-state-bar {
    margin-bottom: 0.25rem;
}

.mobile-state-bar .select-state {
    width: 100%;
    border: transparent;
    border-radius: 4px;
    outline: none;
    padding: 10px;
}

.mobile-state-bar .select-state option {
    font-size: 14px;
    padding: 10px;
}

.mobile-state-bar .select-state option:checked {
    color: var(--primary);
}

.white-btn {
    padding: 10px 20px;
}

.white-btn:hover {
    color: black;
}

.card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.table-border.none-border {
    border: none !important;
}

.conductor_view_top {
    display: flex;
    justify-content: space-between;
}

.conductor_view_top_left {
    margin-right: 1rem;
}

@media (max-width:1290px) {
    .conductor_view_top_left {
        margin-right: 0;
    }

    .conductor_view_top {
        flex-direction: column;
    }
}

div.b {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #000000;
}