/*PRIORITA'*/

.priorityTag {
    display: inline-block;
    border-radius: 4px;
    padding:.2rem .4rem;
    font-size: 14px;
}

.priorityTag.mini {
    padding:.1rem .2rem;
    font-size: 12px;
}

.priorityTag.low {
    background-color: #ECF1FF;
    color: #2F80ED;
}

.priorityTag.medium {
    background-color: #FFF9EC;
    color: #F2994A;
}

.priorityTag.high {
    background-color: #FFECEC;
    color: #EB5757;
}

