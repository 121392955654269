/*GRIGLIA MESE*/

.bg-white {
    background-color: white;
}

div .fc-view-harness.fc-view-harness-active {
    background-color: white !important;
}

.fc-scrollgrid-sync-table tbody tr td{
    border: 1px solid lightgray !important;
    overflow: hidden;
}

/*GRIGLIA GIORNI SETTIMANA*/
.fc-scrollgrid-section.fc-scrollgrid-section-header > th {
    border-bottom: 1px solid lightgray !important;
    padding: 0 !important;

}

.fc-scrollgrid-sync-inner a {
    color: var(--lightgrey)!important;
    border-color: rgb(255 255 255) !important;
}

.fc-day-today {
    background-color: rgba(173, 216, 230, 0.25) !important; 
  }

/*BOTTONI AVANTI E INDIETRO*/

/* .fc-prev-button.fc-button.fc-button-primary,
.fc-next-button.fc-button.fc-button-primary{
background-color: #fff !important;
border:none;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;

} */

/* .fc-next-button.fc-button.fc-button-primary {
    margin-left: .5rem !important;
}

.fc-icon.fc-icon-chevron-left,
.fc-icon.fc-icon-chevron-right {
    color: black;
} */

/* TITOLO MESE */
.fc .fc-toolbar-title {
    font-size: 1.5rem !important; 
    text-transform: capitalize;
}

/* CAMBIO COLORE CASELLA DEL GIORNO */
.fc .fc-daygrid-day.fc-day-today {
    background-color: rgb(40 182 255 / 15%);
}

/*MESI-SETTIMANE-GIORNO*/

/* .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr > .fc-toolbar-chunk:nth-of-type(2) > .fc-button-group {
    border: 2px solid white !important;
    border-radius: 0.5rem;
}

.fc-dayGridMonth-button.fc-button.fc-button-primary,
.fc-dayGridMonth-button.fc-button.fc-button-primary:active
{
    background-color: #eee;
    border: none;
    color: rgba(0, 0, 0, 0.382);
}

.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active {
    background-color: #fff;
    border: none;
    color: black;
    box-shadow: -2.73171px -2.73171px 5.46341px rgba(255, 255, 255, 0.55), 2.04878px 2.04878px 5.46341px rgba(0, 0, 0, 0.05);
} */

/* .fc-timeGridWeek-button.fc-button.fc-button-primary,
.fc-timeGridWeek-button.fc-button.fc-button-primary:active {
    background-color: #eee;
    border: none;
    color: rgba(0, 0, 0, 0.382);
} */

/* .fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active {
    background-color: #fff;
    border: none;
    color: black;
    box-shadow: -2.73171px -2.73171px 5.46341px rgba(255, 255, 255, 0.55), 2.04878px 2.04878px 5.46341px rgba(0, 0, 0, 0.05);

} */

/* .fc-timeGridDay-button.fc-button.fc-button-primary,
.fc-timeGridDay-button.fc-button.fc-button-primary:active {
    background-color: #eee;
    border: none;
    color: rgba(0, 0, 0, 0.382);
} */

/* .fc-timeGridDay-button.fc-button.fc-button-primary.fc-button-active  {
    background-color: #fff;
    border: none;
    color: black;
    box-shadow: -2.73171px -2.73171px 5.46341px rgba(255, 255, 255, 0.55), 2.04878px 2.04878px 5.46341px rgba(0, 0, 0, 0.05);
} */

/* GIORNI DELLA SETTIMANA */

.fc-col-header-cell-cushion {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
}

/* GRIGLIA SETTIMANA */

.fc-timegrid-body > .fc-timegrid-slots > table > tbody > tr:nth-child(even) {
    border-bottom: 1px solid lightgray;
}

/*TOOLTIP STILE*/
.event-style {
    background-color: rgb(255, 255, 255);
    border: 1px solid darkgray;
    border-radius: .5rem;
}

.event-style > * {
    background-color: rgb(255, 255, 255);
    opacity: 1;
    color: black;
    text-align: left;
    border-bottom-color: transparent;
}

.event-style > .tooltip-arrow  {
    background-color: transparent;
    color: transparent;
    border: none;
    border-bottom-color: transparent;
}



.custom-event-content {
    display: flex;
    align-items: center;
    /* Aggiungi ulteriori stili qui per allineare il contenuto come desideri */
}

.dot {
    margin-right: 5px; /* Distanza tra il dot e il testo */
    /* Aggiungi ulteriori stili per il dot qui, come colore, dimensione, ecc. */
}


.custom-event-content span {
    display: inline-block;
    max-width: 100%; /* Adatta questo valore in base alla larghezza della casella dell'evento */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}
