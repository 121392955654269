.partner-hero {
    height: 400px;
    background-image: url(https://images.pexels.com/photos/323780/pexels-photo-323780.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}


.partner-hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(19,44,106);
    background: linear-gradient(177deg, rgba(19,44,106,1) 0%, rgba(33,75,182,0.7623643207282913) 35%, rgba(84,128,240,0.3926164215686274) 100%);}

.partner-hero-search {
    -webkit-font-smoothing: antialiased;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.16);
    color: #425457;
    padding: 2rem 3rem;
    text-align: center;
    text-rendering: optimizeLegibility;
    width: 65%;
    z-index: 2;
    position: absolute;
    bottom: -10%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 700px;
}

.partner-hero-double-input {
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 25px;
    padding: .5rem;
}

.partner-hero-search-input > .input-group-text {
    background: none;
    border: none;
}

.partner-hero-search-input > .partner-hero-search-input-text {
    background: none;
    border: none;
}

.partner-hero-search-input > .partner-hero-search-input-text:focus,
.partner-hero-search-input > .partner-hero-search-input-text:focus-visible {
    outline: none; /* Rimuove l'outline di default quando l'input è in stato di focus */
    /* Altri stili personalizzati per l'input in stato di focus */
    border-color: transparent;
    outline: 0;
    box-shadow: unset;
}

.partner-separator-input {
    background: #ced4da;
    color:transparent;
    width: 1px;
}

.partner-hero-double-input > button {
    appearance: none;
    border: none;
    background: #83112f;
    border-radius: 25px;
    padding: 0.5rem 1rem;
    color:white;
    font-weight: bold;
}


.partner-categories {
    height: 400px;
    background-color: white !important;
    padding: 5rem 2rem 2rem 2rem;
}

.partner-category-card {
    width: 100%;
    height: 180px;
    min-width: 180px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.03), 0 3px 1px -2px rgba(0,0,0,.07), 0 1px 5px 0 rgba(0,0,0,.06);
    border-radius: 12px;
    text-align: center;
}

.partner-category-card  img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 100%;
    height: 75%;
    object-fit: cover;
}

.partner-categories-carousel {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}

.partner-categories-carousel::-webkit-scrollbar {
    display: none;
}

.partner-app {
    height: 500px;
    background-color: rgb(255, 255, 255) !important;
    padding: 2rem;
}

.img-fornitori-app {
    max-height: 300px;
    width: auto !important;
}


/* LISTA FORNITORI */

.carousel-card-partner {
    width: 100%;
    height: 150px;
    min-width: 180px;
    background-color: transparent !important;
    border-radius: 12px;
    text-align: left;
}

.carousel-card-partner  img {
    border-radius: 12px;
    width: 100%;
    height: 80%;
    object-fit: cover;
}

.partner-list-container {
    background-color: white;
    padding: 2rem;
    border-radius: 12px;
}


@media (max-width:1000px){
    
    .partner-list-container {
        padding: 2rem 1rem;
    }

    .partner-hero-search {
        -webkit-font-smoothing: antialiased;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.16);
        color: #425457;
        padding: 2rem 3rem;
        text-align: center;
        text-rendering: optimizeLegibility;
        width: 95%;
        z-index: 2;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        max-width: 700px;
        
    }
    

}

/*FITRI FORNITORI ANIMAZIONE*/

.filterItemFornitore {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding: 0.5rem 1rem;
}

.filterItemFornitore.checked {
border-color: #017A3D;
}

.filterItemFornitore.checked {
    border-color: #017A3D;
}

.filterItemFornitoreText {
    text-transform: capitalize;
    transition: all 0.5s ease-in-out;
}

.filterItemFornitoreIcon {
    transform: scale(0);
    margin-right: 0;
    transition: all 0.5s ease-in-out;
}

.filterItemFornitoreIconActive {
    transform: scale(1);
    margin-right: 0.5rem;
    transition: all 0.5s ease-in-out
}

.filterItemFornitore.checked .filterItemFornitoreIconActive {
    color: #017A3D;
}

.filterItemFornitore.checked .filterItemFornitoreText {
    color: #017A3D;
}

/*CARD FORNITORI ANIMAZIONE*/

.filterCardFornitoreText {
    text-transform: capitalize;
    margin-left: -1rem !important; 
    transition: all 0.5s ease-in-out;
}

.filterCardFornitoreIcon {
    transform: scale(0);
    margin-right: 0;
    transition: all 0.5s ease-in-out;
}

.filterCardFornitoreIconActive {
    transform: scale(1);
    margin-right: 1.5rem;
    transition: all 0.5s ease-in-out
}


.fornitori-location {
    padding: 1rem;
    background: #dbe1e3;
    border-radius: 20px;
}

.bg-custom-fornitore {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; 
    min-height: 120px;
    border-radius: 12px;
    min-width: 180px;
    color: transparent;
    position: relative;


}

.logo-fornitori {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 5%;
    left: 2%;
    border-radius: 12px;
    object-fit: cover;

}


@media (max-width:720px){
    
    .partner-hero-double-input {
        display: block;
        border: none;
        border-radius: none;
        padding: unset;
    }
    
    .partner-hero-search-input {
        margin-bottom: 1rem;
    }

    .partner-hero-search-input > .input-group-text {
        background: none;
        border: none;
        border: 1px solid #ced4da;
        border-radius: 25px;
        padding: .5rem;
        border-right: unset !important;
    }
    
    .partner-hero-search-input > .partner-hero-search-input-text {
        background: none;
        border: none;
        border: 1px solid #ced4da;
    border-radius: 25px;
    padding: .5rem;
    }

    .partner-hero-search-input-text {
        width: calc(100% - 35px);
    }
    
    .partner-hero-search-input > .partner-hero-search-input-text:focus,
    .partner-hero-search-input > .partner-hero-search-input-text:focus-visible {
        outline: none; /* Rimuove l'outline di default quando l'input è in stato di focus */
        /* Altri stili personalizzati per l'input in stato di focus */
        border-color: transparent;
        outline: 0;
        box-shadow: unset;
    }
    
    .partner-separator-input {
        background: #ced4da;
        color:transparent;
        width: 1px;
        display: none;
    }
    
    .partner-hero-double-input > button {
        appearance: none;
        border: none;
        background: #83112f;
        border-radius: 25px;
        padding: 0.5rem 1rem;
        color:white;
        font-weight: bold;
        width: 100%;
    }

    .partner-app {
        height: fit-content;
        background-color: rgb(255, 255, 255) !important;
        padding: 2rem;
    }
    
    

}
