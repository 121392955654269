.body {
    background: #F5F5F5;
    width: auto;
    height: 100%;
}

.calculator-container {
    /* margin-left: 2%; */
    max-width: 800px;
    margin: 0 auto;

}

.block {
    margin-bottom: 3%;
}

.title {
   
    /* margin-left: 1%; */
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    /* line-height: 50px; */

    /* black */

    color: #151313;
}

.button-group {
    /* padding-top: 20px; */
    /* padding-left: 5%; */
    margin-top:1rem;
}

.top-btn {

    width: 140px;
    height: 40px;
    display: flexbox;
    flex-direction: row;
    align-items: stretch;
    background-color: white;
    
    border-radius: 6px;
    border-color: white;

    /*  font-family: 'Mulish';*/
    font-weight: 500;
    font-size: 16px;
    border: 1px solid lightgray;

}

.top-btn-icon {
    display: flexbox;
    margin-left: 1%;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 6px;
    border-color: white;
    border: 1px solid lightgray !important;
}

.top-btn::after {
    border-color: white;
}

.table-inline input {
    border: none;

}

.table-section {
    /* margin-left: 5%; */
   /*  margin-right: 3%; */
    margin-top: 1rem;
    height: 300px;
    border-radius: 16px;
    background-color: white;
    
}

.table_calculator {
    margin: .5rem 3%;
    width: 94%;
    /* height: 150px; */
    overflow-y: scroll;
    /* border-top: none; */
    border-bottom: 1px dotted rgba(0, 0, 0, 0.3);


}

.w-20{
    width: 40px !important;
}

.w-40 {
    width: 40px !important;
}

.colNumeric .react-numeric-input {
    width: 50% !important;
}

.diffdate .react-numeric-input {
    width: 40% !important;
}

.table-header {
    margin-top: 5%;
    /*font-family: 'Mulish';*/
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /* identical to box height */
    border-bottom: 1px Solid rgba(200, 200, 200, 0.5) !important;
    align-items: center;

    color: #000000;
}

table.body {
    text-align: right;
}

.table-line {
    height: 30px;
    border-right: 1px solid rgba(0, 0, 0, 0.3) !important;
    padding-right: 10px;
    text-align: end;
}

.table-line_symbol {
    height: 30px;
    border-right: 1px solid rgba(0, 0, 0, 0.3) !important;
    padding-right: 10px;
    text-align: start;
}

.input_number {
    width: 100%;
    text-align: end;
}

.underline {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.group-input {
    margin-top: 0.5rem;
    margin-left: 1%;
}

.input-section {
    height: 40px;
    width: 92%;
    background-color: white;
    border: 1px solid #BDBDBD;
    padding-right: 10px;
    text-align: end;
   /*  padding-top: 10px;  BREAKS CALCULATOR SELECT FIELDS*/

    border-radius: 6px;
}

.table_calculator {
    width: 94%;
}

.input-section1 {
    height: 40px;
    background-color: white;
    border: 1px solid #BDBDBD;
    padding-right: 10px;
    text-align: end;
    padding-top: 10px;

    border-radius: 6px;
}

.group-input-button {
    margin-left: 10%;
}

.group-calculator {
    margin-top: 1rem;
    /* margin-left: 5%; */
}

.calculator-button {
    width: 35%;
}

.cal-button-primary {

    width: 40px;
    height: 40px;
    background: #D2D3DA;
    border-radius: 10px;
    border: none;
    align-items: stretch;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.cal-button-primary:hover {
    background: #D2D3DA;
    border: none;
}

.cal-button-primary :active {
    background: #D2D3DA;
    border: none;
}

.cal-button-second {
    width: 40px;
    height: 40px;
    background: #83112F;
    border-radius: 10px;
    border: none;
    color: #fff;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.cal-button-second:hover {
    background: #83112F;
    border: none;
    color: #fff;
}

.cal-button-default {
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 10px;
    border: none;
    color: #000;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.cal-button-default:hover {
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 10px;
    border: none;
    color: #000;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.mr-13 {
    margin-right: 4%;
}

.cal-button-default:active {
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 10px;
    border: none;
    color: #000;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.cal-button-default:hover {
    background: white;
    border: none;
}

.cal-button-third {
    width: 40px;
    height: 40px;
    background: #606F88;
    border-radius: 10px;
    border: none;
    color: #fff;
    flex: none;
    font-size: 12px;
    order: 0;
    flex-grow: 0;
}

.cal-button-third:hover {
    background: #606F88;
    border: none;
    color: #fff;
    font-size: 10;

}

.cal-button-forth {
    width: 40px;
    height: 40px;
    background: #83112F;
    border-radius: 10px;
    border: none;
    color: #fff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 3%;
}

.cal-button-forth:hover {
    background: #610f25;
    border: none;
    color: #fff;
}

.cal-button-forth:disabled {
    background: #bbb9b9;
    border: none;
    color: #fff;
}

.dropmenu {
    min-width: 100%;
}

.cal-button-default-1 {
    width: 100px;
    height: 35px;
    background: white;
    border-radius: 16px;
    border: none;
    font-size: 14px;
    /*font-family: "Mulish";*/
    flex: none;
    order: 0;
    flex-grow: 0;
}

.cal-button-default-1:hover {
    background: white;
    border: none;
}

.resultBoard {
    border: 1px solid #333333;
    width: 33%;
    text-align: end;
    padding: 5px;
    position: relative;
}

.pt-0 {
    padding-top: 0px !important;
}

.resultBoard1 {
    border: 1px solid #333333;
    width: 33%;
    text-align: end;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
}

.input-calc-symbol {
    height: 50px;
    padding-left: 1.3rem !important;
    width: 100%;
    border-radius: 6px;
}

.inputText1 {
    text-align: end;
    width: 100%;
    padding-left: 1.3rem;
    /*font-family: 'Mulish';*/
    font-style: normal;
    height: 50px;
    font-size: 20px;
    border: 1px solid #BDBDBD;
    border-radius: 6px;
}

.InputNumberText {
    text-align: end;
    width: 100%;
    height: 50px;
    padding-right: 15px;
    border-radius: 6px;
    border: 1px solid #BDBDBD;
}

.spanInputText {
    position: absolute;
    right: 6px;
    padding-top: 5px;
    top: 6px;
}

.divInput {
    position: relative;
}

.spanInput {
    position: absolute;
    left: 31.5%;
    padding: 5px;

}

.getDate {
    border: 1px solid #9c8181;
    width: 33%;
    text-align: end;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
}

.dateInput {
    width: 40%;
    height: 50px;
    border: 1px solid #BDBDBD;
    border-radius: 6px;
}

.DivInputText {
    width: 100%;
    position: relative;
}

.divInputText {
    width: 40%;
    position: relative;
}

.totalInput {
    position: relative;
}

.calClick {
    position: absolute;
    width: 100%;
    top: -60%;
    left: 45%;
}

.spanInput1 {
    position: absolute;
    left: 6px;
    padding-top: 8px; 
    top: 5px;
}

.spanInputPercentage {
    position: absolute;
    right: 6px;
    /* padding-top: 8px; */
    top: 5px;
}
.row1 {
    width: 33%;
}

.row2 {
    width: 16.6%
}

.row3 {
    width: 50%
}

.aggiungi {
    padding: 0 !important;
}

.btn-primary1 {
    height: var(--btn-default-size) !important;
}

.mg {

    margin-right: 8%;
}

.mg-13 {
    margin-top: 13px;
}

.mg-top {
    margin-top: 50px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

hr {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}


.tooltipCalc > .tooltip-inner {
    min-width: 300px;
    text-align: start;
    
}


.calc-col-save {
    background-color: white;
    border-radius: 16px;
    padding: 1rem 1.5rem;
    min-width: 200px;

}

.calc-item-card {
    padding: .5rem 0;
}

.calc-item-container {
    border-bottom: .5px solid rgba(46, 46, 47, 0.2);
}

.reuse-button {
    border-radius: 20px;
    background:#eee;
    color: #333333;
    border: none;
    outline: none;
    font-size: 12px;
}