
/* .attivitàCard::after {
    content:'';
    display: block;
    width:15px;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: rgb(156, 42, 169);
    z-index: 2;
} */

.attivitàCard {
    background-color: #ffffff;
    width:98%;
    margin: 0 auto 1rem auto;
    height: 80px;
    padding:.8rem .5rem;
    position: relative;
    z-index: 0;

	display: flex;
	align-items: center;
	padding: 15px 20px;
	justify-content: space-between;
	border: 1px solid rgba(35, 39, 56, 0.25);
	border-radius: 5px;	

	margin-left: 12px;
	transition: border .5s ease-in-out;
}

.attivitàCard:hover {
	border-left: 12px solid var(--primary);

}

.items-activity-container {
    display: flex;
    width: 300px;
}

.unit-assignee-circle {
    text-align: center;
    background-color: rgb(255, 30, 94);
    width: 30px;
    height: 30px;
    padding: 1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
}

.col-33 {
    width: 33%;
}

/*INLINE EDITS*/

.inline-date-sal {
    border: 1px solid transparent !important;
    border-radius: 2px !important;
    font-size: 14px !important;
    position: relative !important;
    padding: 0 !important;
}

.inline-date:focus,
.inline-date:focus-visible,
.inline-date:focus-within{
    border: 1px solid lightgray;

}


.inline-date::-webkit-calendar-picker-indicator  {
    opacity: 0;
    position: absolute;
    width: 100%;
}

.inline-date-sal::-webkit-inner-spin-button,
.inline-date-sal::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}


.inline-text-sal {
    border: 1px solid transparent !important;
    padding: 0 !important;
    font-weight: bold !important;
    border-radius: 2px !important;
}

.inline-text:focus,
.inline-text:focus-visible,
.inline-text:focus-within{
    border: 1px solid lightgray;
}

.activity-dropdown {
    width: fit-content;
    border-radius: 6px;
    background-color: white;
    position: absolute;
    padding: 1rem;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    z-index: 1 !important;
}

.inline-select {
    width: 100%;
}

.attività-card-map {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem .5rem;
    max-width: 330px;
    border-bottom: 1px solid rgba(35, 39, 56, 0.1);
    margin: 0 0.5rem!important;

}

@media (min-width:1100px){
    .attività-card-map {
        padding: 1rem;
    }
}



.activity-assignee-circle{
    background-color: rgb(142, 170, 222);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}


/*CARD MOBILE*/

.attività-card-mobile-container {
    display: flex;
    position: absolute;
    left: 50%;
    bottom:40px;
    z-index:9999999999999999999;
    width: 85% !important;
    transform:translateX(-50%) ;
    height: fit-content;
    
}


.attivitàCardMapMobile {
    margin: .5rem 0;
    min-width: 200px;
    height: fit-content;
    background-color: rgb(255, 255, 255);
    padding: .5rem;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, .1);
    margin-left: 1rem;
    max-height: 100px;
}



.trans-div {
    background-color: transparent;
    position: absolute;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
    z-index: 0;
}

.zindexSAL {
    z-index: 2 !important;
}