.attività-options {
    width: 40px;
    height: 40px;
    border: 1px solid black;
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}

.activity-details {
    margin-top: 1rem;
    background-color: #FFFFFF;
    width: 100%;
    height: fit-content;
    padding:3rem;
    border-radius: 1rem;
}

@media (max-width: 576px){
    .activity-details {
        padding: 1rem;
    }
}

@media (max-width: 767px){
    .activity-details {
        padding: 2rem;
    }
}

.divider {
    margin-top: -.2rem;
}

.bold {
    font-weight: 900 !important;
}