.dash-title {
  color: #000000;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 100px;
  line-height: 126px;
  display: flex;
  align-items: center;
  text-align: center;

  height: 100vh;
}

/*DASHBOARD */

.dashbutton {
  display: block !important;
  background-color: white;
  text-align: center;
  padding:.5rem .1rem !important;
  color:#606f88;
  border-radius: 6px;
  width:13%;
  transition: all .5s ease;
}

.dashbutton:hover {
  color:#606f88;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.dashbutton img {
  max-width: 50px;
  height: 40px;
}

.dashbutton p {
  margin-top: 1rem;
}

.margin-dashboard-0 {
  margin-top: 0 !important;
}

.dashboard-container-bg {
  background-color: white; 
  padding: 2rem;
}

.dashboard-container {
  border-radius: 5px;
/*   padding:2rem;
 */  min-height: 70vh;
  display: block;
  position: relative;

  margin: 1rem 0 !important;

}

.div-title-container {
  margin: 1rem;
  margin-right: 0;
  margin-left: 0;
}

.shortcutCard{
  padding: 1rem;
  background: #FFFFFF;

  border: 1px solid #E3EAF6;
  border-radius: 12px;

  color: #252C3A !important;

  min-width: 160px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shortcutCard {
  margin-right: 1rem;
}

.shortcuts-container {
  overflow-x: scroll;
  overflow-y: hidden;
}

.shortcuts-container::-webkit-scrollbar {
  display: none;
}

.cardUnitDashboard {

}

.cardUnitDashboardImg {
  width: 350px;
  height: 170px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  transition: all .5s ease-in-out;
}

.cardUnitDashboardImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



@media (max-width: 1200px) {

  .dashbutton {
      background-color: white;
      text-align: center;
      padding:1rem;
      color:#606f88;
      border-radius: 6px;
      width:12%;
  }

  .dashbutton img {
      width: 90%;
  }
  
  .dashbutton p {
      display: none;
  }
}

@media (max-width: 900px) {

  .dashbutton {
      background-color: white;
      text-align: center;
      padding:.5rem;
      color:#606f88;
      border-radius: 6px;
      width:12%;
  }

  .dashbutton img {
      width: 90%;
  }
  
  .dashbutton p {
      display: none;
  }
}

.dashContainer {
  background-color: white;
  padding: 1rem;
  width: 100%;
  margin: 0;
  border: 1px solid #E3EAF6;
  border-radius: 12px;
  overflow-y: auto;
  overflow-x: hidden;
}

.space-hr {
  display: inline-block;
  border: 1px solid #ced4da;
}

.rc-donut-dashboard {
  padding: 2rem;
  margin: 0 auto;
}

@media (min-width:768px) {
  .dashContainer {
      max-height: 300px;
      min-height: 300px;
  }
  
}

.dashContainer.chart {
  text-align: center;
}

.dchart {
  width: 100% !important;
  height: auto !important;
  position: relative !important;
  margin-top: 1rem;
}

@media (min-width: 1800px){
  .chart-cont-big{
    width: fit-content;
    margin: 0 auto;
  }
}

@media (min-width:992px) and (max-width:1199px){
  .dchart {
      width: 80% !important;
      height: auto !important;
      position: relative !important;
  }
}

@media (max-width:767px){
  .dchart {
      width: 50% !important;
      height: auto !important;
      position: relative !important;
  }
}

@media (max-width:576px){
  .dchart {
      width: 80% !important;
      height: auto !important;
      position: relative !important;
  }
}

.dchart-innertext {
  font-size: 2rem;
}

.dchart-legend-item:nth-child(1) {
  transform: translate(500px, 300px) !important;
}

.dchart-legend-item:nth-child(2) {
  transform: translate(500px, 340px) !important;
}

.dchart-legend-item:nth-child(3) {
  transform: translate(500px, 380px) !important;
}

.dchart-legend-item-label  {
  font-size: 1.2rem !important;
}

/*STATUS TAG dashboard*/

.statusTag {
  display: inline-block;
  border-radius: 4px;
  padding:.2rem .4rem;
  font-size: 14px;
}

.statusTag.small {
  padding:.1rem .2rem;
  font-size: 12px;
}

.statusTag.aperta {
  background-color: #ECF1FF;
  color: #2F80ED;
}

.statusTag.incorso{
  background-color: #FFF9EC;
  color: #F2994A;
}

.statusTag.risolta {
  background-color: #f4ffec;
  color: #1fb21d;
}

.statusTag.ritardo {
  background-color: #FFECEC;
  color: #EB5757;
}


.card-scadenze {

  padding:.2rem .4rem;
  border-radius: 4px;
  
  border-left: 6px solid rgb(18, 116, 201);
  border-top: 1px solid #E9EAEC;   
  border-bottom: 1px solid #E9EAEC;
  border-right: 1px solid #E9EAEC;  
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  
}

.scadenze-details {
  line-height: normal;
}

/*CAROUSEL */

@media(min-width: 2300px){
  .dashCol {
      width: 20% !important;
  }
}

@media(min-width: 1600px){
  .dashCol {
      width: 30% !important;
  }
}


.card-car {
  background-color: white;
  width:100%;
  height: auto;
  padding: 1rem;
  border-radius: 1rem;
  min-width: 298px;
}

.card-car-header {
  background-position: center;
  background-size: cover;
  height: 120px;
  width: 100%;
  border-radius:1rem;
  position: relative;
}

.card-car-initials{
  display: inline;
  padding: .8rem;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  bottom: -20%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-weight: bold;
  background-color: #606F88;
}

.card-car-body {
  margin-top: 2rem;
  text-align: center;
}



/*EDIT 3D CAROUSEL*/



.slider-single-content{
  border-radius: 1rem;
}

.carouselph {
  height: 400px;
  background-color: transparent;
  width: 100%;
}

.react-3d-carousel .slider-container .slider-left div {
  border:none !important
}

.react-3d-carousel .slider-container .slider-right div {
  border:none !important
}

.val-preview .modal-dialog{
  max-width: 60%;
}

.val_price {
  line-height: 15px !important;
}


/*SLIDER NUOVO*/

.slider-dashboard-container,
.shortcuts-dashboard-container
 {
  position: relative;
}

.slider-dashboard-left-arrow ,
.shortcuts-dashboard-left-arrow
{
  /* position: absolute;
  left: -2%;
  top: 50%;
  transform: translateY(-50%); */
  appearance: none;
  outline: none;
  border: none;
  background: transparent;
  color: #252C3A !important;

  opacity: .2;
}

.slider-dashboard-right-arrow,
.shortcuts-dashboard-right-arrow
  {
  /* position: absolute;
  right: -2%;
  top: 50%;
  transform: translateY(-50%); */
  appearance: none;
  outline: none;
  border: none;
  background: transparent;
  color: #252C3A !important;

  opacity: .2;
  transition: all .5s ease-in-out;

}


.slider-dashboard-left-arrow 
{
  top: 40%
}

.slider-dashboard-right-arrow
 {

  top: 40%

}

.slider-dashboard-right-arrow:hover,
.slider-dashboard-left-arrow:hover,
.shortcuts-dashboard-left-arrow:hover,
.shortcuts-dashboard-right-arrow:hover
 {
  opacity: 1;
}



/* .address-card-dashboard {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 20px);

} */

/* VALUTAZIONE OVERLAY CARD*/

.cardUnitDashboardImgOverlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 0%;
  background-color: rgba(0,0,0,0);
  color: white;
  text-align: center;
  transition: all .5s ease-in-out;
}

.cardUnitDashboardImg:hover .cardUnitDashboardImgOverlay {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all .5s ease-in-out;
  background-color: rgba(0,0,0,.5);
  cursor: pointer;
  width: 100%;
  height: 100%;

}


.cardDashboardVal {
  font-weight: bold;
  font-size: 18px;
}

.cardUnitDashboardContentText{
  color: #252C3A !important;

}


@media(max-width:767px ){
  .dashboard-container {
    padding: 1rem 0 !important;
    margin: 1rem 0 !important;
  }

  .div-title-container {
    margin: .5rem 0 ;
  }
}

