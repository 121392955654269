.register-success-subtitle {
  width: 47%;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */

  display: flex;
  align-items: center;
  text-align: center;

  /* black */

  color: #3a3033;
  display: flex;
  margin: auto;
  padding-top: 31px;
}
.register-success-description {
  padding-top: 13px;
  width: 42%;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;
  text-align: center;

  /* black */

  color: #3a3033;
  display: flex;
  margin: auto;
}
