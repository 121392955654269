.sub-menu-card-container {
	margin-left: 100px;
}

.state-bar.no-img-state-bar {
	padding-left: 0px;
}

.save-change-btn-group-stabili {
	position: absolute;
	right: 12px;
	top: 70px;
}

@media (max-width: 900px) {
	.save-change-btn-group-stabili {
		right: 12px;
		top: 125px;
	}
}


@media (min-width: 900px) {
	.tab-container {
		min-width: 150px;
	}
}

.w-20p {
	width: 20%;
}

.green-color {
	color: #24d375;
}

.red-color {
	color: #d32424;
}
