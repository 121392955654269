.pagina-hero {
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    border: unset;
}


.pagina-hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(19,44,106);
    background: linear-gradient(177deg, rgba(19,44,106,1) 0%, rgba(33,75,182,0.7623643207282913) 35%, rgba(84,128,240,0.3926164215686274) 100%);
}


.pagina-hero-search {
    -webkit-font-smoothing: antialiased;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.16);
    color: #425457;
    padding: 2rem 3rem;
    text-align: center;
    text-rendering: optimizeLegibility;
    width: 65%;
    z-index: 2;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
}

.avatar-pagina {
    height: 100px;
    width: 100px
}

.edit-icon-pagina {
    position: absolute;
    width: 50px;
    height: 50px;
    max-width: 50px !important;
    max-height: 50px !important;
    box-shadow: 4px 4px 8px rgba(0,0,0,.16);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    top: -10px;
    right: -10px;
    cursor: pointer;
}

.edit-icon-pagina-heart {
    position: absolute;
    width: 50px;
    height: 50px;
    max-width: 50px !important;
    max-height: 50px !important;
    box-shadow: 4px 4px 8px rgba(0,0,0,.16);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    top: -10px;
    right: -1px;
    cursor: pointer;
}

.edit-icon-pagina-bookmark {
    position: absolute;
    width: 50px;
    height: 50px;
    max-width: 50px !important;
    max-height: 50px !important;
    box-shadow: 4px 4px 8px rgba(0,0,0,.16);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    top: -10px;
    right: 50px;
    cursor: pointer;
}

.hero-img-input-label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;    
}


.pagina-categories {
    min-height: 300px;
    background-color: white !important;
    padding: 4rem 2rem 2rem 2rem;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.16);
    position: relative
}

.pagina-categories-contact {
    background-color: white !important;
    padding: 4rem 2rem 2rem 2rem;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.16);
    position: relative
}

.partner-category-card {
    width: 100%;
    height: 180px;
    min-width: 180px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.03), 0 3px 1px -2px rgba(0,0,0,.07), 0 1px 5px 0 rgba(0,0,0,.06);
    border-radius: 12px;
    text-align: center;
}

.partner-category-card  img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 100%;
    height: 75%;
    object-fit: cover;
}

.pagina-categories-carousel {
    display: flex;
    flex-wrap: wrap;
    max-height: calc(100% - 16px); /* Aggiungi questa riga */
}

.pagina-categories-carousel::-webkit-scrollbar {
    display: none;
}

.carousel-card-pagina{
    width: 100%;
    height: 160px;
    /* min-width: 180px; */
    background-color: transparent !important;
    border-radius: 12px;
    text-align: left;
    flex-basis: calc(33.33% - 16px); /* Aggiungi questa riga */
    margin: 8px; /* Aggiungi questa riga */
}

.carousel-card-pagina  img {
    border-radius: 12px;
    width: 100%;
    height: 80%;
    object-fit: cover;
}

.pagina-hero-search-view {
    -webkit-font-smoothing: antialiased;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.16);
    color: #425457;
    padding: 2rem 3rem;
    text-align: center;
    text-rendering: optimizeLegibility;
    /* width: 65%;
    z-index: 2;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px; */
}

.col-hero-search-view {
    position: absolute;
    top: 20%;
    left: 16.6%;
}

.col-hero-contact-view {
    position: absolute;
    top: 14%;
    right: .2%;
} 


@media (max-width:1199px){
    
    .col-hero-search-view {
        position: relative;
        top: unset;
        left: unset;
        margin-top: 2rem;

        max-width: 800px;
        margin-right: auto;
        margin-left: auto;
    }
    
    .partner-hero {
        height: fit-content;
    }

}

@media (max-width:900px){
    .pagina-hero-search {
        width: 95%;
        z-index: 2;
        position: absolute;
        top: 14%;
        left: 50%;
        transform: translateX(-50%);
        max-width: 600px;
    }

    .edit-icon-pagina,
    .edit-icon-pagina-bottom,
    .edit-icon-pagina-bookmark,
    .edit-icon-pagina-heart {
        width: 40px !important;
        height: 40px !important;
        max-width: 40px !important;
        max-height: 40px !important;
    }


    .pagina-categories {
        padding: 2rem 1rem
    }

    .pagina-categories-carousel {
        display: block;
    }

    .pagina-hero-search {
        padding: 2rem 1rem
    }

    .carousel-card-pagina img {
        border-radius: 12px;
        width: 98%;
        height: 80%;
        object-fit: cover;
    }
}




.fornitori-margin-top {
    margin-top:2.5rem !important;
}