.search-fence {
    border: 1px solid #ced4da;
    filter: none;
    height:44px;
}

.search-fence input {
    border-radius: 6px;
}

@media (max-width:576px){

    .search-fence {
        width:100%
    
    }


}