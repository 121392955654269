.tabs-div {
	display: flex;
	margin-bottom: 25px;
	justify-content: center;
}

.tabs-div.edit-mode .tab1-mobile-title {
	color: #2a7ec4;
}

.tab-icon {
	display: flex;
	cursor: pointer;
}

.padding-left {
	padding-left: 5%;
}

.padding-right {
	padding-right: 5%;
}

.tab-icon-background {
	width: 158px;
	height: 58px;
}

.tab-icon-title1 {
	position: absolute;
	margin-left: 28px;
	margin-top: 16px;
}

.tab-icon-title2 {
	position: absolute;
	margin-left: 28px;
	margin-top: 16px;
}

.tab-icon-title3 {
	position: absolute;
	margin-left: 38px;
	margin-top: 16px;
}

.tab-icon-title4 {
	position: absolute;
	margin-left: 35px;
	margin-top: 16px;
}

.tab-icon-title5 {
	position: absolute;
	margin-left: 48px;
	margin-top: 16px;
}

.tab-icon-title6 {
	position: absolute;
	margin-left: 30px;
	margin-top: 16px;
}

.tab1ContentTitle {
	font-family: "Mulish";
	font-style: normal;
	font-weight: 500;
	font-size: 25px;
	line-height: 38px;
	/* identical to box height */

	display: flex;
	align-items: center;

	/* black */
	margin-top: 30px;
	color: #3a3033;
}

.tabContentSubtitle {
	font-family: "Mulish";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height */

	/* meta */

	color: #606f88;

	margin-right: 51px;
	margin-bottom: 8px;
}

.tab1Content {
	margin-top: 26px;
	display: row;
}

.tabInputField {
	height: 40px;
	/* Gray 4 */

	border: 1px solid #bdbdbd;
	border-radius: 6px;

	font-family: "Mulish";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;

	color: #181818;
	margin-right: 51px;
	margin-bottom: 15px;
}

.toPrevStep-div {
	width: 311px;
	height: 44px;
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 6px;

	font-family: "Mulish";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	justify-content: center;
	display: flex;
	align-items: center;

	color: #000000;
	cursor: pointer;
}

.toNextStep-div {
	margin-left: 20px;
	width: 311px;
	height: 44px;
	background: #83112f;
	border-radius: 6px;

	font-family: "Mulish";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	/* identical to box height */

	justify-content: center;
	display: flex;
	align-items: center;

	color: #ffffff;
	cursor: pointer;
}

.tab3RightSideContent {
	justify-content: center;
	display: flex;
	align-items: center;
}

.tab4Content {
	width: 70%;
	margin: auto;
}

.tab4Content-border {
	background: #ffffff;
	/* meta */

	border: 0.5px solid #606f88;
	border-radius: 16px;
	height: 26%;
	margin-top: 55px;
}

.tab4Content-container {
	margin: 35px;
}

.tab4Content-title {
	font-family: "Mulish";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 25px;
	/* identical to box height */

	display: flex;
	align-items: center;

	/* black */

	color: #3a3033;
}

.capture-btn {
	position: absolute;
	top: 60%;
	margin-left: 1%;
	cursor: pointer;
	font-family: "Mulish";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 25px;

	/* meta */

	color: #83112f;
}

.tab6Border-div {
	background: #ffffff;
	/* meta */

	border: 0.5px solid #606f88;
	border-radius: 16px;
	margin-top: 40px;
}

.tab6Border-insideContainer {
	margin: 25px;
}

.tab6Content-title {
	font-family: "Mulish";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 25px;
	/* identical to box height */

	display: flex;
	align-items: center;

	/* black */

	color: #151313;
}

.tab6Content {
	font-family: "Mulish";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 35px;
	/* or 219% */

	display: flex;
	align-items: center;

	/* black */

	color: #151313;
}

.tab1-mobile-title {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	/* identical to box height, or 150% */

	display: flex;
	align-items: center;
	text-align: center;

	/* meta */

	color: #83112f;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
	width: max-content;
}

.edit-content-container .tab1-mobile-title {
	color: #2a7ec4;
}

.mobile-icon {
	display: none;
}

.desktop-icon {
	display: flex;
}

@media (max-width: 2000px) {
	.tab4Content {
		width: 100%;
		margin: auto;
	}

	.tabContent-input1 {
		padding-left: 0%;
	}

	.tabContent-input2 {
		padding-right: 0%;
	}
}

@media (max-width: 1000px) {
	.tabs-div {
		width: fit-content;
		margin: auto;
	}

	.tab4Content {
		width: 100%;
		margin: auto;
	}

	.desktop-icon {
		display: none;
	}

	.mobile-icon {
		display: block;
	}

	.tabs-btn {
		margin-left: 5%;
		margin-right: 5%;
	}

	.tab5Content {
		width: 80%;
	}

	.tabContent-input1 {
		padding-left: 5%;
	}

	.tabContent-input2 {
		padding-right: 5%;
	}
}

@media (max-width: 576px) {
	.tab1ContentTitle {
		font-size: 20px;
	}

	.tabs-btn {
		flex-direction: column-reverse;
	}

	.toPrevStep-div {
		width: 100%;
	}

	.toNextStep-div {
		width: 100%;
		margin-left: 0px;
		margin-top: 20px;
	}

	.footer {
		flex-direction: column;
	}

	.tab5Content {
		width: 100%;
	}
}

.tab6Border-insideContainer > hr,
.tab4Content-container > hr {
	margin: 0.5rem 0 !important;
}
