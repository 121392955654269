
.desktop-icon-sidebar {
    border-radius: 5px;
    position: relative;
    width: 100%;
    padding: .3rem 1rem;
    transition: all .3s ease-in-out;
    background: transparent;


}

.desktop-icon-sidebar.active {
    background: rgba(255, 255, 255, 0.14);
    padding:.8rem 1rem;
    transition: all .5s ease-out;
}

.sidebar-active-item {
    font-weight: bold !important;
}

.menu-item-sb {
    font-size: 14px;
}

.menu-item-sb:hover {
    color: whitesmoke !important;
}

.collapsed-sb-submenu {
    width: fit-content;
    padding: 1rem;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: rgba(39, 46, 60, 0.8);
    position: absolute;
    left: 100%;
    top: 20%;
}

.last-menu-style {
    margin-top: 2rem !important;
}