.importfile-description {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  /* meta */

  color: #83112f;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.input-file-label {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  /* meta */

  color: #83112f;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  text-decoration: underline;
  margin-left: 7px;
}
.importfile-format {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-align: center;

  /* meta */

  color: #83112f;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 13px;
}
.tab3RightBorder {
  height: 450px;
  background: #ffffff;
  /* Gray 4 */

  border: 1px solid #bdbdbd;
  border-radius: 16px;

  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.tab3RightInsideBorder {
  width: 73%;
  height: 66%;
  background: #fff8f8;
  border: 1px dashed #83112f;
  border-radius: 4px;

  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.importFileStatus-div {
  width: 73%;
  margin-top: 5%;
}
.importFileStatusTitle-div {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  display: flex;

  color: #676767;
  flex-direction: column;
}
.importFileProgressbar {
  background: #ffffff;
  border: 0.5px solid #e3e3e3;
  border-radius: 4px;
  margin-top: 3%;
  height: 36px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #0f0f0f;
  display: flex;
  align-items: center;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.progressbar-upload {
  width: 90%;
  background: #e3e3e3;
  border-radius: 10px;
  height: 3px;
  position: absolute;
  margin-top: 3px;
}
.completed-div {
  margin-top: 2%;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #676767;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.fileTypeError-div {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 18px;
  /* identical to box height, or 180% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #e41d1d;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
@media (max-width: 1530px) {
  .uploadBtn-description {
    flex-direction: column;
  }
  .input-file-label {
    justify-content: center;
    margin-left: 0px;
  }
}