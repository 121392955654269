.building-card {
/* 	display: flex;
	justify-content: space-between;
	align-items: center; */
	padding: 20px;
	color: #151313;
}

.building-card .building-img img {
	width: 86px;
	height: 85px;
	border-radius: 4px;
}

.building-card .report-btn {
	border:none;
	outline:none;
	width: 121px;
	height: 36px;
	border-radius: 4px;
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.building-card .report-btn.add {
	background: #fff8f8;
	box-shadow: 0px 4px 4px #f0c0cd;
	color: #83112f;
}

.building-card .report-btn.add:disabled {
	background: #fff8f8;
	box-shadow: unset;
	color: #965466;
}

.building-card .report-btn.remove {
	background: #f1f2f3;
	box-shadow: 0px 4px 4px #d9d9d9;
}

.building-card .building-card-title {
	font-weight: 700;
	font-size: 16px;
}

.building-card .small-span {
	font-weight: 500;
	font-size: 12px;
}

.building-card .small-icon {
	width: 15px;
	height: 15px;
}
