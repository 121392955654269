.misura-img {
    height: 300px;
    border-radius: 20px;
    overflow: hidden;
    max-width: 450px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.misura-img-1 {
    background-image: url('../images/gestionali/misura-1.jpg');
}

.misura-img-2 {
    background-image: url('../images/gestionali/misura-2.jpg');
}


.misura-img-3 {
    background-image: url('../images/gestionali/misura-3.jpg');
}

.misura-img-4 {
    background-image: url('../images/gestionali/misura-4.jpg');
}

.misura-img-5 {
    background-image: url('../images/gestionali/misura-5.jpg');
}


.misura-img-icon-dx {
    position: absolute;
    bottom: -10%;
    right: 0;
}

.misura-img-icon-sn {
    position: absolute;
    bottom: -10%;
    left: 0;
}

.gestionali-col > .ul > .p {
    list-style: unset;
}