.page1 {

    padding-left: 5%;
    padding-top: 5%;
}

.modal-title {
    width: 100%;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;

    align-items: center;
    text-align: center;
}

.sub-title {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */
    /* margin-left: 2%;  REMOVED BECAUSE IT BREAKS OUR INPUT FIELDS*/
    
    /*QUESTO MARGIN-TOP NONSERVE*/
    /* margin-top: 15px; */
    display: flex;
    align-items: center;

    /* meta */

    color: #606F88;
}



.btn-margin {

    margin-top: 37px;
    cursor: pointer;
}

.btn-second:hover {
    outline: none;
    box-shadow: none !important;

}


.btn:focus-within {
    box-shadow: none !important;

}

.sub-text {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Gray 1 */

    color: #333333;
}

.sub-bold {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Gray 1 */

    color: #333333;
}

.form-select-calc {
    width: 100%;
    height: 50px;
    /* background: #FFFFFF; */
    /* Gray 4 */

    border: 1px solid #BDBDBD;
    border-radius: 6px;
}

.small {
    height: 35px;
    width: 100%;
}


/* .medium {
    height: 45px;
    width: 50%;
} */

.medium-small {
    height: 45px;
    width: 30%;
}

.space-top {
    margin-top: 10%;
    text-align: center;

}

.btn-default {
  /*   background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none; */
}

.btn-primary1 {
    background: #83112F;
    border-radius: 6px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    color: white;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.btn-primary1:hover {
    box-shadow: none;
}

.btn-second {
    background: #606F88;
    border-radius: 6px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    color: white;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.spin {
    display: flex;
    height: 45px;
    width: 100%S;
}

.tb-title {
    padding-top: 10px;
    background: #F9F8F9;
    border: 0.5px solid #EAEAEA;
    height: 35px;
    align-items: center;
    text-align: left;
    padding-left: 1%;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;

    color: #2D2F30;
}

.tb-content {
    padding-top: 10px;
    background: #FFFFFF;
    border: 0.5px solid #EAEAEA;
    height: 35px;
    align-items: center;
    text-align: left;
    padding-left: 1%;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;

    color: #2D2F30;
}