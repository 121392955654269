.footer {
  background: #1e2833;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.footer-container {
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
}
.footer-logo-description {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */

  letter-spacing: -0.204545px;

  /* white */

  color: #ffffff;
}
.main-description {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: -0.231818px;
  text-transform: uppercase;
  /* Gray 6 */
  color: #f2f2f2;
  margin-bottom: 21px;
}
.sub-description {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: -0.204545px;

  color: #ffffff;
  margin-right: 17px;
}
.socialLink-div {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 134px;
}
.footer-description {
  margin-left: 7%;
  margin-right: 8%;
}
.subdiscription-div {
  display: flex;
}
.footer-link-div {
  width: 25%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
@media (max-width: 1000px) {
  .footer-container {
    display: block;
    margin-top: 20%;
    margin-bottom: 20%;
  }
  .footer-description {
    margin-left: 0px;
    margin-top: 50px;
  }
  .subdiscription-div {
    display: block;
    margin-top: 50px;
  }
  .sub-description {
    margin-top: 13px;
  }
  .footer-link-div {
    width: 100%;
    margin-top: 80px;
  }
}
