.custom-badge-success {
    background-color: #ECFDF3;
    color: #027A48;
}

.custom-badge-danger {
    background-color: #FDEFEC;
    color: #B61919;
    cursor: pointer;
}

.custom-badge-alert {
    background-color: #FFFAEB;
    color: #B54708;
}

.custom-badge-info {
    background-color: #F4F1FE;
    color: #3D1EB7;
}

.custom-badge-pagina {
    background-color: #cccccc66;
    color: rgb(83, 83, 106);
}

.custom-badge-pagina {
    background-color: #cccccc66;
    color: rgb(83, 83, 106);
}

.custom-badge-gray {
    color: #405666;
    background-color: #dce3e8;
}

.custom-badge-red {
    color: #a1160a;
    background-color: #fadcd9;
}

.custom-badge-orange {
    color: #f8f8f8;
    background-color: #f7893b;
}

.custom-badge-yellow {
    color: #7b774c;
    background-color: #faf6cf;
}

.custom-badge-lime {
    color: #2e5c0e;
    background-color: #d5f0b1;
}

.custom-badge-green {
    color: #075e45;
    background-color: #c7ebd1;
}

.custom-badge-teal {
    color: #155c5e;
    background-color: #beebe7;
}

.custom-badge-turquoise {
    color: #0a596c;
    background-color: #c7e8ed;
}

.custom-badge-aqua {
    color: #08548b;
    background-color: #c9e7f6;
}

.custom-badge-blue {
    color: #0d4ea6;
    background-color: #d4e4fa;
}

.custom-badge-ultramarine {
    color: #3b45b0;
    background-color: #dee0fa;
}

.custom-badge-purple {
    color: #6b31ab;
    background-color: #eadcfc;
}

.custom-badge-pink {
    color: #961574;
    background-color: #f7daee;

}

.custom-badge-transparent {
    color: #212023;
    background-color: transparent;

}