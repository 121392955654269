.welcome-title {
  font-family: "Inria Serif";
  font-style: normal;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  width: 300px;
  color: #606f88;
}
.welcome-description {
  font-family: "Mulish";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #606f88;
}
/* .title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 37.65px;
} */
.subtitle {
  font-family: "Mulish";
  font-weight: 500;
  font-size: 16px;
  line-height: 20.08px;
  padding-top: 20px;
  color: #606f88;
  margin-bottom: 8px;
}
#disable-login {
  background-color: #83112f80;
}
.loader {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #151414a8;
  z-index: 999;
  position: fixed;
}
.loading-bar {
  position: fixed;
  z-index: 1000;
  top: calc(50% - 30px);
  right: calc(50% - 30px);
}
.login-btn {
  background-color: #83112f;
  width: 100%;
  margin-top: 20px;
  border: none;
  border-radius: 6px;
  height: 44px;
  color: #ffffff;
}
.reset-password {
  font-family: "Mulish";
  font-style: Regular;
  font-size: 16px;
  line-height: 20px;
  color: #606f88;
}
.reset-password:hover {
  color: 606F88;
}
.createAccount-btn {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
  margin: auto;
  margin-bottom: 40px;
  margin-top: 20px;
}
.error-div {
  background-color: #fff8f8;
  stroke: Solid #eb5757;
  color: #eb5757;
  border: 1px solid #eb5757;
  border-radius: 6px;
  width: 460.38px;
  height: 50px;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-family: "Mulish";
  line-height: 17.57px;
  font-weight: 500;
}
#input_error {
  border: 1px solid #eb5757;
}

.passwordHideToggle {
  position: absolute;
  right: 7%;
  margin-top: -57px;
  cursor: pointer;
}

.passwordHideToggle2 {
  position: absolute;
  right: 7%;
  margin-top: -37px;
  cursor: pointer;
}

.passwordHideToggle1 {
  position: absolute;
  right: 7%;
  margin-top: -47px;
  cursor: pointer;
}

.rightPanel {
  margin: auto;
  width: 25%;
}
.verify-Modal .modal-dialog {
  max-width: 40% !important;
}
@media (max-width: 1800px) {
  .rightPanel {
    margin: auto;
    width: 25%;
  }
  .logo-mobile-div {
    background-image: url("../images/logo-bianco.svg");
    width: 120px;
    height: 50px;
    left: 6%;
    top: 4%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 43% 47%;
    display: none;
  }
  .verify-Modal .modal-dialog {
    max-width: 40% !important;
  }
}
@media (max-width: 1200px) {
  .rightPanel {
    width: 30%;
    margin: auto;
  }
  .verify-Modal .modal-dialog {
    max-width: 500px !important;
  }
}
@media (max-width: 950px) {
  .rightPanel {
    width: 80%;
    margin: auto;
  }
  .login-div {
    height: 100vh;
  }
  .verify-Modal .modal-dialog {
    max-width: 500px !important;
  }
}
@media (max-width: 600px) {
  .mobile-background {
    background: url("../images/mobile-background.png");
    width: 100%;
    height: 70vh;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .login-div {
    height: max-content;
    border-radius: 16px;
    position: absolute;
    top: 80px;
    width: 100%;
  }
  .rightPanel {
    width: 100%;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    height: 100vh;;
  }
  .welcome-title {
    font-size: 20px;
    padding-top: 40px;
  }
  .welcome-description {
    font-size: 14px;
  }
  .title {
    font-size: 24px;
  }
  .verify-Modal .modal-dialog {
    max-width: 100% !important;
  }
  .logo-mobile-div {
    display: block;
  }
}
