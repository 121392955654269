.border-label-div {
	padding: 20px;
	border: 0.5px solid #eb5757;
	position: relative;
}

.border-label {
	position: absolute;
	top: -15px;
	color: #eb5757;
	font-size: 14px;
	background-color: white;
	padding: 5px;
}

.border-label-div.general-border {
	border: 0.5px solid #bdbdbd;
}

.border-label.general-color {
	color: #828282;
}

.table-border.out-border-none {
	border: none;
}

.table-border.out-border-none input {
	accent-color: var(--primary);
}

.result-table tbody > tr:hover {
	cursor: pointer;
	background-color: var(--edit-color);
}

.round-icon-btn.small {
	width: 23px;
	height: 23px;
	padding: 2px 6px;
	margin-right: 5px;
	cursor: pointer;
}

.user-field {
	display: flex;
	align-items: center;
}

.user-field input[type="text"] {
	background-color: white !important;
	padding: 0px;
	height: auto;
	width: auto;
	color: var(--lightgrey) !important;
}

.file-upload-div {
	width: 260px;
	height: 190px;
	background: rgba(196, 196, 196, 0.05);
	border: 0.5px solid rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.file-upload-div.drag-active {
	background-color: #bdbdbd;
}

.image-file-card {
	margin: 10px;
	width: fit-content;
	height: fit-content;
}

.image-file-card img {
	border: 0.5px solid rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	width: 260px;
	height: 190px;
	object-fit: cover;

}

@media (max-width: 1420px) {
	.tabs-div {
		zoom: 0.9;
	}
}

@media (max-width: 1290px) {
	.tab-div {
		zoom: 1;
	}

	.desktop-icon {
		display: none;
	}

	.mobile-icon {
		display: block;
	}
}

.image-div {
	margin-left: 10px;
}

.image-div .section-card {
	padding: 0px;
	border: none;
}

.image-div .section-card .round-icon-btn {
	width: 23px;
	height: 23px;
	padding: 2px 6px;
	margin-right: 11px;
}

.image-div .section-card .round-icon-btn.change-state-btn {
	padding: 0px;
}

.reunit-card {
	background-color: white;
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: 15px 20px;
	justify-content: space-between;
	border: 1px solid rgba(35, 39, 56, 0.25);
	border-radius: 5px;	

	margin-left: 12px;
	transition: all .5s ease-in-out;
}

.reunit-card:hover {
	border-left: 12px solid var(--primary);

}

.reunit-card .state-img img {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	margin-right: 30px;
	object-fit: cover;
}

.reunit-card .reunit-card-type {
	font-size: 10px;
	color: var(--lightgrey);
}

.reunit-card .reunit-card-title {
	font-size: 16px;
	font-weight: 700;
	color: #151313;
}

.fund-btn {
	width: 144px;
	height: 44px;
	background: #9cccf2;
	border-radius: 10px;
	color: white;
	font-weight: 600;
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.reunit-card .small-span {
	font-weight: 500;
	font-size: 12px;
	color: #151313;
}

.reunit-card .small-icon {
	width: 20px;
	height: 20px;
}

.reunit-card .reunit-value {
	font-weight: 600;
	font-size: 12px;
	color: #151313;
}

input.inline-edit {
	border: 1px solid;
	border-color: transparent !important;
	padding: 0;
	border-radius: 2px;
}

input.inline-edit:focus {
	border: 1px solid lightgray !important;
}

input.inline-edit:disabled {
	background-color: transparent !important;
}

.save-change-btn-group {
	position: absolute;
	right: 40px;
	top: 90px;
}

@media (max-width: 900px) {
	.save-change-btn-group {
		right: 12px;
		top: 140px;
	}
}


.tab-state {
	display: flex;
	justify-content: center;
	position: relative;
}

.tab-state label {
	color: #bdbdbd;
	font-size: 14px;
}

.tab-bar {
	background: #f0f0f0;
	border-radius: 5px;
	height: 9px;
	margin-top: 7px;
	width: 100%;
	z-index: 1;
}

.tab-bar.active-tab-bar {
	background: #9ccbf2;
	z-index: 2;
}

.tab-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	width: 25%;
}

label.non-active {
	color: #bdbdbd !important;
}

.user-dropdown-content.left {
	left: 0px;
}

.cadastre-card {
	border: 1px solid #d9d9d9;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
	margin-top: 10px;
}

.cadastre-card-header {
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
}

.cadastre-card-header span {
	color: #bdbdbd;
}


@media (max-width: 900px) {
	.tab-state {
		display: unset !important;
		justify-content: center;
		position: relative;
		margin: 0 auto;
	}

	.tab-state label {
		color: #bdbdbd;
		font-size: 10px;
	}
}