/*BTN-RM-PRIMARY*/

.btn-rm-primary,
.btn-rm-primary:focus,
.btn-rm-primary:active {
    background-color: var(--primary) !important;
    color: var(--white) !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    transition: all .5s ease;
    border: none;
    min-width: 150px;
}

.btn-rm-primary:hover {
    background-color: #640C23;
    color: var(--white);
    border: none;
}

.btn-rm-primary:disabled{
    background-color: #C08796;
    color: var(--white);
    border: none;
}

/*OUTLINE*/

.btn-rm-outline,
.btn-rm-outline:focus,
.btn-rm-outline:active {
    background-color: white !important;
    color: #344054 !important;
    border-radius: 8px;
    transition: all .5s ease;
    box-shadow: none;
    border: 1px solid #344054;
    min-width: 150px;
    border: 1px solid #D0D5DD !important;
    padding: .3rem 1rem !important;
    font-weight: 600;
}

.btn-rm-outline:hover {
    background-color: rgb(242, 242, 245);
    color: #344054;
    border:1px solid #D0D5DD;
}

.btn-rm-outline:disabled{
    background-color:rgb(230, 225, 225);
    color: #606a7c;
    border:1px solid #D0D5DD;
}

/*OUTLINE-RED*/

.btn-rm-outline-red,
.btn-rm-outline-red:focus,
.btn-rm-outline-red:active {
    background-color: rgb(216, 60, 60) !important;
    color: #ffffff !important;
    border-radius: 8px;
    transition: all .5s ease;
    box-shadow: none;
    border: none;
    min-width: fit-content;
    border:1px solid #D0D5DD;
    padding:.3rem 1rem !important;
    font-weight: 600;
}

.btn-rm-outline-red:hover {
    background-color: rgb(162, 10, 5);
    color: #ffffff;
    border:1px solid #D0D5DD;
}

.btn-rm-outline-red:disabled{
    background-color:rgb(208, 120, 120);
    color: #c8c9cc;
    border:1px solid #D0D5DD;
}

/*OUTLINE-gray*/

.btn-rm-outline-gray,
.btn-rm-outline-gray:focus,
.btn-rm-outline-gray:active {
    background-color:#606F88 !important;
    color: #ffffff !important;
    border-radius: 8px;
    transition: all .5s ease;
    box-shadow: none;
    border: none;
    min-width: fit-content;
    border:1px solid #D0D5DD;
    padding:.3rem 1rem !important;
    font-weight: 600;
}

.btn-rm-outline-gray:hover {
    background-color:#475368;
    color: #ffffff;
    border:1px solid #D0D5DD;
}

.btn-rm-outline-gray:disabled{
    background-color:#9197a1;
    color: #c8c9cc;
    border:1px solid #D0D5DD;
}



/*BTN-RM-SECONDARY*/

.btn-rm-secondary,
.btn-rm-secondary:focus,
.btn-rm-secondary:active {
    background-color: #606F88 !important;
    color: var(--white) !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    border:none;
    min-width: 150px;

}

.btn-rm-secondary:hover {
    background-color: #3B4F6F;
    color: var(--white);
    border: none;
}

.btn-rm-secondary:disabled{
    background-color: #606F88;
    color: var(--white);
    border: none;
}


/*BTN-RM-TERTIARY*/

.btn-rm-tertiary,
.btn-rm-tertiary:focus,
.btn-rm-tertiary:active {
    background-color: var(--white) !important;
    color: black !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    border:none;
    min-width: 150px;

}

.btn-rm-tertiary:hover {
    background-color: #F5F5F5;
    color: black;
    border: none;
}

.btn-rm-tertiary:disabled{
    background-color: rgba(255, 255, 255, 0.5);
    color: rgba(0, 0, 0, 0.5);
    border: none;
}

/*BTN-TEXT-ONLY*/

.btn-rm-text,
.btn-rm-text:focus,
.btn-rm-text:active {
    background-color: transparent !important;
    color: black !important;
    border:none;
    box-shadow: unset;
    background: transparent !important;
    text-decoration: none;
    transition: all .5s ease; 
}

.btn-rm-text:hover {
    background-color: transparent;
    color: black;
    border: none;
    text-decoration: underline;
    transition: all .5s ease;
}

.btn-rm-text:disabled{
    background-color: transparent;
    color: #6c757d !important;
    border: none;
}

/*BTN-RM-BLUE*/

.btn-rm-blue,
.btn-rm-blue:focus,
.btn-rm-blue:active {
    background-color:var(--edit-color) !important;
    color: var(--white) !important;
    border-radius: 6px;
    border:none;
    min-width: 150px;
    box-shadow: none !important;


}

.btn-rm-blue:hover {
    background-color: #73b4e9;
    color: var(--white);
    border: none;
}

.btn-rm-blue:disabled{
    background-color:#8eabc3;
    color: var(--white);
    border: none;
}


/*BUTTON SLC*/

.btn-rm-slc,
.btn-rm-slc:focus,
.btn-rm-slc:active {
    border-radius: 20px !important;
    font-size: 14px;
    font-weight: bold;
    padding: .5rem 1rem;
    line-height: 1.2;
    background-color:#d7ecfb !important;
    color: #262d3c !important ;
    border-radius: 6px;
    border:none;
    min-width: 150px;

}

.btn-rm-slc:hover {
    background-color: #1D3F95;
    color: var(--white);
    border: none;
}

.btn-rm-slc:disabled{
    background-color:#768BC0;
    color: var(--white);
    border: none;
}

/*FOR ATTRIBUTE SIZE*/

.btn-default {
    padding: 0 3rem;
    height: var(--btn-default-size)
}

.btn-full-width {
    width: 100%;
    height: var(--btn-default-size)
}

.btn-small {
    padding: 0 1.5rem;
    height: 40px;
}

.btn-extra-small {
    padding: 0 1.3rem;
    height: 35px;
}

.btn-full-width-small {
    padding: 0 1.5rem;
    height: 40px;
    width: 100%;
    font-size:14px;
    line-height: 1.2;

}


/*BTN PINK*/
.btn-rm-pink{
    background-color: #FFF8F8 !important;
    box-shadow: none;
    border-radius: 6px;
    transition: all .5s ease;
    border: 1px solid var(--primary);
}

.btn-rm-pink:hover {
    background-color: #e9cfcf;
    color: var(--primary);
    border: 1px solid var(--primary);
}

.btn-rm-pink:disabled{
    background-color: #f5e6ea;
    color: #71525a;
    border: #71525a;
}



/*BTN-RM-PRIMARY-ROUND*/

.btn-rm-primary-round,
.btn-rm-primary-round:focus,
.btn-rm-primary-round:active {
    border-radius: 20px !important;
    font-size: 14px;
    font-weight: bold;
    padding: .5rem 1rem;
    line-height: 1.2;
    background-color: var(--primary) !important;
    color: var(--white) !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    transition: all .5s ease;
    border: none;
    min-width: 150px;
}

.btn-rm-primary-round:hover {
    background-color: #640C23;
    color: var(--white);
    border: none;
}

.btn-rm-primary-round:disabled{
    background-color: #C08796;
    color: var(--white);
    border: none;
}


/*BUTTON TROVA GESTORE*/

.btn-rm-trova-gestore,
.btn-rm-trova-gestore:focus,
.btn-rm-trova-gestore:active {
    font-size: 14px;
    font-weight: bold;
    padding: .5rem 1rem;
    line-height: 1.2;
    background-color:#11267A !important;
    color: #fbfbfb !important ;
    border-radius: 6px;
    border:none;
    min-width: 150px;

}

.btn-rm-trova-gestore:hover {
    background-color: #0e1d5a;
    color: var(--white);
    border: none;
}

.btn-rm-trova-gestore:disabled{
    background-color:rgb(47, 55, 75);
    color:rgb(151, 158, 177) ;
    border: none;
}

/*BUTTON TROVA GESTORE - light*/

.btn-rm-trova-gestore-light,
.btn-rm-trova-gestore-ligh:focus,
.btn-rm-trova-gestor-lighe:active {
    font-size: 14px;
    font-weight: bold;
    padding: .5rem 1rem;
    line-height: 1.2;
    background-color:#d7ecfb !important;
    color: #262d3c !important ;
    border-radius: 6px;
    border:none;
    min-width: 150px;

}

.btn-rm-trova-gestore-ligh:hover {
    background-color:#b8d3e7 !important;
    color: #262d3c !important ;
    border: none;
}

.btn-rm-trova-gestore-ligh:disabled{
    background-color:#bbc5cc !important;
    color: #414754 !important ;
    border: none;
}