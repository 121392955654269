.settings-container {
    background: white;
    padding: 1rem;
    border-radius: 5px;
    margin-top: 1rem;
}

@media (min-width:1000px){
    .settings-container {
        background: white;
        padding: 3rem;
        border-radius:5px;
        margin-top: 3rem;
    }
}


.settings-tab-content {
    padding: 1.5rem 0;
    margin: 1rem 0;
}

.opacity-10 {
    opacity: .10!important;
}

.roundImgContainer{
    position: relative;
}

.roundImg {
    width: 100px;
    height: 100px;
    object-fit: cover;

}

.roundImgSmall {
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.roundImgRemove {
    position: absolute;
    right: -10px;
    top: 10px;
    width: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    cursor: pointer;
}

.faceIdDiv {
    padding:1rem 2rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    color:#606F88;
    text-align: center;
    cursor: pointer;
}

.firmeCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:.5rem 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    color:#606F88;
    cursor: pointer;
    margin-bottom: .5rem;
}

.firmeCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:.5rem 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    color:#606F88;
    cursor: pointer;
    margin-bottom: .5rem;
}

.intCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:.5rem 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    color:#606F88;
    margin-bottom: .5rem;
}

.discount {
    position: absolute;
    right: 1rem;
    top: 0;
}

.discount-text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cardSubDiv {
    padding:2rem 2rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    color:#606F88;
    text-align: center;
    min-height: 413px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bg-table-invoice{
    background-color:  #F9FAFB !important;
    color: #667085;

}

.text-secondary-settings {
    color:#606F88;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    display: inline;
}

.remove-image-invitation {
    cursor: pointer;
}

.editor-modal {
    --bs-modal-width: 800px !important; 
    min-height: 600px !important;
}

.editor-modal-body {
    --bs-modal-width: 800px !important; 
    min-height: 500px !important;
    max-height: 500px !important;
    overflow-y: auto;
}

.settings-archive.breadcrumb-item > a {
    color: gray;
}


.settings-archive.breadcrumb-item.active {
    color: var(--primary);
    text-decoration: underline;
}


.valSettingsDiv {
    padding:1rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    color:#606F88;
    text-align: center;
    cursor: pointer;

    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* EDIT SLIDER COLOR*/

.MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium.css-187mznn-MuiSlider-root {
    color: #606f88 !important;
}

.css-14pt78w-MuiSlider-rail {
    background-color: lightgray !important;
}

.css-1gv0vcd-MuiSlider-track {
    border: 1px solid #606f88!important;
    background-color: #606f88!important;
}

.css-eg0mwd-MuiSlider-thumb{
    background-color: #606f88 !important;
}

.MuiSlider-valueLabelOpen.MuiSlider-valueLabel.css-nnid7-MuiSlider-valueLabel {
    background-color: white;
    border: 1px solid #606f88;
    color: black
    ;
}

.MuiSlider-valueLabelOpen.MuiSlider-valueLabel.css-nnid7-MuiSlider-valueLabel::before {
    background-color: white;
    border-bottom: 1px solid #606f88;
    border-right: 1px solid #606f88;
    color: black;
    bottom: -1px;
    ;
}



/*VAL PADDING*/

.val-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* RESPOSNIVE*/

.settings-dropdown {
    padding: 0 !important;
}

.settings-dropdown.show > .dropdown-menu {
    padding: 1rem;
}

.settings-dropdown.show > .dropdown-menu > .dropdown-item {
    display: inline-block;
    margin-bottom: .5rem !important; 
    text-decoration: none;
    transition: all .5s ease-in-out;
}


.portfolioImgRemove {
    position: absolute;
    right: -10px;
    top: 10px;
    width: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    cursor: pointer;
}

.portfolio-category-card {
    width: 100%;
    height: 140px;
    min-width: 190px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.03), 0 3px 1px -2px rgba(0,0,0,.07), 0 1px 5px 0 rgba(0,0,0,.06);
    border-radius: 12px;
    text-align: center;
    position: relative;
}

.portfolio-category-card  .portfolioImg {
    border-radius: 12px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.portfolio-fornitori-modal {
    max-height: 520px;
    overflow-y: auto;
}

.icons-portfolio-container {
    position:absolute;
    display: flex;
    justify-content: end;
    right: 0.2%;
    top:2%;
}

.bullet {
    background-color: #000;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
}

.no-bullet {
    background-color: transparent;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
}


.edit-icon-pagina-bottom {
    position: absolute;
    width: 50px;
    height: 50px;
    max-width: 50px !important;
    max-height: 50px !important;
    box-shadow: 4px 4px 8px rgba(0,0,0,.16);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    top: -10px;
    right: 3px;
    cursor: pointer;
}