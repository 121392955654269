.documenti-owner-btn {
	background: #606f88;
	color: #fff;
	padding: 10px 30px;
}

.documenti-owner-btn:hover {
	background-color: #3b4f6f;
	color: white;
}

.mt20 {
	margin-top: 20%;
}

.round-icon-btn {
	background-color: #f1f2f3 !important;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px;
	margin-right: 10px;
	border: none;
}

.round-icon-btn:hover {
	background-color: #cccccc !important;
}

.icon-btn-group {
	display: flex;
}

.template-card {
	background-color: white;
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: 15px 20px;
	justify-content: space-between;
	border: 1px solid rgba(35, 39, 56, 0.25);
	border-radius: 5px;	

	flex-wrap: wrap;
	transition: all .5s ease-in-out;
}

/* .template-card:hover {
	border-left: 12px solid var(--primary);

} */

.template-card-input {
	width: fit-content !important;
	padding:0
}

.model-modal .modal-dialog {
	position: absolute;
	right: 0px;
	max-width: 50%;
	margin: 0px !important;
	height: 100%;
	/*CHANGES MADE BY EMA SET WIDTH MODAL AND FIX RESPONSIVE*/
	width: 500px;
}

@media (max-width: 767px) {
	.model-modal .modal-dialog {
		min-width: 90%;
		max-width: 90%;
	}
}

/*CHANGES BY EMA END HERE*/

.model-modal .modal-content {
	height: 100%;
	border: none;
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}

.upload-div {
	border: 1px dotted #bdbdbd;
	border-radius: 5px;
	padding: 1rem;
}

.upload-btn {
	border-radius: 4px;
	padding: 1rem;
	width: 100%;
}

.input-file-label {
	cursor: pointer;
}

.input-file-label:hover {
	text-decoration: underline;
}

.drag-active {
	background-color: #bdbdbd;
}

.small-modal {
	display: flex !important;
	align-items: center;
}

.small-modal .modal-dialog {
	max-width: 50%;
	height: 60%;
	width: 50%;
}

.small-modal .modal-content {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.section-card {
	background-color: white;
	width: 100%;
	padding: 10px 10px;
	display: flex;
	justify-content: space-between;
	/* flex-wrap: wrap; */
	border: 1px solid rgba(96, 111, 136, 0.5);
	border-radius: 4px;
	align-items: center;
}

.section-card input[type="text"] {
	padding: 0px !important;
	height: 35px !important;
}

.type-modal {
	top: 50%;
	transform: translateY(-25%);

}

.plus-icon {
	margin-right: 10px;
}

.table_tr {
	background: rgba(45, 156, 219, 0.2);
}

.disable {
	background: var(--light);
}

.preview-screen .modal-dialog {
	max-width: 70%;
}

.modal-close-btn {
	padding: 0px;
	position: fixed;
	right: 20px;
	top: 10px;
	color: white;
	border: none;
	width: 30px;
	height: 30px;
}

.modal-close-icon {
	color: white;
	font-size: 25px;
}

.mobile-icon-btn {
	border: 1px solid rgba(35, 39, 56, 0.5);
	border-radius: 6px;
	background-color: white;
	width: 46px;
	height: 46px;
	display: block;
	opacity: 1;
	transition: ease-in-out, width 2s;
}

.back-icon {
	display: flex;
	align-items: center;
}

.document-card {
	background-color: white;
	width: 100%;
	padding: 10px 25px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
/* 	box-shadow: 0px 4px 4px #e7e5e5;
 */	border-radius: 6px;
	align-items: center;
	margin-bottom: 10px;

	border: 1px solid #ced4da;
    border-radius: 5px;
}

.w-15p {
	width: 15%;
}

.document-card p:first-child {
	width: 25% !important;
}

.c-green {
	color: #3da369;
}

.c-red {
	color: #ed6c6c;
}

.filter {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
}

.filter-btn {
	padding: 8px 20px;
	color: #83112f;
	background: #eddee2;
	border-radius: 4px;
	margin-left: 20px;
	border: none;
	font-size: 14px;
	font-weight: bold;
}

.account {
	width: 100%;
	overflow-x: auto;
	border-radius: 5px;
}

@media (min-width: 1000px) {
	.account{
		width: 18% !important;
	margin-right: 20px;
	}
}


.account-table {
	background-color: white;
	font-size: 14px;
	width: 100%;
	border: 1px solid #ced4da;
    border-radius: 5px;
}

.account-table tr,
.account-table tr td,
.account-table tr th {
	border: none !important;
}

.account-table td,
th {
	padding: 10px 8px !important;
}

.account-table tr.selected_tr {
	background: #606f88;
	border-radius: 0px 0px 4px 4px;
	color: white;
}

.account-document {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.document {
	flex: auto;
}

.document-card-header {
	color: #828282;
}
.sort-filter-container {
	margin-top: 20px;
	min-height: 150px;
}
.user-dropdown-content {
	display: none;
	position: absolute;
	right: 0px;
	background-color: white;
	padding: 1rem;
	min-width: 280px;
	/* box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15); */
	border-radius: 5px;
	z-index: 3;
	top: 10px;
    max-height: 300px;
    overflow-y: auto;
	border: 1px solid #E3EAF6;

}

.user-dropdown {
	position: relative;
}

.user-dropdown:hover .user-dropdown-content {
	display: block;
}

.user-dropdown:hover .sort-filter-container {
	min-height: 150px;
}

.user-dropdown-content-btn {
	padding: 6px 20px;
}

.form-last-btns .btn-last {
	padding: 10px 20px;
}

.edit-container {
	background-color: white;
	/* margin-top: 15px;
	padding: 10px 40px; */
	border-radius: 5px;
	padding-top: 0 !important;
}

.add-btn:disabled {
	background-color: rgba(131, 17, 47, 0.5) !important;
}

.add-btn {
	background-color: #83112f !important;
}

.card-list {
	display: flex;
	height: 80%;
	overflow-y: auto;
}

.form-content {
	height: 70%;
}

.section-modal-container {
	padding-left: 15%;
	padding-right: 15%;
	padding-bottom: 8%;
}

.section-modal-btn {
	width: 50%;
	margin: auto;
}

.table-border {
	/* border: 2px solid #83112f; */
}

table.table-border > thead > tr > th {
	border: 0.5px solid #eaeaea !important;
}

table.table-border > tbody > tr > td {
	border: 0.5px solid #eaeaea !important;
}

.table-border thead {
	background: #f9f8f9;
}

select:disabled,
input:disabled,
textarea:disabled {
	background-color: #f1f2f3 !important;
}

.template-card p {
	font-weight: 400 !important;
}

.document-card p {
	font-weight: 400 !important;
}

.template-card input:disabled {
	background-color: white !important;
	font-weight: 400 !important;
}

.section-card input {
	background-color: white !important;
	font-weight: 400 !important;
}


/*SEND*/

.editor-model{
	max-height: 800px;
	overflow-y: auto;
	border-radius: 5px;
}

/*INPUT*/

.input-group-rm {
	border: 1px solid #D0D5DD;
	border-radius: 5px;
	padding-left: 1.5rem;
}

.input-group-rm-item {
	padding: .5rem;
	margin-left: .5rem;
	position: relative;
}

.input-group-rm > *:not(:last-child) {
	border-right: 1px solid #D0D5DD;
	padding-right: 1rem;
}

.input-group-rm-item-fav {
	background-color: rgba(156, 204, 242, 0.5);
}



.account {
	width: 100%;
	overflow-x: auto;
	border-radius: 5px;
}

@media (min-width: 1100px) {
	.account{
		width: 18% !important;
	margin-right: 20px;
	}
}


.percentage-input-pn {
	width: unset !important;
}

.document-table-note {
	background-color: white;
}

.document-table-note > thead > tr,
.document-table-note > tbody > tr {
	border: 1px solid lightgray !important;
}

.document-table-note > tbody > tr > td,
.document-table-note > thead > tr > th {
	padding: .5rem 0.8rem !important;
	border: 1px solid lightgray;
} 


/* CSS personalizzato */
.custom-tr {
	border-radius: 5px;
	cursor: pointer
}


/*credo sia codice di olek per rendiconto*/

.account-item {
	display: flex;
	justify-content: space-between;
	width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.3) !important;
	border-radius: 6px;
	font-weight: 600;
}

.subaccount-td {
	border-right: 1px solid rgba(0, 0, 0, 0.3) !important;
	padding: .5rem;
}

.subaccount-td:last-child {
	border-right: none !important; 
}

.subaccount-input {
	width: 100%;
	appearance: none;
	border: none !important;
	resize: none;
	outline: none;
}

.account-input {
	width: 100% !important;
	background: transparent !important;
    border: none !important;
    padding: 0 !important;
    margin: 0;
	color: white !important;
	font-weight: bold !important;
}

.account-td {
	border-right: 1px solid rgba(0, 0, 0, 0.3) !important;
	padding: .2rem .5rem;
	color: white;
}

.account-item > .account-td {
	border: none !important
}

input[type="number"].subaccount-input {
	padding-left: .2rem !important;
}

.type-account {
	background: #9ccbf2;
	color: white;
	padding: 10px 10px;
}

.subAccount-sub-item {
	/* border-right: 1px solid; */
	/* padding: 10px 0px 10px 10px; */
}

.subAccount-sub-item:last-child {
	/* border-right: none; */
}

.result-item {
	display: flex;
	border: 1px solid rgba(0, 0, 0, 0.3);
	border-radius: 6px;
	font-weight: 600;
	padding: 10px 0px 10px 10px;
}

.calculate-btn {
	cursor: pointer;
	border: 1px solid black;
	border-radius: 6px;
	font-size: 14px;
	padding: 1px 25px;
}

.account-headers {
	padding-left: 10px;
	display: flex;
	justify-content: space-between;
}

.header-trash {
	cursor: pointer;
}