/* MODAL */
.trovaGestoreModal.modal {
    --bs-modal-width: 700px !important; 
}

/* MODAL HEADER */
.trovaGestoreModal > div >  div > .modal-header {
    flex-direction: column !important;
    border-bottom: 0;
}

.trovaGestoreModal > div > div > .modal-header  > :nth-child(1) {
    order: 2; 
}

.trovaGestoreModal > div > div >.modal-header  > :nth-child(2) {
order: 1; 
}

/* MODAL BODY */

.trovaGestoreModal > div >  div > .modal-body {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;

}

.trovaGestoreModal > div >  div > .modal-footer {
    border-top: 0;
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* RADIO BUTTON */
.trova-gestore-input >.form-check-input:checked {
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
}