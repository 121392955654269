.page-body {
    padding: 3%;
}

.page-title {
    /* margin-top: 5%; */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: black;
}

/*FIX MARGIN BETWEEN INPUT AND LABEL*/
.space-top1 {
    margin-top: .5rem;
    width: 100%;
    align-items: flex-start;

}

.space-top3 {
    margin-top: 2%;
    width: 100%;
    align-items: flex-start;
}

.radioLabel {
    margin-top: 2%;
    width: 100%;
    align-items: center;
    display: flex;
}

.space-top2 {
    margin-top: 5%;
    width: 100%;
    align-items: flex-start;
}

.space-left {
    margin-left: 5%;
}

.btn-radio {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-right: 10px;
    /* identical to box height */

    color: #606F88;
}

.btn-radio:hover {
    color: #83112F;
}

.btn-radio:focus {
    color: #83112F;
}

.btn-radio-active {
    color: #83112F;
}

.btn-group2 {
    margin-top: 8%;
    margin-left: 60%;
    margin-right: 10%;
    margin-bottom: 10%;
}

.btn-group3 {
    margin-top: 8%;
    margin-left: 40%;
    margin-right: 10%;
    margin-bottom: 10%;
}

.btn-text {
    margin-top: 2%;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-decoration-line: underline;

    /* meta */

    color: #83112F;
}

.date-section {
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 6px;
    height: 40px;
    width: 50%;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;

}

.input-section {
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    height: 50px;
    align-items: center;
    color: #333333;
    text-align: start;
}

.input-text {
    height: 50px;
    color: #333333;
    background: rgba(54, 209, 107, 0.3);

    border: 1px solid #BDBDBD;
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;

}

.p-0 {
    padding: 0px !important;
}

.m-10 {
    margin-right: 10px !important;
}

.sub-title1 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
   
    display: flex;
    align-items: center;
    color: #606F88;
    margin-left: 1%;
}

.input-text-native {
    height: 50px;
    color: #333333;
    background: rgba(235, 87, 87, 0.3);

    border: 1px solid #BDBDBD;
    border-radius: 6px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;

}

.input-text-blue {
    height: 35px;
    color: #333333;
    background: rgba(45, 156, 219, 0.3);

    border: none;
    border-radius: 6px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;

}

.top-btn-icon {
    display: flexbox;
    margin-left: 1%;
    /* box-shadow: 1px 3px 4px #aa9c9f8a; */
    box-shadow: unset !important;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 6px;
    border-color: white;
}

.top-btn:hover {
    border-color: white;
}

.top-btn-section {
    margin-left: 65%;
}

.select-section {
    margin-left: 2%;
    width: 20%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    align-items: center;
    color: #333333;
    text-align: start;
}

.select-section1 {

    width: 50%;
    height: 35px;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    align-items: center;
    color: #333333;
    text-align: start;
}

.select-part {

    height: 50px;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    align-items: center;
    color: #333333;
    text-align: start;
}

.spin-section {
   
    width: 40%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    align-items: center;
    color: #333333;
    text-align: start;
}

.spin-section1 {

    width: 50%;
    height: 35px;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 6px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    align-items: center;
    color: #333333;
    text-align: start;
}

.tb {
    border: 0.5px solid #EAEAEA;
}

.tb-head {
    height: 35px;
    padding-left: 5%;
    background: #83112f;
    color: white !important;
    /* Neutral / Grey 3 */

    border: 0.5px solid #EAEAEA;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    letter-spacing: 0.5px;

    /* Neutral / Grey 7 */

    color: #2D2F30;
}

.tb-body {
    height: 35px;
    padding-left: 1%;

    background: #FFFFFF;
    /* Neutral / Grey 3 */

    border: 0.5px solid #EAEAEA;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    letter-spacing: -0.25px;

    /* Neutral / Grey 7 */

    color: #2D2F30;
}

.tb-body-blue {
    padding-left: 1%;
    height: 35px;
    background: #D0E1EF;
    /* Neutral / Grey 3 */

    border: 0.5px solid #EAEAEA;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    letter-spacing: -0.25px;

    /* Neutral / Grey 7 */

    color: #2D2F30;
}