/* Variabili */
:root {
    --break-xs: 420px;
    --break-s: 570px;
    --break-m: 790px;
    --break-l: 920px;
  
    --font-size-xs: 35px;
    --font-size-s: 40px;
    --font-size-m: 55px;
    --font-size-l: 70px;
    --font-size-xl: 85px;
  
    --spacing-s: 20px;
    --spacing-m: calc(var(--spacing-s) * 2);
    --spacing-l: calc(var(--spacing-m) * 2);
  
    --background-color: #DCE1E9;
    --text-color: #363732;
  }
  
  /* Stile */
  body {
    background-color: var(--background-color);
    color: var(--text-color);
    padding: var(--spacing-l) var(--spacing-s);
  
    @media screen and (min-width: var(--break-s)) {
      padding: var(--spacing-l) var(--spacing-m);
    }
  }
  
  .typewriter {
    max-width: 100%;
    line-height: 1.4;
    font-family: 'Lekton';
    font-size: var(--font-size-xs);
  }
  
  @media screen and (min-width: var(--break-xs)) {
    .typewriter {
      font-size: var(--font-size-s);
    }
  }
  
  @media screen and (min-width: var(--break-s)) {
    .typewriter {
      font-size: var(--font-size-m);
    }
  }
  
  @media screen and (min-width: var(--break-m)) {
    .typewriter {
      font-size: var(--font-size-l);
    }
  }
  
  @media screen and (min-width: var(--break-l)) {
    .typewriter {
      font-size: var(--font-size-xl);
    }
  }
  
  .sentence:not(.last-sentence) {
    display: block;
  }
  
  .last-sentence {
    white-space: nowrap;
    display: inline-block;
  }
  
  .dynamic-text {
    position: relative;
  }
  
  .dynamic-text:after {
    content: '|';
    position: absolute;
    left: calc(100% - 10px);
  }
  
  .dynamic-text.fade:after {
    animation: fade 1s infinite ease-in-out;
  }
  
  @keyframes fade {
    0%, 100% {
      opacity: 1;
    }
  
    50% {
      opacity: 0;
    }
  }
  