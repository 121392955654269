/*     @font-face {
        font-family: 'Mulish';
        src: url('./../fonts/Mulish-Regular.eot');
        src: url('./../fonts/Mulish-Regular.eot?#iefix') format('embedded-opentype'), url('./../fonts/Mulish-Regular.woff') format('woff'), url('../fonts/Mulish-Regular.ttf') format('truetype'), url('./../fonts/Mulish-Regular.svg#Mulish-Regular') format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Mulish';
        src: url('./../fonts/Mulish-Medium.eot');
        src: url('./../fonts/Mulish-Medium.eot?#iefix') format('embedded-opentype'), url('./../fonts/Mulish-Medium.woff') format('woff'), url('./../fonts/Mulish-Medium.ttf') format('truetype'), url('./../fonts/Mulish-Medium.svg#Mulish-Medium') format('svg');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Mulish';
        src: url('./../fonts/Mulish-SemiBold.eot');
        src: url('./../fonts/Mulish-SemiBold.eot?#iefix') format('embedded-opentype'), url('./../fonts/Mulish-SemiBold.woff') format('woff'), url('./../fonts/Mulish-SemiBold.ttf') format('truetype'), url('./../fonts/Mulish-SemiBold.svg#Mulish-SemiBold') format('svg');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Mulish';
        src: url('./../fonts/Mulish-Black.eot');
        src: url('./../fonts/Mulish-Black.eot?#iefix') format('embedded-opentype'), url('./../fonts/Mulish-Black.woff') format('woff'), url('./../fonts/Mulish-Black.ttf') format('truetype'), url('./../fonts/Mulish-Black.svg#Mulish-Black') format('svg');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    } */
    
   /*  body {
        font-family: 'Mulish';
        font-weight: normal;
        font-size: 14px;
        color: #000000;
        background: #F1F2F3;
        height: 100%;
    }
    
    #root {
        height: 100%;
    }
    
    .container {
        width: 100%;
        max-width: 1200px;
        padding: 0 20px;
    } */
    
    a {
        text-decoration: none;
    }
    
    /* img {
        max-width: 100%;
    } */
    
    /* h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600;
        color: #151313;
        font-family: 'Mulish';
        margin: 0;
    } */
    
    ul {
        list-style: none;
    
    }
    
  /*   a:hover,
    a:focus {
        color: #640C23;
    }
     */
   /*  p {
        font-size: 14px;
        margin: 0;
         line-height: 24px; 
        font-family: Mulish;
    } */
    
    a,
    button,
    input,
    textarea {
        transition: all 300ms;
    }
    
    a,
    a:hover,
    a:focus {
        outline: none;
        text-decoration: none;
    }
    
    .btn-check:focus+.btn,
    .btn:focus {
        outline: 0;
        box-shadow: unset;
    }
    
    .d-flex {
        display: flex;
    }
    
    /* header {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 9;
        background: #83112F;
    } */
    
    .header-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 15px;
        margin-left: 250px;
    }
    
    .header-main .hdr-lft h3 {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        text-transform: uppercase;
    }
    
    .header-main .login-prflnm h3 {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        text-decoration-line: underline;
        text-transform: uppercase;
        color: #FFFFFF;
    }
    
    .hdrrght-inner {
        display: flex;
        align-items: center;
    }
    
    .login-prflnm {
        margin-right: 20px;
    }
    
    .notification {
        position: relative;
    }
    
    .noti-count {
        position: absolute;
        top: -4px;
        right: -4px;
    }
    
    .noti-count span {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #9CCBF2;
        width: 25px;
        height: 25px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
    }
    
    .flex-section {
        margin-left: 230px;
    }
    
    .mdl-main {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    
    /* 
    .side-menu {
        width: 250px;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 99;
        bottom: 0;
    }
    
    .side-nav {
        background-color: #222;
        background-image: url(./../../images/sidebar-bg.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 0px;
        height: 100%;
         padding-bottom: 58px; 
        padding-bottom: 0;
        overflow: auto;
        padding-top: 106px;
        padding-bottom: 40px;
    }
    
    .expand-lftmenu {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    
    .logo {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }
    
    .ul-list {
        list-style: none;
        padding: 0;
        padding-top: 0px;
    }
    
    .ul-list li a,
    .ul-list li p {
        padding: 8px 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        display: block;
        margin: 0;
        cursor: pointer;
        position: relative;
    }
    
    .ul-list li a:hover,
    .ul-list li p:hover {
        transition: color 0.2s ease, background-color 0.2s ease;
         color: #83112F; 
        font-weight: 700;
    }
    
    .ul-list li p:after {
        content: "\f107";
        position: absolute;
        top: 8px;
        right: 14px;
        font-family: fontAwesome;
        font-weight: 600;
        transform: rotate(360deg);
    }
    
    .ul-list li.menu-open p:after {
        transform: rotate(180deg);
    }
    
    .sumenu {
        list-style: none;
        padding-left: 0;
         display: none; 
    }
    
    .sumenu li {
        position: relative;
    }
    
    .sumenu li a {
        padding: 8px 15px;
        font-size: 13px;
        padding-left: 30px;
    }
    
    .sumenu li.active a {
        font-weight: 700;
    }
    
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgb(0 0 0 / 20%);
        z-index: 998;
        transition: all 300ms;
        opacity: 0;
        visibility: hidden;
    } */
    
    main {
        height: 100%;
        /* padding: 60px 0 0;
    margin-left: 250px; */
    }
    
    .middle-sec {
        /* padding: 20px 20px; */
        padding: 0px 0px;
        height: 100%;
    }
    
    .top-sec {
        /* display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    margin-bottom: 15px;
    height: 70px; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0 0;
        margin-bottom: 20px;
        height: 50px;
    }
    
    .top-sec h2 {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 32px;
        line-height: 50px;
        color: #151313;
        padding: 0 !important;
    }
    
    .add-icon span {
        display: block;
        cursor: pointer;
    }
    
    .add-icon span:hover {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    
    .main-flex {
        /* display: table; */
        /* margin: 0 -15px;
    height: calc(100% - 85px); */
        /* display: none; */
        margin: 0 -15px;
        height: calc(100% - 60px);
    }
    
    .main-flex .inner-flex {
        padding: 0 15px;
        /* display: table-cell; */
        vertical-align: top;
    }
    
    .main-flex .inner-flex.lftcht-sec {
        width: 30%;
        float: left;
    }
    
    .main-flex .inner-flex.rghtcht-sec {
        margin-left: 30%;
    }
    
    .chatlist-hd {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    
    .chatlst-srch {
        position: relative;
        margin-right: 15px;
        width: 100%;
    }
    
    .chatlst-srch input {
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        border-radius: 10px;
        padding: 11px 15px;
        border: 1px solid #fff;
        padding-left: 35px;
        width: 100%;
    }
    
    .chatlst-srch input:focus-visible {
        outline: #cacaca auto 0.5px !important;
        outline-offset: 0.5px;
    }
    
    .chatlst-srch .srch-icon {
        position: absolute;
        top: 10px;
        left: 10px;
        display: block;
    }
    
    .card {
        background: #FFFFFF;
        border-radius: 6px;
        padding: 20px;
        position: relative;
        border: 1px solid #fff;
    }
    
    /* .chatlist-card ul li .card:hover {
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    } */

    .card:hover {
         background-color: #fff; 
    }
    
    .chatlist-card ul li .card {
        cursor: pointer;
        border: 0px solid transparent;
        padding: 10px 10px;
    }
    
    .open-chat .card {
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    }
    
    .chatlist-card {
        height: 100%;
        overflow: auto;
        direction: rtl;
        padding-left: 10px;
    }
    
    .chatlist-card ul {
        direction: ltr;
        padding-bottom: 10px;
        margin-left: 0;
        padding-left: 0;
        max-height: 65vh;
    }
    
    .chatlist-card ul li+li {
        margin-top: 20px;
    }
    
    .open-chat > li > .card:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 8px;
        background: #FA6969;
        border-radius: 0px 6px 6px 0px;
    }
    
   /*  .chatlist-card ul li .card .d-flex {
        margin: 0 0px;
        align-items: center;
    } */
    
    .chatlist-card ul li .card .d-flex .flx-iner {
        padding: 0 0px;
    }
    
    .userchat-img img {
        border-radius: 50%;
    }
    
    .usercht-info {
        margin-left: 8px;
    }
    
    .usercht-info h3 {
        /* font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 163px; */
        font-weight: 800;
        font-size: 15px;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }
    
    .usercht-info p {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #464646;
        padding-top: 5px;
    }
    
    .chat-header {
        background: linear-gradient(90.04deg, #83112F 7.6%, rgba(131, 17, 47, 0) 99.13%), /* url("../../images/images-chat/chating-bg.png"); */ ;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
    }
    
    .chathead-user h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #FFFFFF;
    }
    
    .chathead-user p {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        padding-top: 4px;
    }
    
    .elipsv-svg {
        display: block;
        font-size: 22px;
        color: #fff;
        padding: 0 7px;
        cursor: pointer;
        vertical-align: middle;
    }
    
    .chating-sec {
        background: url("../../images/images-chat/chating-bg.png"), #F2F2F2;
        border: 1px solid #DBDBDB;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        /* padding: 20px; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0 1rem ;
    }
    
    .chating-sec ul li {
        position: relative;
        /* max-width: 600px; */
    }
    
    .chating-sec ul li .card {
        max-width: 90%;
        width: 600px;
    }
    
    .chating-sec ul li.msg-sender {
        padding-right: 20px;
        /* margin: 0 0 0 auto; */
    }
    
    .chating-sec ul li.msg-reciver {
        padding-left: 20px;
        /* margin: 0 auto 0 0; */
    }
    
    .chating-sec ul li .elipsv-icon {
        position: absolute;
        bottom: 0;
    }
    
    .chating-sec ul li.msg-sender .elipsv-icon {
        right: 0;
    }
    
    .chating-sec ul li.msg-reciver .elipsv-icon {
        left: 0;
    }
    
    .chating-sec ul li .elipsv-icon .elipsv-svg {
        color: #9BA5B4;
        display: inline-flex;
    }
    
    .chating-sec ul .msg-sender+.msg-reciver {
        margin-top: 22px;
    }
    
    .chating-sec ul li .card {
        position: relative;
        box-shadow: 0px 4px 20px -5.71709px rgb(0 0 0 / 15%);
        padding: 20px 20px;
    }
    
    .chating-sec ul li.msg-reciver .card {
        border-radius: 20px 20px 20px 0px;
        margin: 0 auto 0 0;
    }
    
    .chating-sec ul li.msg-sender .card {
        border-radius: 20px 20px 20px 0px;
        margin: 0 0 0 auto;
    }
    
    .chating-sec ul li .card .msg-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .chating-sec ul li .card .msg-head .msg-sendername {
        display: flex;
        align-items: center;
        text-transform: uppercase;
    }
    
    .chating-sec ul li.msg-sender .card .badge-crclname {
        margin-left: 8px;
    }
    
    .chating-sec ul li.msg-reciver .card .badge-crclname {
        margin-right: 8px;
    }
    
    .badge-crclname span {
        background: #2A0F0F;
        font-weight: 600;
        font-size: 12px;
        line-height: 13px;
        color: #FFFFFF;
        width: 39px;
        height: 39px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
    }
    
    .chating-sec ul li.msg-reciver .badge-crclname span {
        background: #2C693D;
    }
    
    .chating-sec ul li .card .msg-head p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #606F88;
    }
    
    .chating-sec ul li .card .msg-descrp {
        margin-top: 10px;
    }
    
    .chating-sec ul li .card .msg-descrp p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }
    
    .chat-footer .chat-typinput textarea,
    .chat-footer .chat-typinput textarea:active,
    .chat-footer .chat-typinput textarea:focus,
    .chat-footer .chat-typinput textarea:focus-visible
    .chat-footer .chat-typinput textarea:focus-within {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        background: #ffffff;
        width: 100%;
        padding: 1rem;
        padding-right: 5rem;
        /* border: 1px solid rgba(40, 40, 40, 0.275); */
    }
    
    
    .chat-footer .chat-typinput {
        position: relative;
        height: 56px;
        background-color: #fff;
        border-radius: 4px;
    }
    
    .chat-footer .chat-typinput .chatfiles-uplodr {
        position: absolute;
        right: 16px;
        top: 0;
        bottom: 0;
    }
    
    .chat-footer .chat-typinput .chatfiles-uplodr .uplodr-flx {
        display: flex;
        align-items: center;
        height: 100%;
    }
    
    .chat-footer .chat-typinput .chatfiles-uplodr .uplodr-flx .chatimg-upldr {
        margin-right: 5px;
        cursor: pointer;
    }
    
    .chat-mic {
        cursor: pointer;
    }
    
    .chatftr-main {
        position: relative;
        padding: 0 126px 0 54px;
    }
    
    .atachment-icon {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
    }
    
    .sendmain-btn {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
    }
    
    .atachment-icon span {
        display: inline-block;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 100%;
    }
    
    .atachment-icon span svg:hover {
        box-shadow: 0px 4px 20px -5.71709px rgb(0 0 0 / 15%);
        transform: matrix(-1, 0, 0, 1, 0, 0);
        border-radius: 50%;
    }
    
    .primary-btn {
        background: #83112F;
        border-radius: 6px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        padding: 11px 15px;
        border: 1px solid #83112F;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    
    .primary-btn:hover {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        color: #ffffff;
        background: #640C23;
        border: 1px solid #640C23;
    }
    
    .sendmain-btn .primary-btn {
        max-width: 120px;
        width: 114px;
    }
    
    .chat-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 20px;
        z-index: 1;
    }
    
    .chating-sec ul li .card .msg-descrp .msginner-dtlhead p {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #606F88;
    }
    
    .msginner-dtlhead {
        display: flex;
        align-items: center;
        padding: 0px 0 8px;
    }
    
    .msginner-dtlhead .tasklist-icon {
        margin-right: 5px;
    }
    
    .chating-sec ul li .card .msg-descrp .msginnerdtl-lst p {
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: #606F88;
    }
    
    .chating-sec ul li .card .msg-descrp .msginnerdtl-lst h6 {
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        color: #606F88;
        min-width: 200px;
        margin-right: 10px;
    }
    
    .msginnerdtl-lst {
        border-top: 1px solid rgba(96, 111, 136, 0.2);
        border-bottom: 1px solid rgba(96, 111, 136, 0.2);
        padding: 10px 0;
    }
    
    .vision-chksec {
        padding: .3rem 0;
    }
    
    .msg-chk {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    
    .msg-chk label {
        position: relative;
        margin-right: 6px;
    }
    
    .msg-chk label input[type="checkbox"] {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        visibility: hidden;
    }
    
    .msg-chk label input[type="checkbox"]~span {
        display: block;
        background: #FFFFFF;
        border: 1px solid #999A9A;
        border-radius: 4px;
        width: 20px;
        height: 20px;
        position: relative;
        cursor: pointer;
    }
    
    .msg-chk label input[type="checkbox"]~span:before {
        content: "";
        position: absolute;
        left: 6px;
        top: 1px;
        display: inline-block;
        transform: rotate(45deg);
        height: 13px;
        width: 7px;
        border-bottom: 3px solid transparent;
        border-right: 3px solid transparent;
    }
    
    .msg-chk label input[type="checkbox"]:checked~span {
        background: #83112F;
        border-color: #83112F;
    }
    
    .msg-chk label input[type="checkbox"]:checked~span:before {
        border-right-color: #fff;
        border-bottom-color: #fff;
    }
    
    .dualbtn-main {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 12px;
        margin-top: 0px;
        align-items:center;
    }
    
    .btn-success {
        border-radius: 4px;
    }
    
    .btn-success:hover {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    
    .btn-danger {
        color: #fff;
        border-radius: 4px;
    }
    
    .btn-danger:hover {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    
    .btn-gp+.btn-gp {
        margin-left: 12px;
    }
    
    .tag-flxmsg {
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        
    }
    
    .tag-flxmsg h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #606F88;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 300ms;
    }
    
    .tag-flxmsg h5:hover {
        color: #640C23;
    }
    
     ::placeholder {
        color: #191D1E;
        opacity: 0.5;
    }
    
     :-ms-input-placeholder {
        color: #191D1E;
        opacity: 0.5;
    }
    
     ::-webkit-input-placeholder {
        color: #191D1E;
        opacity: 0.5;
    }
    
     ::-webkit-scrollbar {
        width: 8px;
    }
    
    .main-side::-webkit-scrollbar {
        width: 4px;
    }
    
     ::-webkit-scrollbar-track {
        background: #E4E4E4;
        border-radius: 8px;
    }
    
     ::-webkit-scrollbar-thumb {
        background: #B9B7B8;
        border-radius: 8px;
    }
    
     ::-webkit-scrollbar-thumb:hover {
        background: #B9B7B8;
    }
    
    .add-popup {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        height: 100%;
        padding: 40px 60px;
        /* opacity: 0;
    visibility: hidden; */
    }
    
    .open-pop .add-popup.readmore-modal,
    .open-firmapop .add-popup.aggiungi-pop,
    .open-atvitpopup .add-popup.contenuti-modal,
    .open-anteprima .add-popup.anteprima-modal,
    .open-eluminapop .add-popup.chatdelet-modal {
        opacity: 1;
        visibility: visible;
    }
    
    .add-popup .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgb(0 0 0 / 80%);
        z-index: 0;
    }
    
    body.open-pop .readmore-modal .overlay,
    body.open-firmapop .aggiungi-pop .overlay,
    body.open-atvitpopup .contenuti-modal .overlay,
    body.open-anteprima .anteprima-modal .overlay,
    body.open-eluminapop .chatdelet-modal .overlay {
        opacity: 1;
        visibility: visible;
    }
    
    .lm-outer {
        width: 100%;
        height: 100%;
        display: table;
        vertical-align: middle;
        overflow: auto;
    }
    
    .lm-inner {
        display: table-cell;
        vertical-align: middle;
    }
    
    .close-pop {
        padding: 0 0px;
        cursor: pointer;
        text-align: right;
    }
    
    .rlsttpwb-tabs {
        margin: 0 auto;
        background: #fff;
        border-radius: 15px;
        z-index: 5;
        position: relative;
        box-shadow: 0 16px 26px rgb(0 0 0 / 10%);
        overflow: hidden;
        max-width: 736px;
        overflow-y: auto;
        height: auto;
        width: 100%;
    }
    
    .rlsttpwb-tabs .suport-icon {
        position: relative;
        display: flex;
    }
    
    .rlsttpwb-tabs .suport-icon .gettch-icon {
        position: relative;
        left: 0;
        top: 0;
        margin-right: 6px;
    }
    
    .innerpop-descrp {
        padding: 25px 38px;
    }
    
    .innerpop-descrp .pop-heading {
        margin-bottom: 20px;
    }
    
    .innerpop-descrp .pop-heading h4 {
        margin: 0;
        font-weight: 700;
        font-size: 28px;
        line-height: 40px;
        color: #151313;
    }
    
    .bl-single label {
        margin-bottom: 8px;
    }
    
    .bl-single .form-control {
        display: block;
        width: 100%;
        padding: 14px 15px;
        color: #333333;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #BDBDBD;
        border-radius: 6px;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
    
    .pdp-btn {
        text-align: center;
        margin-top: 40px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    
    .pdp-btn .primary-btn {
        max-width: 192px;
        width: 192px;
    }
    
    .default-btn {
        color: #000000;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        max-width: 192px;
        width: 192px;
        padding: 11px 15px;
    }
    
    .default-btn:hover {
        color: #83112F;
    }
    
    .pdp-btn .primary-btn+.default-btn {
        margin-top: 5px;
    }
    
    .drpdown-chatdesrp {
        position: absolute;
        top: -167px;
        right: 14px;
    }
    
    .msg-reciver.drpdown-chatdesrp {
        right: 0;
        left: 16px;
    }
    
    .drpdown-chatdesrp ul {
        padding: 15px 15px;
        background: #FFFFFF;
        box-shadow: 0px 4px 15px rgb(0 0 0 / 15%);
        width: 152px;
        border-radius: 4px;
    }
    
    .drpdown-chatdesrp ul li {
        position: relative;
        max-width: 100%;
    }
    
    .drpdown-chatdesrp ul li a {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #3D3333;
        border-bottom: 2px solid #ffffff;
        padding: 2px 0;
    }
    
    .drpdown-chatdesrp ul li a:hover {
        border-bottom: 2px solid #83112f;
        color: #83112f;
    }
    
    .drpdown-chatdesrp ul li a .drpdn-iocn {
        margin-right: 10px;
    }
    
    .drpdown-chatdesrp ul li+li {
        margin-top: 2px;
    }
    
    .chthdr-elipse {
        position: relative;
    }
    
    .chthdr-elipse .drpdown-chatdesrp {
        top: 6px;
        right: 14px;
        z-index: 5;
    }
    
    .chthdr-elipse .drpdown-chatdesrp ul {
        width: 260px;
    }
    
    .chthdr-elipse .drpdown-chatdesrp ul li a {
        padding: 10px 0;
        border-bottom: unset;
    }
    
    .chthdr-elipse .drpdown-chatdesrp ul li+li {
        margin-top: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.3);
    }
    
    .main-side {
        height: 100%;
        overflow: auto;
    }
    
    .main-flex .inner-flex,
    .chat-detail,
    .chating-sec {
        /* height: 100vh; */
    }

    .lft-chatlist {
        height: calc(100vh - 150px);
    }
    
    .chating-sec {
        height: calc(100vh - 250px);
         padding-bottom: 80px; 
        position: relative;
    }
    
    html {
        height: 100%;
    }
    
    .lft-chatlist {
        padding-top: 70px;
        position: relative;
    }
    
    .chating-sec ul {
        height: 100%;
        overflow: auto;
        padding-bottom: 0px;
    }
    
    .elipsv-icon.dropdown.open .drpdown-chatdesrp {
        display: block;
        left: inherit;
        right: 14px;
        top: 3px;
    }
    
    .drpdown-chatdesrp ul li.dropdown-item {
        display: block;
        width: 100%;
        padding: 0;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        text-decoration: none;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
    }
    
    .elipsv-icon.dropdown .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0;
        vertical-align: 0.255em;
        content: "";
        border-top: 0 solid;
        border-right: 0 solid transparent;
        border-bottom: 0;
        border-left: 0 solid transparent;
    }
    
    .elipsv-icon.dropdown .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        /* display: none; */
        min-width: 10rem;
        padding: 0 0;
        margin: 0;
        font-size: unset;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: transparent;
        background-clip: padding-box;
        border: unset;
        border-radius: 0;
    }
    
    .msg-sender .drpdown-chatdesrp.edit-elpsdrp {
        /* display: none; */
        z-index: 9;
    }
    
    .msg-sender .elipsv-icon.dropdown.open .drpdown-chatdesrp.edit-elpsdrp {
        top: -162px;
        right: 14px;
    }
    
    .msg-reciver .drpdown-chatdesrp.edit-elpsdrp {
        /* display: none; */
        z-index: 9;
    }
    
    .msg-reciver .elipsv-icon.dropdown.open .drpdown-chatdesrp.edit-elpsdrp {
        top: -162px;
        right: 0;
        left: 15px;
    }
    
    .atchment-drpdown {
        /* display: none; */
        bottom: 80%;
        left: 0px;
        position: absolute;
        z-index: 5;
    }
    
    .atachment-icon.open>span {
        transition: all 300ms;
    }
    
    .succes-atchmnt {
        transition: all 300ms;
    }
    
    .atachment-icon.open span svg .succes-atchmnt {
        fill: #4B9765;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 4px 20px -5.71709px rgba(0, 0, 0, 0.15);
    }
    
    .atachment-icon span svg:hover .succes-atchmnt {
        fill: #4B9765;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 4px 20px -5.71709px rgba(0, 0, 0, 0.15);
    }
    
    .atchment-drpdown ul {
        padding: 15px 15px;
        background: #FFFFFF;
        box-shadow: 0px 4px 15px rgb(0 0 0 / 15%);
        width: 210px;
        border-radius: 4px;
    }
    
    .atchment-drpdown ul li.dropdown-item {
        display: block;
        width: 100%;
        padding: 0;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        text-decoration: none;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
    }
    
    .atchment-drpdown ul li+li {
        margin-top: 5px;
    }
    
    .atchment-drpdown ul li a {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #3D3333;
        border-bottom: 2px solid #ffffff;
        padding: 4px 0;
    }
    
    .atchment-drpdown ul li a:hover {
        border-bottom: 2px solid #83112f;
        color: #83112f;
    }
    
    .atchment-drpdown ul li a .drpdn-iocn {
        margin-right: 10px;
    }
    
    .atachment-icon.dropdown.open .atchment-drpdown {
        display: block;
    }
    
    .atachment-icon.dropdown .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0;
        vertical-align: 0;
        content: "";
        border-top: 0 solid;
        border-right: 0 solid transparent;
        border-bottom: 0;
        border-left: 0 solid transparent;
    }
    
   /*  .form-control:focus {
        box-shadow: unset;
        border: 1px solid #83112f;
    } */
    
    .teritary-btn {
        background: #FFFFFF;
        border-radius: 6px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        padding: 11px 15px;
        border: 1px solid #ffffff;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    
    .teritary-btn:hover {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        background: #F5F5F5;
        border: 1px solid #F5F5F5;
    }
    
    .frigm-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
        margin-bottom: 10px;
    }
    
    .frigm-btn .teritary-btn+.primary-btn {
        margin-left: 15px;
    }
    
    .frigm-btn .teritary-btn,
    .frigm-btn .primary-btn {
        width: 192px;
    }
    
    .bl-single textarea {
        resize: unset;
    }
    
    .innerpop-descrp .bl-single+.bl-single {
        margin-top: 17px;
    }
    
    .aggiungi-pop .innerpop-descrp {
        padding: 35px 38px;
    }
    
    .aggiungi-pop .bl-single input.form-control {
        background: #F1F2F3;
        border: 1px solid #BDBDBD;
    }
    
    .frmasgnd-sts {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    .frmasgnd-sts p {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #FFFFFF;
        transform: matrix(1, 0, 0, 1, 0, 0);
        background: #6524F0;
        border-radius: 4px;
        padding: 4px 16px;
    }
    
    .notarizzato {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 10px;
    }
    
    .chating-sec ul li .card .msg-descrp .notarizzato p {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #606F88;
        margin: 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 300ms;
    }
    
    .chating-sec ul li .card .msg-descrp .notarizzato p:hover {
        color: #640C23;
    }
    
    .chating-sec ul li .card .msg-descrp .notarizzato p span {
        margin-right: 5px;
    }
    
    .chating-sec ul li .card .msg-descrp .notarizzato p:hover span svg path {
        stroke: #640C23;
    }
    
    .chating-sec ul li.msg-reciver+li.msg-reciver {
        margin-top: 22px;
    }
    
    .sinaturen-img {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 90px;
        margin-top: 8px;
    }
    
    .tag-flxmsg+.frmasgnd-sts {
        margin-top: 10px;
    }
    
    .chating-sec ul li .card .msg-descrp .sucess-preventomsg {
        display: flex;
        justify-content: flex-end;
        padding: 10px 0;
    }
    
    .chating-sec ul li .card .msg-descrp .sucess-preventomsg p {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #606F88;
        display: flex;
        align-items: center;
    }
    
    .chating-sec ul li .card .msg-descrp .error-preventomsg {
        display: flex;
        justify-content: flex-end;
        padding: 10px 0;
    }
    
    .chating-sec ul li .card .msg-descrp .error-preventomsg p span {
        margin-right: 3px;
    }
    
    .chating-sec ul li .card .msg-descrp .error-preventomsg p {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #606F88;
        display: flex;
        align-items: center;
    }
    
    .chating-sec ul li .card .msg-descrp .sucess-preventomsg p span {
        margin-right: 3px;
    }
    
    .chating-sec ul li .card .msg-descrp .vision-chksec+.sucess-preventomsg {
        padding-top: 0;
    }
    
    .msginnerdtl-lst .lampadine-tp h5 {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #606F88;
    }
    
    .msginnerdtl-lst .lampadine-tp h4 {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #606F88;
    }
    
    .lampadine-tp+.lampadine-tp {
        margin-top: 10px;
    }
    
    .msginnerdtl-lst .lampadine-tp h5+h5 {
        margin-left: 15px;
    }
    
    .chating-sec ul li .card .msg-descrp .sucess-preventomsg+.sucess-preventomsg {
        padding-top: 0;
    }
    
    .chating-sec ul li .card .msg-descrp .sucess-preventomsg+.error-preventomsg {
        padding-top: 0;
    }
    
    .tphead-cntnti {
        background: linear-gradient(90.04deg, #83112F 7.6%, rgba(131, 17, 47, 0) 99.13%), url("../../images/images-chat/chat-hdr.png");
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px;
        position: relative;
    }
    
    .tphead-cntnti .pop-heading h4 {
        font-weight: 700;
        font-size: 28px;
        line-height: 40px;
        color: #FFFFFF;
    }
    
    .tphead-cntnti .close-pop svg path {
        stroke: rgb(255 255 255);
    }
    
    .tphead-cntnti .close-pop svg {
        transition: all 300ms;
    }
    
    .tphead-cntnti .close-pop svg:hover {
        background: #fff;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 4px;
    }
    
    .tphead-cntnti .close-pop svg:hover path {
        stroke: #640C23;
    }
    
    .atchmnt-tabngmenu {
        max-width: 511px;
        width: 511px;
        margin: 0 auto;
        margin-top: -15px;
    }
    
    .atchmnt-tabngmenu .nav-tabs {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: unset;
    }
    
    .atchmnt-tabngmenu .nav-tabs:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 10px;
        background: #F0F0F0;
        border-radius: 5px;
    }
    
    .atchmnt-tabngmenu .nav-tabs .tab-item {
        position: relative;
    }
    
    .atchmnt-tabngmenu .nav-tabs .tab-item a {
        display: block;
        padding: 20px !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #BDBDBD;
        transition: all 300ms;
    }
    
    .atchmnt-tabngmenu .nav-tabs .tab-item a.active {
        border-right: 0;
        box-shadow: unset;
    }
    
    .atchmnt-tabngmenu .nav-tabs .tab-item a:hover {
        color: #83112F;
    }
    
    .atchmnt-tabngmenu .nav-tabs .tab-item a.active {
        color: #83112F;
    }
    
    .atchmnt-tabngmenu .nav-tabs .tab-item a:before,
    .atchmnt-tabngmenu .nav-tabs .tab-item a.active:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 10px;
        border-radius: 5px;
        transition: all 300ms;
    }
    
    .atchmnt-tabngmenu .nav-tabs .tab-item a:before {
        background: #F0F0F0;
    }
    
    .atchmnt-tabngmenu .nav-tabs .tab-item a:hover:before {
        background: #83112F;
    }
    
    .atchmnt-tabngmenu .nav-tabs .tab-item a.active:before {
        background: #83112F;
    }
    
    .atchmnt-maintb .tab-content {
        margin-top: 10px;
    }
    
    .galerydoc-atchment .glry-list {
        display: flex;
        margin: -10px 0px;
        flex-wrap: wrap;
        direction: ltr;
    }
    
    .galerydoc-atchment .glry-list li {
        flex: 1 1 33%;
        padding: 10px 10px;
    }
    
    .contenuti-modal .innerpop-descrp {
        padding: 25px 30px;
    }
    
    .poptbng-scrol {
        max-height: 55vh;
        overflow-y: auto;
        overflow-x: hidden;
        direction: rtl;
    }
    
    .galerydoc-atchment .glry-list li .glry-img {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 66px;
        border-radius: 6px;
        cursor: pointer;
    }
    
    .documnti-card {
        width: 520px;
        max-width: 100%;
        margin: 0 auto;
        background: rgb(255 255 255 / 30%);
        border: 1px solid #B7B7B7;
        box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
        border-radius: 16px;
        padding: 20px;
    }
    
    .documnti-card .tpdcmnt-dcmnttl p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #151313;
    }
    
    .documnti-card .msginner-dtlhead {
        padding: 15px 0;
    }
    
    .documnti-card .msginner-dtlhead p {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #151313;
    }
    
    .documnti-card .creato-flx {
        display: flex;
        margin: 0 -8px;
        justify-content: space-between;
    }
    
    .documnti-card .iner-creto {
        padding: 0 8px;
    }
    
    .documnti-card .iner-creto p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #151313;
    }
    
    .chkvrbia-listng {
        direction: ltr;
    }
    
    .chkvrbia-listng li+li {
        margin-top: 20px;
    }
    
    .pdf-topdoc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
    }
    
    .sldr-img .sldr-bg {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        /* padding-top: 66%; */
        height: 40vh;
        display: block;
        border-radius: 4px;
    }
    
    .anteprima-modal .rlsttpwb-tabs {
        max-width: 530px;
    }
    
    .sinkpopup-crousel .owl-theme .owl-nav {
        color: #FFF;
        font-size: 0;
        margin: 0;
        padding: 0;
        background: transparent;
        display: inline-block;
        cursor: pointer;
        border-radius: 0;
        transition: all 0.3s ease;
        margin-top: 0;
    }
    
    .sinkpopup-crousel #sync1.owl-theme .owl-next,
    .sinkpopup-crousel #sync1.owl-theme .owl-prev {
        width: 22px;
        height: 40px;
        margin-top: -20px;
        position: absolute;
        top: 50%;
        transform: translatey(-50%);
    }
    
    .sinkpopup-crousel #sync1.owl-theme .owl-prev {
        left: -15px;
    }
    
    .sinkpopup-crousel #sync1.owl-theme .owl-prev:before,
    .sinkpopup-crousel #sync1.owl-theme .owl-next:before {
        font-family: fontAwesome;
        color: #3A3033;
        font-size: 40px;
        line-height: 40px;
    }
    
    .sinkpopup-crousel #sync1.owl-theme .owl-prev:before {
        content: "\f104";
    }
    
    .sinkpopup-crousel #sync1.owl-theme .owl-next:before {
        content: "\f105";
    }
    
    .sinkpopup-crousel #sync1.owl-theme .owl-next {
        right: -15px;
    }
    
    .sinkpopup-crousel .owl-theme .owl-dots,
    .sinkpopup-crousel .owl-theme .owl-nav {
        text-align: center;
        -webkit-tap-highlight-color: transparent;
    }
    
    .sinkpopup-crousel .owl-theme .owl-dots .owl-dot span {
        width: 8px;
        height: 8px;
        margin: 0px 2px;
        background: #c9c0c0;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity .2s ease;
        border-radius: 50%;
    }
    
    .sinkpopup-crousel .owl-theme .owl-dots .owl-dot.active span,
    .sinkpopup-crousel .owl-theme .owl-dots .owl-dot:hover span {
        background: #83112f;
    }
    
    .crusel-thumbnail .thumb-sldrimg {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100px;
        height: 68px;
        display: block;
        /* padding: 10px; */
        margin: 0 auto;
        border-radius: 4px;
    }
    
    .crusel-thumbnail .owl-nav {
        display: none;
    }
    
    .owl-carousel .owl-nav button:focus {
        outline: none;
    }
    
    .sinkpopup-crousel .sldr-img {
        padding: 0 15px;
    }
    
    .sinkpopup-crousel #sync1 .owl-dots {
        /* margin-top: -10px; */
        position: absolute;
        bottom: 28px;
        left: 0;
        right: 0;
        padding: 0 20px;
    }
    
    .sinkpopup-crousel #sync2.owl-theme .owl-dots,
    .sinkpopup-crousel #sync2.owl-theme .owl-nav {
        display: none;
    }
    
    .sinkpopup-crousel #sync1+#sync2 {
        margin-top: 15px;
    }
    
    .chiudi-ttl {
        padding-top: 25px;
    }
    
    .chiudi-ttl h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        cursor: pointer;
        color: #000000;
    }
    
    .chiudi-ttl h4:hover {
        color: #83112F;
        text-decoration: underline;
    }
    
    .add-popup.chatdelet-modal .rlsttpwb-tabs {
        max-width: 540px;
    }
    
    .chatdelet-modal .innerpop-descrp .pop-heading h4 {
        font-size: 24px;
    }
    
    .chatdelet-modal .innerpop-descrp {
        padding: 25px 38px;
        text-align: center;
    }
    
    .chatdelet-modal .innerpop-descrp .pop-heading {
        margin-bottom: 15px;
    }
    
    .chatdelet-modal .pdp-btn {
        margin-top: 25px;
    }
    
    .chatdelet-modal .msgdeleat-descrp p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
    }
    
    .chating-sec ul .msg-reciver+.msg-sender {
        margin-top: 22px;
    }
    
    .chating-sec ul .msg-sender+.msg-sender {
        margin-top: 22px;
    }
    
    .chating-sec ul li .card .msg-descrp .elumina-status p {
        color: rgba(0, 0, 0, 0.5);
        padding-top: 15px;
        font-size: 15px;
    }
    
    .chating-sec ul li .card .modify-status {
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;
    }
    
    .chating-sec ul li .card .modify-status p {
        color: #606F88;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }
    
    .respondi-msgbox {
        position: absolute;
        top: -100px;
        left: 0;
        right: 0;
        /* padding: 0 20px; */
        z-index: -1;
        /* opacity: 0; */
        /* visibility: hidden; */
        transition: all 300ms;
        padding-top: 40px;
    }
    
    .open-replybx .respondi-msgbox {
        opacity: 1;
        visibility: visible;
        padding-top: 0;
    }
    
    .repondimsg-card {
        background: #606F88;
        border-radius: 14px 14px 0px 0px;
        padding: 20px 15px 40px;
    }
    
    .respondi-detail {
        position: relative;
        padding-right: 35px;
    }
    
    .close-respns {
        position: absolute;
        top: 0;
        right: 0;
    }
    
    .respondi-detail h6 {
        font-weight: 600;
        font-size: 14px;
        line-height: 12px;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 8px;
    }
    
    .respondi-detail p {
        font-weight: 500;
        font-size: 14px;
        line-height: 12px;
        /* or 86% */
        color: rgba(255, 255, 255, 0.8);
    }
    
    .close-respns span {
        display: block;
        cursor: pointer;
    }
    
    .close-respns span svg path {
        transition: all 300ms;
    }
    
    .close-respns span svg:hover path {
        stroke: #83112F;
    }
    
    .reply-resultmsg .rply-bg {
        background: #606F88;
        border-radius: 6px;
        padding: 15px;
    }
    
    .reply-resultmsg {
        padding: 15px 0;
    }
    
    .reply-resultmsg .rply-bg h6 {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 8px;
    }
    
    .reply-resultmsg .rply-bg p {
        font-weight: 500;
        font-size: 14px;
        line-height: 12px;
        color: rgba(255, 255, 255, 0.8);
    }
    
    .bruno-name p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
    }
    
    .chating-sec ul li .msg-chk.slect-chat {
        position: absolute;
        top: 0;
        left: -25px;
        display: block;
    }
    
    body:not(.open-checkbox) .chating-sec ul li .msg-chk.slect-chat,
    body:not(.open-checkbox) .seleziona-ftr {
        /* display: none; */
    }
    
    body.open-checkbox .chating-sec ul {
        padding-left: 30px;
    }
    
    .seleziona-ftr {
        position: relative;
        background: #151313;
        border-radius: 16px;
        padding: 12px 20px;
    }
    
    .seleziona-ftr .close-respns {
        position: absolute;
        top: 16px;
        left: 25px;
        right: unset;
    }
    
    .seleziona-ftr .ftrbtn-selzina {
        margin-left: 40px;
    }
    
    .seleziona-ftr .ftrbtn-selzina .seleziona-btn {
        width: 100%;
        color: #ffffff;
    }
    
    body.open-checkbox .chatftr-main {
        display: none;
    }
    
    .chating-sec ul li .msg-chk.slect-chat label {
        margin-right: 0;
    }
    
    .carousel.carousel-slider .control-arrow:hover {
        background: transparent !important;
    }
    
    .carousel .carousel-status {
        display: none !important;
    }
    
    .carousel .thumb.selected,
    .carousel .thumb:hover {
        border: 2px solid #a33b57 !important;
    }
    
    .carousel .thumb {
        padding: 0 !important;
        border: 2px solid #fff;
        border-radius: 6px;
    }
    
    .carousel .control-dots .dot {
        box-shadow: 1px 1px 2px rgb(0 0 0 / 10%) !important;
        background: #ffffff !important;
        border: 2px solid #4d4d4ddd;
        margin: 0 2px !important;
    }
    
    .carousel .control-dots .dot.selected,
    .carousel .control-dots .dot:hover {
        opacity: 1;
        background: #83112f !important;
        border: 2px solid #83112f;
        box-shadow: 1px 1px 2px rgb(0 0 0 / 10%) !important;
    }
    
    .carousel .thumbs-wrapper {
        margin: 20px 0 0 !important;
    }
    
    .navbar-menu {
        display: none;
    }
    
    .close-menu {
        display: none;
    }
    
    .withsrch-addpop {
        display: none;
    }
    
    .mbil-chatfltr {
        display: none;
    }
    
    .msgsend-svgbtn {
        display: none;
    }
    
    .chatimg-upldr svg:hover path {
        stroke: rgb(131 17 47);
    }
    
    .msgsend-svgbtn svg:hover path {
        fill: rgb(131 17 47);
    }
    
    .chat-mic svg:hover path {
        stroke: rgb(131 17 47);
    }
    
    .chating-sec ul li .elipsv-icon .elipsv-svg:hover {
        color: #640C23;
    }
    
    .elipsv-svg:hover {
        color: #83112f;
    }
    
    .app-box {
        height: 100%;
    }
    
    .drpdown-chatdesrp {
        top: 0;
        right: 14px;
        display: block;
    }
    
    .chthdr-elipse .dropdown-menu.drpdown-chatdesrp {
        right: 0;
        left: unset;
    }
    
    .carousel .slider {
        height: auto;
        background-color: #fff;
        overflow: initial;
    }
    
    .carousel .control-dots {
        height: auto;
        overflow: hidden;
    }
    
    .carousel.carousel-slider {
        height: 288px;
    }
    .carousel .thumbs {
        transform: translate3d(0, 0, 0) !important;
    }
    .carousel .thumbs::-webkit-scrollbar {
        /* width: 4px; */
        height: 6px;
    }