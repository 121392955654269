.checklistModalBody {
    min-height: 400px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.cntntitvt-ppbtn input[type=file]{
    opacity:0;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}

.user-chat-img {
    height:50px;
    width:50px;
}

.user-chat-img  img{
    max-width: 100%;
}

.card-contabilità-btn {
    box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding:2rem;
}
.card-contabilità-btn.selected {
    box-shadow:0px 4px 4px rgba(156, 203, 242, 0.25);
    background-color: var(--edit-color) !important;
}

.contabilitàModal,
.firma-modal{
    --bs-modal-width: 600px !important; 
}

.contabilitàModal.preventivo {
    --bs-modal-max-width: 1000px !important;
    --bs-modal-width: 1000px !important;
}
.contabilitàModalBody, .eventModalBody {
    min-height: 400px;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.contabilitàModalBody > .tab-content {
    padding: 0 !important;
    overflow-x: auto;
}

@media (max-width: 1000px) {
    .tab-content {
        overflow-x: scroll;
    }
}

.cont-tabs > .nav-item > .nav-link {
    color: gray;
    outline: none;
    border: none;
}

.cont-tabs > .nav-item > .nav-link.active {
    border-bottom: rgb(128, 0, 105);
    color: var(--primary);
    font-weight: bold;
    border-bottom: 2px solid var(--primary);
}

.chat-header {
/*     background-image: url("../images/bg.png");
 */    
 background: linear-gradient(90.04deg, #83112F 7.6%, rgba(131, 17, 47, 0) 99.13%);
 background:  #83112F;


    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

/*RIASSUNTO CONTABILITA*/

.rcModal.modal {
    --bs-modal-width: fit-content !important; 
}

.rc-body{
    background-color: #eee;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-radius: 4px;
    min-height: 400px;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
}

.rc-card-container {
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 1.5rem 1rem;
    border-radius: 4px;
   
}

.rc-range {
    width: 100%;
}

.chart-rc-container {
    position: relative;
}
.chart-title-rc{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}

.rc-donut {
    padding: 3rem
}

@media (max-width: 576px){
    .rc-donut.rc-details {
        width: 100%;
        height: auto;
    }
}

/*PARTCEIPANTI*/

.part-dots-menu {
    position: relative;
    width: fit-content;
    cursor: pointer;
}

.participant-options-menu {
    height: fit-content;
    width: 120px;
    padding: 1rem;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    right:100%;
    top:5%;
}


/* SIGNATURE */

.signature-canva canvas {
    width: 100% !important;

}


/* AUDIO */

.audio-recorder {
    background-color: #FFF !important;
    box-shadow: unset !important;
}

.audio-recorder-mic {
    color: var(--lightgrey) !important;
    filter: invert(45%) sepia(24%) saturate(455%) hue-rotate(179deg) brightness(88%) contrast(86%);
}


/*CHAT PLACEHOLDER*/

.chat-placeholder {
    height: calc(100vh - 150px);
}

.chat-placeholder > div {
    height: 95%;
    background-color: white;
    border-radius: 10px;
    /* box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25); */

}

.chat-textarea {
    resize: none;
    box-sizing: border-box;
    height: 56px;
    min-height: 41px;
    max-height: 97px;
    border: 1px solid transparent;
    outline: none;
    padding: 0 24px 0 24px;
    overflow: hidden;
    border-radius: 10px;
}

.chat-alert {
    position: absolute;
    z-index: 10;
    bottom: 1%;
    width: 100%;
}

.chat-alert .btn-close {
    filter: invert(1);
}

.ca-body {
    background-color: white !important;
}

.ca-img {
    height: 150px;
    max-width: 250px;
    object-fit: cover;
    border-radius: 4px;
}

.ca-img-row {
    max-width: 600px;
}

.card-ca{
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
    background-color: white;
    padding: 1rem;
}

.tab-bar-ca {
    background: #eee;
    border-radius: 5px;
    height: 9px;
    margin-top: 7px;
    width: 100%;
    z-index: 1;
}

.tab-bar-ca.active-tab-bar {
    background: #C08796;
    z-index: 2;
}

/* REMOVE BORDER AND FOCUS MUI INPUT AUTOCOMPLETE*/

.searchInputChat > .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
    border: none;
    border-color: transparent;
    border-width: 0;
  }

  .searchInputChat > .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
    border: none;
    border-color: transparent;
    border-width: 0;
  }
  
  .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
    padding-top: 9px!important;
    padding-bottom: 9px!important;


  }

  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
    border: 0px !important;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
    border: 0px !important;
  }

  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: transparent !important;
    transform: none !important;
    -webkit-transform: none !important;
    -webkit-transition: none !important;
    transition: none !important;
  }

  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    color: transparent !important;
    transform: none !important;
    -webkit-transform: none !important;
    -webkit-transition: none !important;
    transition: none !important;
  }

  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    -webkit-transform: none !important;
    -webkit-transition: none !important;
    transition: none !important;
    left: 8px !important;
    top: 17px !important;
    transform: none !important;


  }