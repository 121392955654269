
@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../node_modules/bootstrap/dist/css/bootstrap-utilities.css";
@import "../node_modules/bootstrap/dist/css/bootstrap-grid.css";
@import "./assets/css/otherstyle.css";
@import "./assets/css/all.min.css";
@import '../node_modules/react-modal-video/css/modal-video.css';

/* 
@font-face {
	font-family: 'Urbanist';
	src: url('./assets/font/Urbanist-VariableFont_wght.ttf') format('truetype');
	font-weight: 100 900;
	font-display: swap;
	font-style: normal;
  }
 */
* {
	box-sizing: border-box;
}

.table-font {
    font-family: 'Urbanist', sans-serif !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.table-activity::-webkit-scrollbar {
	display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .table-activity {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
  }


ol,
a,
p,
h1,
h2,
h3 h4,
h5,
h6 {
	margin: 0 !important;
	padding: 0 !important;
	text-decoration: none;
	outline: none;
}

input {
	outline: none;
	/* width: 100%; */
}

a,
a:hover,
a:focus {
	cursor: pointer;
	-webkit-transition: all 0.4s ease 0s;
	-moz-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	text-decoration: none;
	outline: none;
	text-decoration: none !important;
}

button:focus {
	outline: none;
	border: none;
}

/* button:focus,
input:focus {
	box-shadow: none !important;
} */

button {
	cursor: pointer;
}

.cursor-pointer {
	cursor: pointer;
}

*,
*:after,
*:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.ls li {
	list-style: none;
}

.cur {
	cursor: pointer;
}

.po img {
	opacity: 0;
}

.mw-100 img {
	max-width: 100%;
}

.fw-100 img {
	width: 90%;
}

.rel {
	position: relative;
}

.img-100 img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

:root {
	--primary: #83112f;
	--light: rgba(131, 17, 47, 0.5);
	--white: #fff;
	--yellow: #f2994a;
	--pink: #fdb7e9;
	--darkgrey: #3c4257;
	--lightgreen: #78ab8b;
	--lightgrey: #606f88;
	--lightblue: #5998bc;
	--btn-default-size: 44px;
	--green: #6fcf97;
	--purple: #a47df7;
	--grey: #606f88;
	--edit-color: #9ccbf2;
	--slc: #214bb6;
}

body {
	font-family: "Urbanist", "Mulish",  sans-serif !important;
	font-weight: 400;
	margin: 0 !important;
	padding: 0 !important;
	background: #f5f5f5 !important;
	overflow: auto !important;
}

.sidebar-main {
	padding: 2rem;
	background-size: cover;
	height: 100vh;
	/* width: 21%; */
	left: 0;
	bottom: 0;
	min-height: 650px;
	z-index: 50;
	background-color: #262d3c;
}

.sidebar-main-slc {
	padding: 40px 20px;
	background-size: cover;
	height: 100vh;
	/* width: 21%; */
	left: 0;
	bottom: 0;
	min-height: 650px;
	z-index: 50;
	background-color: #262d3c;

}

.mobile-sidebar {
	position: fixed;
	padding-top: 18px;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 22px;
	background-size: cover;
	width: 100%;
	z-index: 50;
	overflow-x: hidden;
	background-color: #262d3c;

}

.logo-menu-group {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.mobile-logo {
	width: 80px;
	font-weight: 100;
}

.mobile-menu {
	background-color: transparent;
	color: white;
	border: none;
}

.mobile-menu-icon {
	width: 25px;
	height: 25px;
}

.logo {
	width: 50% !important;
	max-height: 42px;

}

.app-box.app-small .logo {
	width: 80% !important;
	max-height: 42px;
	text-align: center;
}

.app-box.app-small .sidebar-main {
/* 	width: 9%;
 */}

.app-box.app-small .sidebar-main-slc {
	/* width: 9%; */
}

.right-side-box {
	padding: 30px;
	/*  margin-left: 23%;
    margin-right: 3%;
    margin-left: 23%; 
    margin-left: 21.5%; */
	/* margin-left: 21.5%;
	width: 79%; */
	margin: 0 auto;
}

.app-box.app-small .right-side-box {
	/* margin-left: 9%; */
	/* width: 91%; */
}

.app-box.app-small .head-bar {
	/* left: 9%;
	width: 91%; */
}

.app-box.app-small .back-btn-1 {
	left: calc(9% + 1.5rem);
}

.dropdown-link .sub-links {
	/* display: none; */
	max-height: 0px;
	overflow: hidden;
	transition: opacity 0.7s;
	opacity: 1;
	max-height: 500px;
	transition: max-height 0.4s ease-in;
	transition: opacity 0.7s;
	
	padding: 1rem;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: rgba(39, 46, 60, 0.8) !important;
}


/* .app-box.app-small .menu-list,
.app-box.app-small .links-log {
  display: none !important;
} */
.app-box.app-small .text-link,
.app-box.app-small .icon-down {
	display: none;
}

.app-box.app-small .icon-link {
	display: block;
	margin: 0 auto;
	text-align: center !important;
}

.menu-list {
	margin-top: 40px;
	overflow-y: auto;
	height: 100%;
	overflow-x: hidden;
	/* height: 50%; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-x: hidden;/* rimuovere se vogliamo vedere barra di scorrimento */
}

/* rimuovere se vogliamo vedere barra di scorrimento */
.menu-list::-webkit-scrollbar {
	display: none;
}

.app-box.app-small .menu-list {
	overflow: unset !important;
}

.menu-list li .icon-link,
.links-log li .icon-link {
	display: none;
}

.menu-list li,
.links-log li {
	margin-bottom: 10px;
}

.menu-list li a,
.links-log li a,
.menu-list li button,
.sub-links li a {
	color: #fff;
	font-size: 16px;
	font-weight: 300;
}

/* .menu-list li a:hover, */
.links-log li a:hover,
/* .menu-list li button:hover, */
.sub-links li a:hover {
	color: var(--primary);
}

.left-nav-form li {
	margin-bottom: 10px;
}

.left-nav-form li a {
	margin-bottom: 10px;
	color: #91939b;
	font-size: 16px;
	font-weight: 400;
}

.left-nav-form li a:hover,
.left-nav-form li .active {
	margin-bottom: 10px;
	color: var(--primary);
	font-size: 16px;
	font-weight: 400;
}

.app-box.app-small .dropdown-link {
	position: relative;
}

.app-box.app-small .dropdown-link .sub-links {
	position: absolute;
	background: rgba(0, 0, 0, 0.8);
	border-radius: 0 10px 10px 0;
	left: 100%;
	top: 0;
	min-width: 200px;
	z-index: 1;
}

.app-box.app-small .dropdown-link .sub-links ul {
	padding: 0;
}

.app-box.app-small .dropdown-link .sub-links ul li a {
	padding: 4px 10px;
	width: 100%;
	display: block;
}

.dropdown-link .sub-links ul li:last-child {
	margin-bottom: 0px;
}

.form-check-input:checked {
	background-color: var(--primary) !important;
	border-color: var(--primary) !important;
}

.submit-btn {
	background: var(--primary);
	color: #fff;
}

.submit-btn:hover {
	background: #111;
	color: #fff;
}

.backwards-btn {
	background: #fff;
	color: #000;
}

.backwards-btn:hover {
	background: #111;
	color: #fff;
}

.bag-btn {
	background: #606f88;
	color: #fff;
}

.bag-btn:hover {
	background: var(--primary);
	color: #fff;
}

.interest-link {
	color: var(--primary);
}

.black-link {
	color: black;
	cursor: pointer;
}

.black-link:hover {
	text-decoration: underline;
}

.model-modul-group {
	width: 100%;
	display: block;
}

.form-item-align-box {
	background: #fff;
	border-radius: 5px;
	/* padding: 2rem !important; */
}

@media (max-width: 1300px) {

	.app-box.app-small .sidebar-main {
		padding: 2rem 1rem;
	}
}

@media (max-width: 1024px) {
	.app-box.app-small .sidebar-main {
		width: 80px;
		padding: 2rem .5rem;
	}

	.app-box.app-small .sidebar-main-slc {
		/* width: 80px; */
	}

	.app-box.app-small .right-side-box {
/* 		margin-left: 80px;
 */		/* width: calc(100% - 80px); */
	}

	.app-box.app-small .head-bar {
		/* left: 80px;
		width: calc(100% - 80px); */
	}

	.app-box.app-small .back-btn-1 {
		left: calc(80px + 1.5rem);
	}

	.form-item-align-box input[type="text"] {
		width: 100%;
	}

	.head-bar {
		/* left: 80px !important; */
	}
}

.topHeading-title {
	margin-top: 40px;
}

@media (max-width: 900px) {
	.topHeading-title {
		margin-top: 130px;
	}

	.app-box .sidebar-main {
		display: none !important;
	}

	.app-box .sidebar-main-slc {
		display: none !important;
	}

	.right-side-box {
		margin: 0 !important;
		width: 100vw !important;
		padding: 10px 15px !important;
	}

	.title-search-group {
		margin-top: 120px;
		display: block;
	}

	.search-window {
		display: none !important;
	}

	.alarm-group {
		position: absolute;
		top: 100px;
		right: 10px;
	}

	.right-header {
		margin-top: 40px;
	}

	.btn-hidden {
		display: none;
		width: 0px;
	}

	.search-hidden {
		width: 100%;
		display: flex !important;
		justify-content: flex-start !important;
	}

	.search-show {
		width: 100%;
		display: flex !important;
		justify-content: space-between !important;
	}

	.modal-btn {
		width: 100%;
	}

	.desktop-label {
		display: none;
	}

	.mobile-label {
		display: show;
		padding-left: 35px;
		padding-right: 35px;
	}

	.search-bar {
		flex: auto;
	}

	.document-btn-group {
		justify-content: space-between !important;
	}

	.head-bar {
		/* position: absolute !important; */
		left: 0% !important;
		top: 80px !important;
		width: 100% !important;
		padding-right: 15px !important;
		padding-left: 15px !important;
		z-index: 10;
	}

	.back-btn-1 {
		left: 15px !important;
		top: 88px !important;
	}

	.logo-mark {
		height: 20px !important;
		margin-left: 5px !important;
	}

	.head-bar > h3 {
		font-size: 12px !important;
	}

	.right-logo > h3 {
		font-size: 12px !important;
	}

	.btn-search-group {
		flex-direction: row-reverse;
	}

	.search-input {
		flex: auto;
	}

	.section-modal-btn {
		width: 100% !important;
	}

	.plus-button {
		margin-right: 20px;
		border: none;
	background: transparent;
	}

	.state-bar {
		display: none !important;
	}

	.content-container {
		padding: 1rem !important;
	}

	.edit-content-container {
		padding: 1rem !important;
	}

	.modal-search {
		width: 100% !important;
	}

	.desktop-table {
		display: none;
	}

	.desktop {
		display: none;
	}

	.type-modal .modal-content {
		height: 85vh !important;
		border-radius: 10px;
		overflow: hidden;
	}

	.type-modal .modal-body {
		overflow-y: auto;
	}

	.fixed-btn {
		width: 480px;
	}
}

@media (max-width: 576px) {
	.fixed-btn {
		width: 92%;
	}
}

@media (max-width: 456px) {
	.fixed-btn {
		width: 90%;
	}
}

@media (min-width: 901px) {
	.mobile-sidebar {
		display: none;
	}

	.mobile-search-btn {
		display: none !important;
		width: 0px;
	}

	.mobile-close-btn {
		display: none;
	}

	.right-side-box {
		padding: 10px 13px !important;
	}

	.mobile-label {
		display: none;
	}

	.mobile-state-bar {
		display: none;
	}

	.title-font {
		font-size: 32px;
	}

	.mobile-table {
		display: none;
	}

	.mobile {
		display: none;
	}
}

.show-mobile-sidebar {
	display: show !important;
}

.bottom-position {
	position: absolute;
	bottom: 60px;
}

.display-show {
	display: show !important;
}

.display-hidden {
	display: none !important;
}

.full-height {
	height: 100vh;
}

.two-btn {
	display: flex;
}

.card-input {
	border: none !important;
}

.d-none {
	display: none !important;
}

.flex-auto {
	flex: auto;
}

.menu-list::-webkit-scrollbar {
	width: 4px;
}

.menu-list::-webkit-scrollbar-track {
	background-color: darkgrey;
}

.menu-list::-webkit-scrollbar-thumb {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.menu-list::-webkit-scrollbar-thumb:hover {
	background: #555;
}

::-webkit-scrollbar {
	width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.f-label {
	color: var(--lightgrey);
}

.schede-container {
	overflow-x: scroll;
}

.schede-container::-webkit-scrollbar {
    display: none;
	-ms-overflow-style: none;  /* IE and Edge */
  	scrollbar-width: none;  /* Firefox */
}

/* .form-item-align-box input[type="text"],
.form-item-align-box input[type="date"],
.form-item-align-box input[type="email"],
.form-item-align-box input[type="tel"] {
	padding: 10px 20px;
	height: 50px;
} */

@media (max-width: 991px) {
	input[type="date"] {
		min-width: 96%;
	}

	.react-numeric-input {
		min-width: 100%;
	}
}

/* .form-item-align-box select {
	padding: 10px 20px;
	height: 50px;
} */

.form-last-btns .btn-last {
	padding: 10px 20px;
}

.head-bar {
	position: fixed;
	top: 0px;
	/* left: 21%; */
	right: 0px;
	background-color: #f5f5f5;  
	color: black;
	padding: 8px 1.5rem 10px 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 10;
}

.right-logo {
	display: flex;
	align-items: center;
}

.logo-mark {
	height: 30px;
	margin-left: 30px;
}

.search-fence {
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.search-fence input {
	border-radius: 6px;
}

/* .form-item-align-box input[type="text"] {
	padding: 10px 20px;
	height: 50px;
} */

/* .form-item-align-box select {
	padding: 10px 20px;
	height: 50px;
} */

.empty-container {
	border-radius: 10px;
	background: transparent;
	padding: 10%;

	min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.plus-btn {
	color: #83112f;
	font-size: 50px;
	border: none;
	background: transparent;
}

.plus-button:hover {
	cursor: pointer;
	border: none;
	background: transparent;
}

.add-section-btn {
	font-size: 40px;
}

.add-section-btn:hover {
	cursor: pointer;
}

.tooltip.tooltip-container > div.tooltip-inner {
	background-color: white !important;
	color: #606f88;
	font-size: 12px;
}

.tooltip.exclamation-tooltip > div.tooltip-inner {
	background: #3b4049;
	border: 1px solid #bdbdbd;
	padding: 20px 15px;
	max-width: 350px;
	border-radius: 16px;
	opacity: 1 !important;
}

.tooltip.exclamation-tooltip.show {
	opacity: 1 !important;
}

.tooltip.tooltip-container.show {
	opacity: 1 !important;
}

.tooltip.tooltip-container .tooltip-arrow::before {
	border-top-color: white !important;
}

.tooltip.exclamation-tooltip .tooltip-arrow::before {
	border-top-color: #3b4049 !important;
}

.file-load-card {
	background-color: white;
	width: 100%;
	padding: 10px 10px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	border: 0.5px solid rgba(35, 39, 56, 0.5);
	border-radius: 4px;
}

.file-load-card p {
	color: black;
	margin: 0px;
}

.head-bar i:hover {
	cursor: pointer;
}

.title-padding {
	padding-top: 0.5rem;
	padding-bottom: 0.3rem;
}

.modal-search {
	width: 25%;
}

.card-title-padding {
	padding: 10px 10px 10px 40px;
}

.card-table {
	padding: 40px;
}

.card-table table {
	margin-bottom: 0px !important;
}

.card-table td {
	padding: 0px !important;
}

.card-table.editable-card-table {
	padding: 40px 10px 40px 30px;
}

.slick-next:before,
.slick-prev:before {
	color: #41464b !important;
}

.input-checkbox {
	display: flex;
	align-items: center;
}

.input-checkbox input[type="checkbox"] {
	width: 16px;
	height: 16px;
	margin-right: 10px;
	accent-color: var(--primary);
}

.green-input {
	background-color: var(--green);
	border: 1px solid #bdbdbd;
}

.table-border thead {
	background: #f9f8f9;
}

.type-modal .modal-body {
	position: relative;
}

.fixed-wrapper {
	position: absolute;
	top: 90%;
	left: 5px;
}

.fixed-btn {
	position: fixed;
	border-radius: 6px;
	background-color: #83112f;
	padding: 10px;
	color: white;
}

.rmsc {
	--rmsc-h: 48px !important;
	--rmsc-radius: 0.5rem !important;
	/* --rmsc-border: rgba(108, 117, 125, 0.5) !important; */
}

/* .input-label {
    padding: 10px 20px;
    height: 50px;
}
*/

.input-radio {
	accent-color: var(--primary);
	display: flex;
	align-items: center;
	margin: 20px 0px;
}

.input-radio input {
	margin-right: 20px;
	width: 20px;
	height: 20px;
}

.input-radio.edit-mode {
	margin: 10px 0px;
}

.input-radio.edit-mode input[type="radio"] {
	/* The native appearance is hidden */
	appearance: none;
	-webkit-appearance: none;

	/* For a circular appearance we need a border-radius. */
	border-radius: 50%;

	/* The background will be the radio dot's color. */
	background: white !important;

	/* The border will be the spacing between the dot and the outer circle */
	border: 3px solid white !important;

	/* And by creating a box-shadow with no offset and no blur, we have an outer circle */
	box-shadow: 0 0 0 1px var(--edit-color) !important;
}

.input-radio.edit-mode input[type="radio"]:hover {
	border-width: 0;
}

.input-radio.edit-mode input[type="radio"]:checked {
	box-shadow: 0 0 0 1px var(--edit-color) !important;
	background-color: var(--edit-color) !important;
	border-width: 1rem;
}

.back-btn-1 {
	width: 30px;
	height: 30px;
	position: fixed;
	display: flex;
	align-items: center;
	background-color: #83112f;
	color: white;
	left: calc(21% + 1.5rem);
	top: 8px;
	z-index: 100;
	cursor: pointer;
}

.edit-content-container {
	background-color: white;
	border-radius: 15px;
	padding: 2rem;
}

/* .edit-content-container label {
	color: var(--edit-color) !important;
	border-color: var(--edit-color) !important;
	border-width: 2px !important;
} */

/* .edit-content-container select,
.edit-content-container input,
.edit-content-container button,
.edit-content-container textarea {
	border-color: var(--edit-color) !important;
	accent-color: var(--edit-color) !important;
}
 */
.edit-content-container button.btn-last {
	background-color: var(--edit-color) !important;
}

.edit-content-container .input-checkbox input[type="checkbox"]:before {
	border: 2px solid var(--edit-color);
	border-radius: 6px;
}

/* input[type=checkbox]:invalid+label::before {
    border-color: red;
} */

/* .edit-content-container .input-checkbox input[type="checkbox"]:checked:before {
    border: #1C4CBD !important;
    background-color: #1C4CBD !important;
} */

.edit-content-container .label-heading {
	border-bottom: 5px solid var(--edit-color);
}

.edit-content-container .label-heading .label-text {
	background-color: var(--edit-color);
}

.edit-content-container .label-text:after {
	/* filter: hue-rotate(262deg) brightness(5.44444); */
	background: url(./assets/images/lightblue-shape.png) no-repeat;
	background-size: contain;
}

.edit-content-container .table-border {
	border: 2px solid var(--edit-color);
}

.edit-content-container hr {
	opacity: 1;
	border-color: var(--edit-color);
}

.edit-content-container label.edit-subpage-title {
	font-size: 1rem;
	color: #6a6a6a !important;
	font-weight: 700;
}

.edit-content-container .edit-black-label {
	color: #828282 !important;
}

input.editable-input {
	border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

input.editable-input:disabled {
	border: none !important;
	background-color: white !important;
}

.edit-content-container .round-icon-btn img,
.edit-content-container .add-section-btn,
.edit-content-container button svg {
	color: var(--edit-color);
}

.user-alert {
	border-radius: 6px;
	padding: 10px 20px;
}

.user-alert label {
	font-size: 14px;
}

.user-alert.green {
	background-color: rgba(111, 207, 151, 0.1);
	border: 1px solid #219653;
}

.user-alert.green label {
	color: #219653 !important;
}

.user-alert label.link-label {
	font-weight: bold;
	text-decoration: underline;
	cursor: pointer;
}

.user-alert.red {
	background: #fff8f8;
	border: 1px solid #eb5757;
}

.user-alert.red label {
	color: #eb5757 !important;
}

.general-card {
	background-color: white;
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: 15px 20px;
	justify-content: space-between;
	border: 1px solid rgba(35, 39, 56, 0.25);
	border-radius: 5px;	

	margin-left: 12px;
	transition: all .5s ease-in-out;
}

.general-card:hover {
	border-left: 12px solid var(--primary);

}

.general-card .state-img img {
	border-radius: 50%;
	min-height: 68px;
	margin-right: 16px;
	min-width: 68px;
	max-width: 68px;
}

.general-card .general-card-type {
	font-size: 14px;
	color: var(--lightgrey);
}

.general-card .general-card-title {
	font-size: 16px;
	font-weight: 700;
	color: #151313;
}

.general-card .small-span {
	font-weight: 500;
	font-size: 14px;
	color: #151313;
}

.general-card .small-icon {
	width: 20px;
	height: 20px;
}

.general-card .reunit-value {
	font-weight: 600;
	font-size: 12px;
	color: #151313;
}

.sort-text:hover {
	color: var(--primary);
	cursor: pointer;
}

.dropdown-filter .filter-menu {
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.4s ease-out;
}

.dropdown-filter.dropdown-filter-open .filter-menu {
	max-height: 500px;
	transition: max-height 0.4s ease-in;
}

.img-comment {
	margin-top: 20px;
	font-size: 18px;
	font-weight: 500;
	color: var(--lightgrey);
	text-align: center;
}

/*avoid horizontal scroll*/
.overflow-x-hidden {
	overflow-x: hidden;
}

/*CSS STYLE FOR SAFARI */
select {
	overflow: hidden;
	overflow: -moz-hidden-unscrollable;
	-webkit-appearance: none;
	-moz-appearance: none;
	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")
		no-repeat !important;
	background-position: 97% !important;
	background-color: white !important;
}

.input-section {
	overflow: hidden;
	overflow: -moz-hidden-unscrollable;
	-webkit-appearance: none;
	-moz-appearance: none;
	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")
		no-repeat !important;
	background-position: 97% !important;
	background-color: white !important;
}

/*OVERRIDE SOME INPUTS IN CALCOLI : ALIGNMENT AND FONT-SIZE */

.inputText,
.inputText1 {
	font-size: 1rem !important;
	text-align: start !important;
}

/*ADD GENERAL PADDING FOR INPUT FIELDS*/
input,
.input-text,
.input-text-native,
select {
	padding: 0 0.5rem;
}

/*FIX-MARGIN-TOP*/
@media (max-width: 900px) {
	.mobile-margin {
		margin-top: 9rem !important;
	}
}


/*Mandatory field*/

.mandatory-field {
	color: red;
}


input[type=radio] {
	accent-color: var(--primary);
}
input[type=checkbox] {
	accent-color: var(--primary);
}


/*Accordion*/

.accordionHeader{
	border:1px solid #dee2e6 !important;
	border-radius: 0.375rem !important;
}

input[type="text"],
input[type="select"],
input[type="date"],
input[type="email"],
input[type="number"],
select{
	display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.btn-input {
	padding: .5rem 1rem;
	background-color: white !important;
    color: black;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
    border-radius: 6px !important;
    border: none !important;
    min-width: 150px;
    text-align: center;
}

/* UNIT EDIT MODE TAB*/

.tabs-title-min,
.tabs-title-min-red
 {
	height: 56px;
    background: #ffffff;
    border: 1px solid #c1c9d2;
    box-shadow: 0px 1px 2px rgba(55, 65, 81, 0.08);
    border-radius: 4px;
    text-align: center;
    margin-left: 10px;
    cursor: pointer;
    padding: .5rem;
    width: -moz-fit-content !important;
    width: fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    transition: all .2s ease;
}


.tabs-title-min.active {
    background: #DCEEFD !important;
    color: #2B6392 !important;
	border: 1px solid #2B6392;
}

.tabs-title-min-red.active {
    background:  rgb(246, 236, 236) !important;
    color: var(--primary) !important;
	border: 1px solid  var(--primary);
}

.tabs-title-min:hover {
    background: #DCEEFD !important;
    color: #2B6392 !important;
	border: 1px solid #2B6392;
}

.tabs-title-min-red:hover {
    background:  rgb(228, 194, 194) !important;
    color: var(--primary) !important;
	border: 1px solid  var(--primary);
}

.state-bar-red .state-btn-group-red {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
}


/*TOP BAR*/

.notifications .icon {
    position: relative;
    height: unset !important;
    width: unset !important;
    box-sizing: border-box;
	margin-right: 1rem;
}

.notifications .icon .image {
    height: 18px !important;
    width: 18px !important;
    cursor: pointer;
}

.notifications .icon .count {
    height: 15px !important;
    width: 15px !important;
    max-width: 15px !important;
    min-height: 15px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -2px;
    right: -30% !important;
    font-size: 10px;
    border-radius: 50%;
    background-color: var(--primary) !important;
    font-weight: 400;
    color: white;
}

.notifications .container .items .loader {
	background-color: transparent;
	position: unset !important;
}

.container .items .card {
    display: flex;
    border-bottom: 1px solid rgba(153, 153, 153, 0.3);
    justify-content: space-between;
 /*    background-color: #edf2fa; */
    font-size: 12px;
    cursor: pointer;
    padding: 6px;
}

.container .items .card .content {
	margin: unset;
    width:  100%;
    border-radius: 0px; 
    background-color: transparent; 
}

/* MULTISELECT */

.rmsc .dropdown-container {
    position: relative;
    outline: 0;
    background-color: var(--rmsc-bg);
    border: 1px solid var(--rmsc-border);
    border-radius: var(--rmsc-radius);
	z-index: 100;
}

.rmsc.disabled-multiselect .dropdown-container  {
	background-color: #f1f2f3 !important;
}

/* RIMUOVI ICONA DA DROPDOWN CUSTOM TOGGLE DI REACT BOOTSTRAP */
.btn-custom-export.dropdown-toggle::after {
	display: none;
}


.pointer-cursor {
	cursor: pointer;
}

.primary-color {
	color: var(--primary) !important;
	font-weight: bold;
}

.min-width-custom {
	
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	  
}

.table-view-custom-container {
	overflow-x: auto;
	background: transparent !important;

  }
  
  .table-view-custom-container::-webkit-scrollbar {
	display: none;

	  
  }
  
  .table-view-custom-container::-webkit-scrollbar-thumb {
	background-color: transparent;
  }
  

  .custom-export-sal{
	outline: none;
    border: 1px solid black;
	padding: 0.45rem 0.7rem !important;
  }

  .syne-font {
  font-family: 'Syne', sans-serif;
  font-weight: bold;
}


/* LANDING SLIDESHOW */

.tech-slideshow {
    display: block;
    height: 400px;
    position: relative;
    overflow: hidden;
}
  
.tech-slideshow > div {
    height: 1812px; /* Doppia altezza: 582px * 2 */
    width: 312px;
    background-repeat: repeat-y;
}
  
.tech-slideshow .mover-1, .tech-slideshow .mover-2 {
    background-size: 312px 906px; /* Mantiene la dimensione originale dell'immagine, ma viene ripetuta */
}

.tech-slideshow .mover-1 {
    animation: moveSlideshow 12s linear infinite;
}
  
.tech-slideshow .mover-2 {
    background-position: 0 -906px; /* Inizia alla metà dell'altezza */
    animation: moveSlideshow 12s linear infinite 7s; /* Aggiunge un ritardo per iniziare quando l'animazione della mover-1 è a metà strada */
}

.tech-slideshow:hover .mover-1,
.tech-slideshow:hover .mover-2 {
    animation-play-state: paused;
	cursor: pointer;
}

.tech-slideshow .mover-1,
.tech-slideshow .mover-2 {
    animation-play-state: running;
}

  
@keyframes moveSlideshow {
    100% { 
        transform: translateY(-50%);
    }
}


.small-screen {
    width: 40px !important;
    height: 40px !important;
}

.large-screen {
    width: 85px !important;
    height: 85px !important;
}


/* CONTENT CONTAINER */

.form-item-align-box,
.edit-content-container,
.content-container  {
	background-color: transparent !important;

}

.form-item-align-box-white,
.edit-content-container-white,
.content-container-white  {
	background-color: white !important;


}

.bg-sm-white{

}

.form-item-align-box-white,
.edit-content-container-white {
 	padding: 3rem 2rem;	
	border-radius: 5px;
	min-height: 400px
}


@media (max-width: 1300px) {

	.form-item-align-box-white  {
		padding:1rem;	
	}
}

.modal-content {
max-height: 500px;
overflow-y: scroll !important;
}


/* FOOTER MODAL*/

/* Applica stili solo su schermi con una larghezza di almeno 992px (punto di rottura per desktop in Bootstrap) */
@media (min-width: 992px) {
    .custom-modal-footer  {
        width: 50%; /* Imposta la larghezza del modale al 50% della viewport */
        max-width: none; /* Sovrascrive il max-width predefinito del modale in Bootstrap */
		min-width: 50% !important;

    }
}



/* REUSABLE CLASSES FOR REVIEW */
.center-modal  > .modal-dialog {
	margin: 0 auto !important;
	max-width: 1500px !important;
}

.input-min-height {
	min-height: 38px;
}

@media (max-width: 576px) {
    .modal-mobile-rm  {
       width: 90vw;
	   margin: 0 auto;
    }
}


/* MUI */

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed,
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
	color: var(--primary) !important;
}


/* TAG CHAT*/

.tag-chat-2 {
	BACKGROUND: #EEE;
    WIDTH: FIT-CONTENT;
    PADDING: 0.2rem 0.5rem;
    BORDER-RADIUS: 8%;
}

.input-custom-width {
	min-width: unset !important;
}

/* ATTIVITA TABLE*/

 .form-check-input-act-table > input {
	padding: 0 !important;
	margin: 0 !important;
} 

.table-act-head-cell {
	padding-left: 16px !important;
}

.multi-col-drop > div {
	column-count: 2;
    column-gap: 1rem;
}

.chat-card-name {
	display: inline-block;
   	width: 20ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	font-style: italic;
}

.notificationCard {
    width: 98% !important;
    margin: 0 auto .5rem auto;
    align-items: center;
    padding: 1rem 2rem;
    justify-content: space-between;
    border: 1px solid rgba(35, 39, 56, 0.1);
    border-radius: 5px;
    margin-left: 12px;
    transition: border .5s ease-in-out;
	padding: 1rem 1.5rem !important;
}

.notificationCard.unread {
	border-left: 12px solid var(--primary) !important;
}

.round-icon-btn-chatlist {
	width: 30px;
	height: 30px;
}

/*NASCONDI ICONA DAL DROPDWON NOTIFICHE */
.notification-bell-container  > .notifications > .container > .items > .card > .card-link > .content >.image {
	display: none;

}

/*NASCONDI ICONA DAL DROPDWON NOTIFICHE */
.notification-bell-container  > .notifications > .container > .items > .card > .card-link > .content >.message {
	width: 100% !important;

}

.MuiTabs-scrollButtons.Mui-disabled {
	opacity: 0.3;
}


.dynamic-text {
	color: #004b87;
}

.rm-home-module {
    background: rgb(255 255 255 / 11%);
    padding: 2rem 2rem;
    border-radius: 2rem;
	transition: all .5s ease;
}

.rm-home-module:hover {
    box-shadow: 10px 10px #0000006e;
}

.Typewriter__cursor{
	color: transparent !important;
}

.plan-features .plan-head-2 {
    display: flex;
    align-items: center;
    height: 64px;
}

.text-justify {
	text-align: justify;
}

.custom-overflow {
	overflow-x: auto;
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* Internet Explorer 10+ */
}

.custom-overflow::-webkit-scrollbar {
	width: 0;
	height: 0;
  }

  /* Nascondi la stanghetta di collegamento quando il nodo è collassato */
  .tree-node-collapsed > .tree-children {
	display: none;
  }
  
  .tree-node-expanded > .tree-children {
	display: block;
  }

  @media (max-width: 900px) {
	.sh-dash-mob {
		top: 110px;
		padding: 10px 0rem 10px 0rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 10;
	}
  }