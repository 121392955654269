.left-panel {
  width: 43%;
}

.left-background {
  background-image: url("../images/bg-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  z-index: 0;
}

.logo-div {
/*   background-image: url("../images/logo-bianco.svg");
 */  width: 132px;
  height: 62px;
  left: 5%;
  top: 10%;
  position: absolute;
}

.left-background {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.login-text {
  width: 390px;
  height: 52px;
  font-family: "Inria Serif";
  font-size: 32px;
  line-height: 38px;
  color: #ffffff;
  margin: auto;
}

.login-text2 {
  text-align: center;
  color: #ffffff;
  font-family: "Mulish";
}

.email-icon {
  padding-bottom: 10px;
  font-family: "Mulish";
}

.phone-icon {
  font-family: "Mulish";
}

@media (max-width:950px) {
 .left-panel {
  display: none;
 } 
}