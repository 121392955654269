.body {
   /*  background: #F5F5F5; */
   
    height: 100%;
    width: 100%;
}

.title {
   
    /* padding-bottom: 3%; */
   /*  font-family: 'Mulish'; */
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    margin-left: .5%;
    /* black */

    color: #151313;
}

.space {
    border: none !important;
    margin-left: 5% !important;
    margin-top: 3% !important;
    margin-bottom: 3% !important;
    width: 90% !important;
    text-align: start !important;
    padding-left: 10% !important;
    background: #FFFFFF !important;
    border-radius: 10px !important;

   /*  font-family: 'Mulish'; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}

.btn-list {
    height: 50px !important;
    color: #91939B !important;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.w-70 {
    width: 70% !important;
}

.w-50 {
    width: 50% !important
}

.menuList {
    position: fixed;
    z-index: 1000;
    background-color: #91939B;
}

.calcoli-mobile-menu {
    /* background-color: aqua; */
    position: fixed;
    top: 150px;
    left: 0px;
    height: 5%;
    width: 8%;
}


.react-numeric-input {
    /* width: 70%;
    Bad CSS
    CSS has to have your parent class.
    */
    height: 50px;
}

.spin {
    width: 100% !important;
    height: 100% !important;
    border: 1px solid #BDBDBD !important;
    border-radius: 6px !important;
}

.w-94 {
    width: 97% !important;
    /* border-radius: 6px; */
}

.mt-15 {
    margin-top: 15px;
}

.btn-list.space.active {
    color: #9CCBF2 !important;
    border-right: 10px Solid #9CCBF2 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.btn-list:focus {
    color: #9CCBF2 !important;
    border-right: 10px Solid #9CCBF2 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    display: flex;
    align-items: center;
}

.btnAvanti {
    margin-top: 20px;
}

.content {
    margin-top: 3%;
    width: 93%;
    border-radius: 6px;

    background-color: white;
}