.imp-chat-card {
    background-color: white;
    width: 100%;
    height: fit-content;
    padding: 2rem;
    border-radius: 16px;
    text-align: center;
    color: var(--lightgrey);
    transition: all .5s ease;
    border: 1px solid var(--lightgrey) ;
}

.imp-chat-card:hover {
    color:#606f88;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/*TAG*/
.tag-card {
    border-radius: 10px;
    background:white ;
    width: fit-content;
    padding: .5rem 2rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
}

/*CHECKLIST*/

.checklist-card {
    background:white ;
    width: 100%;
    padding: .5rem 2rem;
}

.checklist-card {
    background:white ;
    width: 100%;
    padding: .5rem 2rem;
    cursor:pointer;
    border-radius: 6px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    margin-bottom: 1rem;
}

/*NUOVA CHECKLIST*/

.new-checklist-btn-group {
    position: sticky;
    bottom: 5%;
    margin-right: 5%;
    display: flex;
    align-self: end;
}


/*CONTABILITA*/
.tooltipContabilita > .tooltip-inner {
    min-width: 300px;
    text-align: start;
    
}

.tableModal.modal {
    --bs-modal-width: 700px !important; 
}

.contabilita-table {
    max-width: 600px;
    overflow-x: auto;
}

.contabilita-table > table > thead {
    background-color: #eee;
}
.contabilita-table > table >  thead > tr > th {
    border: 1px solid lightgray !important;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.2;
    min-width: 100px;
}

.contabilita-table > table >  thead > tr > th:last-child {
    border: 1px solid lightgray !important;
    font-size: 16px;
    font-weight: normal;
    min-width: unset;
}

.contabilita-table > table > tbody {
    background-color: white !important; 

}

.contabilita-table > table > tbody > tr > td {
    border: 1px solid lightgray !important;
    padding: 1.5rem;
}



/*SQUADRE*/

.modalCustomHeader{
    background-image: url("../images/bg.png");
    background-position: center;
    background-size: cover;
    color:white;
}

.modalCustomHeader > .btn-close{
    filter: invert(1);
    opacity: 1;
}

.newParticipantCard {
    background: #eee;
    padding:.2rem 1rem;
    border-radius:10px;
}

.squadreModal.modal {
    --bs-modal-width: 600px !important; 
}

.squadreModal.modal {
    --bs-modal-width: 600px !important; 
}

/*REMOVE ELLIPSIS AND NO WRAP AND ADJUST MULTISELECT TO FIT ITS HEIGHT BASED ON ITS CONTENT*/

.dropdown-heading {
    height: fit-content !important;
    min-height: 38px;
}

.rmsc .dropdown-heading .dropdown-heading-value {
    overflow-y: auto !important;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    white-space: unset !important;
    flex: 1;
}

/*SQUADRA CARD*/

.squadra-card.card {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 1px;
    --bs-card-border-color: var(--bs-border-color-translucent);
    --bs-card-border-radius: 0.375rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(0.375rem - 1px);
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    padding:0
}

.text-truncation {
    max-width: 20ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}