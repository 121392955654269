/*OPTIONS*/

.alert-container {
    position: sticky;
    bottom: 5%;
}

.alert-options {
    background-color: rgb(24, 24, 25);
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
/*     height:fit-content;
 */    flex-wrap: wrap;
    height: unset !important;
}

.alert-link,
.alert-options a {
    color:white !important;
    text-transform: uppercase;
    font-size: 14px;
    text-decoration: underline transparent !important;
    transition: text-decoration .2s ease-in;

}

.alert-link:hover,
.alert-options a:hover {
    text-decoration: underline white !important;

}

/*MODAL DIV*/

.decorative-div {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left-div,
.right-div {
    border: .5px solid #cfd8df;
    height: 0;
    width: 40%;
    height:1px;
}

/*CHANGE VIEW TYPE BTN*/

.viewType {
    width: 32px;
    height: 32px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}

.viewType:hover {
    color: var(--primary);
}

.viewType.select {
    box-shadow: 0px 4px 4px rgba(245, 200, 200, 0.50);
    background: #FFF8F8;
}

.viewType.select i:hover {
    color: rgb(179, 42, 97);
}

.viewType.select i {
    color: var(--primary);
}

/*valutare se spostare in unità.css o se riutilizzabile in attività*/

.unit-container {
    background: #fff;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 5px;
    height: calc(74vh - 50px);
    width: 100%;
    overflow-y: auto;
    position: relative;
    overflow-x: hidden;
    z-index: 1;
}

.gantt-container {
    background: #fff;
   /*  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 6px;
    height: calc(74vh - 50px);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 1;
}

.gantt-container  div{
    overflow-x: scroll;
}

.gantt-container  div::-webkit-scrollbar {
    display: none;
}


.leaflet-container {
    width:100%;
    height:100%;
    background: white !important;
    z-index: 1 !important;


}

.planimetria-selection {
    z-index:99999;
    background-color: white;
    position: absolute;
    top:1rem;
    left: 50%;
    transform: translateX(-50%);
    height: 40px;
    padding: .5rem; 
    width: 50%;   
    border-radius: 4px;
    border: 1.5px solid rgba(180, 183, 200, 0.5);
}

/* MEDIA QUERY FOR BIGGER SCREENS */
@media (min-width:1700px) {

    .col-2-xxxl {
        width: 20%;
    }

    .col-10-xxxl {
        width: 80%;
    }
}


/*NUOVA ATTIVITA'*/

.inputs-container {
    margin: 1rem auto;
    background-color:white;
    border-radius:16px;
    height:fit-content;
    padding: 2rem;
    width: 96%;
}


@media (min-width: 992px){
    .inputs-container {
        padding:3rem;
        width: 98%;
    }
}

@media (min-width: 1200px){
    .inputs-container {
        padding: 3rem 5rem;
        width: 98%;
    }
}

.input-label {
    color: #606F88;
}

.input-size-reduce {
    width: 90%;
}

.addButton,
.addButton div {
    display: inline-block;
    width: 8% !important;
    margin-left: -.5rem;
    margin-top: 10px;
}
.addButton div svg {
    height: 2rem;
    color: black;
}

.leaflet-container-newattività {
    height: 300px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-top:.5rem;
    z-index: 1 !important;
}

.leaflet-container-newattività-fullscreen {
    min-height: 500px;
    height: unset;
}

.leaflet-container-modal {
    width:100%;
    height: 350px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-top:.5rem;
    z-index: 1 !important;
}

.allegati-container-newattività {
    height: 350px;
}


@media (min-width:567px){
    .input-size {
        width:48%;
    }

    .input-size-reduce {
        width: 40%;
    }

   /*  .inputs-container {
        margin: 2rem 0;
        padding: 2rem;
    } */
}

@media (min-width:767px){

  /*   .inputs-container {
        padding: 3rem 4rem;
    } */
}


/* GANTT CONTAINER*/
div.unit-container > div:has(._3eULf){
    height: calc(100% - 50px)
}

._3eULf{
    height: 100%;
}


/*NEW ATTIVITA' SELECT*/
/* .css-1s2u09g-control{
    min-height: 50px !important;
} */


/* TABS*/
.tab-bar-slc {
    background: white;
    border-radius: 5px;
    height: 9px;
    margin-top: 7px;
    width: 100%;
    z-index: 1;
}

.tab-bar-slc.active-tab-bar {
    background: #C08796;
    z-index: 2;
}


.edit-content-container-slc label {
    color: #C08796 !important;
    border-color: #C08796 !important;
    border-width: #C08796 !important;
}

.tab-container-slc label {
    color: #C08796 !important;
}

.tab-container-slc label.non-active {
    color: #bdbdbd !important
}

.tab-container-slc {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 130px;
    cursor: pointer;
}

.accordion-disabled-slc .accordion-header button::after{
    display: none;
}