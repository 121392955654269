
.ro-div {
    background: url(../images/bg-darker.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    border-radius: 1rem;
    padding: 2rem;

    background-blend-mode: darken; /* Scurisce l'immagine di sfondo */

}


.cardRoleRo {
    background-color: white;
    padding:.5rem 1rem;
    border-radius: .5rem;
    border:3px solid transparent;
    transition: all .5s ease;
    word-break: break-all;

}

.cardRoleRo {
    margin:1rem
}

.cardRoleRo.selected {
    margin:1rem;
    color:#58C5F4;
    border:3px solid #58C5F4;
}

.text-blue-ro {
    color: #111151  !important
}

.cardRoleRo img {
    width: 100%;
    max-width: 60px;
    max-height: 60px;
    margin-bottom: .2rem;
}


.cardChartRo {
    margin:1rem;
}

.cardChartRo img {
    max-width: 80px;
    max-height: 80px;
}



.custom-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 40px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 20px;
    outline: none;
  }
  
  .custom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .custom-slider::-webkit-slider-runnable-track {
    height: 40px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(270deg, #58C5F4 2.48%, #2F80ED 100%);
    border-radius: 20px;
  }
  
  