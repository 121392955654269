.unitàCard {
    background-color: #ffffff;
    width:98%;
    margin: 0 auto 1rem auto;
    height: 80px;
    padding:.8rem .5rem;
    position: relative;
    z-index: 0;

	display: flex;
	align-items: center;
	padding: 15px 20px;
	justify-content: space-between;
	border: 1px solid rgba(35, 39, 56, 0.25);
	border-radius: 5px;	

	margin-left: 12px;
	transition: border .5s ease-in-out;
}

.unitàCard:hover {
	border-left: 12px solid var(--primary);

}


/* .unitàCard::after {
    content:'';
    display: block;
    width:15px;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: rgb(24, 157, 155);
    z-index: 2;

} */

.img-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
}

.unit-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* CARD MAP*/



.unitàCardMap {
    display: inline-block;
    background-color: #fff;
    width: 98%;
    height: fit-content;
    padding: 1rem;
    border-bottom: 1px solid rgba(35, 39, 56, 0.1);
    color: black;

}

.img-circle-map {
    width: 50px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
}

/*CARD POPUP*/

.unitàCardPopup {
    background-color: #fff;
    width:fit-content;
    height: fit-content;
    color: black;
}

/*UNITà CARD MOBILE*/

.unit-card-mobile-container {
    display: flex;
    position: absolute;
    left: 50%;
    bottom:40px;
    z-index:9999999;
    width: 85% !important;
    transform:translateX(-50%) ;
    height: fit-content;
    
}


.unitàCardMapMobile {
    margin: .5rem 0;
    background-color: rgb(255, 255, 255);
    padding: .5rem;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, .1);
    margin-left: 1rem;
}
