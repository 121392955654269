.titleOTP-div {
  font-family: Mulish;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: auto;
}

.description-OTP-div {
  font-family: "Mulish";
  font-style: Medium;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.verify-Modal .modal-content {
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgba(189, 189, 189, 1);
}

.verify-Modal .modal-body {
  width: 90%;
  margin: auto;
}

.verify-description-div {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  display: flex;
  align-items: center;
  /* darkgray-text */
  color: #606f88;
  margin-bottom: 20px;
}
.confirm-btn-div {
  height: 44px;
  width: 100%;
  background: #bdbdbd;
  /* Gray 4 */

  border: 1px solid #bdbdbd;
  border-radius: 6px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  /* Gray 6 */

  color: #f2f2f2;
}
.resend-div {
  text-align: center;
  margin-top: 20px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  text-decoration-line: underline;

  /* darkgray-text */
  color: #606f88;
  cursor: pointer;
  margin-bottom: 25px;
}
#otp_input_error {
  border: 1px solid #eb5757 !important;
}
.opt_error_msg {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #ef5350;
  margin-top: 5px;
}
#active-submit {
  border: 1px solid #83112f;
  background: #83112f;
  border: 1px solid #83112f;
  border-radius: 6px;
}
.confirm-emailLink-div {
  background: #83112f;
  /* Gray 4 */

  border: 1px solid #bdbdbd;
  border-radius: 6px;
  text-align: center;
  width: 259px;
  height: 44px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: auto;
  margin-top: 10px;
  cursor: pointer;
  /* Gray 6 */

  color: #f2f2f2;
}
