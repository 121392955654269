.gestioneCard {
    background-color: #ffffff;
    width:98%;
    margin: 0 auto 1rem auto;
    height: 80px;
    padding:.5rem;
    position: relative;
    z-index: 0;

	display: flex;
	align-items: center;
	padding: 15px 20px;
	justify-content: space-between;
	border: 1px solid rgba(35, 39, 56, 0.25);
	border-radius: 5px;	

	margin-left: 12px;
	transition: border .5s ease-in-out;


}

.gestioneCard:hover {
	border-left: 12px solid var(--primary);

}

/* .gestioneCard::after {
    content:'';
    display: block;
    width:15px;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: rgb(20, 50, 182);
    z-index: 2;

} */

.items-container {
    display: flex;
}

.gestione-assignee {
    text-align: center;
    background-color: dodgerblue;
    width: 40px;
    height: 40px;
    padding: 1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
}

.assignee-dropdown {
    width: fit-content;
    border-radius: 6px;
    background-color: white;
    position: absolute;
    padding: 1rem;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    z-index: 100;
}