.scadenzario-card-container{
    position:relative;
    background-color: white;
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: 15px 20px;
	justify-content: space-between;
	border: 1px solid rgba(35, 39, 56, 0.25);
	border-radius: 5px;	

	margin-left: 12px;
	transition: all .5s ease-in-out;
}

.scadenzario-card-container:hover {
	border-left: 12px solid var(--primary);

}

.scadenzario-card-delete {
    position: relative;
}

.trans-div-event {
    z-index:1; 
    background-color:transparent;
    position: absolute;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
}

.cancel-btn {
    width: 40px;
    height: 40px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;   
}
