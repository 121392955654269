.support-container {
    border: 1px solid #dee2e6;
    border-radius: 6px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.support-container > span {
    background: #262d3c;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 50%;
    height: 35px;
    width: 35px;

    display: flex;
    align-items: center;
    justify-content: center;

}

.support-container > p {
    font-size: 1rem;
    font-weight: bold;
    color: #262d3c;
}

.support-article {
    padding: 0 1rem 1rem 1rem;
    color: #262d3c;
}

.support-article > h1 {
    font-size: 1.6rem;
    text-decoration: underline;

}

.support-article > h2 {
    font-size: 1.3rem;
    padding: 0;
    margin: 0;

    margin-top: 1.5rem !important;
    margin-bottom: 0.2rem !important;

    padding-top: 1.5rem !important;
    border-top: 1px solid #dee2e6;

}

.support-article > h3 {
    font-size: 1rem;
    text-transform: uppercase;
    padding: 0;
    margin: 0;

    margin-top: 1rem !important;
    text-decoration: underline;

}


.support-article > img {
    width: 100%;
    border: 1px solid #262d3c6e;
    border-radius: 4px;
    margin: 1rem auto;
}



.support-index {
    font-family: 'Urbanist', sans-serif !important;
}

.css-1bcfi89-MuiTreeItem-content .MuiTreeItem-label {
    font-family: 'Urbanist', sans-serif !important;

}

/* Ripristina lo stile di default per <ul> all'interno di .support-article */
.support-article > ul {
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
  
  /* Ripristina lo stile di default per <li> all'interno di .support-article */
  .support-article > ul > li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  .support-article > .subtitle {
    margin: .5rem 0 !important;
    font-size: 12px;
    color: #262d3c;
    text-transform: uppercase;

}
  

