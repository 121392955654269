.return-login {
  position: absolute;
  width: 140px;
  height: 38px;
  top: 7%;
  left: 48%;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 38px;
  /* identical to box height, or 238% */

  display: flex;
  align-items: center;

  color: #000000;
  cursor: pointer;
}
.recover-text {
  font-family: "Inria Serif";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  /* or 95% */

  display: flex;
  align-items: center;
  text-align: center;

  /* meta */

  color: #606f88;

  margin-bottom: 11px;
}
.recover-text2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;

  /* darkgray-text */

  color: #606f88;
}
.recover-title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
  margin-top: 40px;
}
.recover-subtitle {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* meta */

  color: #606f88;
  margin-top: 31px;
  margin-bottom: 8px;
}
.password-form {
  background: #ffffff;
  /* Gray 4 */

  border: 1px solid #bdbdbd;
  border-radius: 6px;
  height: 50px;
  width: 100%;
}
.recover-btn {
  margin-top: 38px;
  width: 100%;
  height: 44px;
  background: #83112f;
  border-radius: 6px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border: none;

  color: #ffffff;
}
.emailtypeError-div {
  height: 53px;
  width: 100%;
  background: #fff8f8;
  /* Red */

  border: 1px solid #eb5757;
  border-radius: 6px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Red */

  color: #eb5757;
  margin-top: 16px;
}

.resend-code {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  text-decoration-line: underline;

  /* darkgray-text */

  color: #606f88;

  cursor: pointer;
}
@media (max-width: 950px) {
  .return-login {
    left: 10%
  }
}
@media (max-width: 600px) {
  .recover-text {
    font-size: 24px;
  }
  .recover-div {
    margin-left: 20px;
    margin-right: 20px;
  }
  .return-login {
    left: 5%
  }
}