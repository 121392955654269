.owner-btn {
  background: var(--primary);
  color: var(--white);
  padding: 10px 30px;
}

.right-full-pop {
  left: 12%;
  top: 10%;
}

.modal-fullscreen {
  width: 75vw;
  height: 85vh;
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 900px) {
  .modal-fullscreen {
    width: 90vw;
  }
}

.form-tab-main li .nav-link {
  font-size: 14px;
  color: var(--darkgrey) !important;
}

.form-tab-main li .nav-link.active,
.form-tab-main li .nav-link:hover {
  color: var(--primary) !important;
  border-color: var(--primary);
}
.loader {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #151414a8;
  z-index: 999;
  position: fixed;
}
.loading-bar {
  position: fixed;
  z-index: 1000;
  top: calc(50% - 30px);
  right: calc(50% - 30px);
}
.label-heading {
  border-bottom: 6px solid var(--primary); 
}
/* .label-heading1 {
  border-bottom: 5px solid var(--primary);
  border-radius: 0% 10px 10px 0%;
} */

.label-heading .label-text {
  background: var(--primary);
  border-radius: 6px 0 0 0;
}

.label-text:after {
  content: "";
  position: absolute;
  background: url(../images/decorazione.svg) no-repeat;
  width: 45px;
  height:38px;
  background-size: contain;
  right: -39px;
  bottom: 0;
  top: 0;
}

.form-last-btns .btn-last {
  padding: 10px 20px;
}

.back-btn {
  color: black;
  background: white !important;
}

.back-btn:hover {
  color: white;
  background: var(--light);
}

.back-btn:active {
  color: white;
  background: var(--primary);
}

.light-btn {
  color: var(--white);
  background: var(--light);
}

.next-btn {
  background: var(--primary);
  color: var(--white);
}

.popup-green {
  border: 2px solid var(--lightgreen);
}

.green-popup-header {
  background: var(--lightgreen);
}

.acc-table-box {
  max-width: 665px;
}

.acc-list-table thead th {
  background: #eaeaea;
}

.popup-yellow {
  border: 2px solid var(--yellow);
}

.m-37{
  margin-left: 37px !important;
  margin-right: 37px !important;
}

.colo-list{
	background-color: white;
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: 15px 20px;
	justify-content: space-between;
	border: 1px solid rgba(35, 39, 56, 0.25);
	border-radius: 5px;	

	margin-left: 12px;
	transition: all .5s ease-in-out;
}

.colo-list:hover {
	border-left: 12px solid var(--primary);

}

.flex-shrink-0{
  cursor: pointer;
}
.mt-46{
  margin-top: 46px !important;
}
.yellow-popup-header {
  background: var(--yellow);
}

.popup-pink {
  border: 2px solid var(--pink);
}

.pink-popup-header {
  background: var(--pink);
}

.popup-blue {
  border: 2px solid var(--lightblue);
}

.blue-popup-header {
  background: var(--lightblue);
}

.edit-delete-btns a,
.edit-delete-btns button {
  background: #f1f2f3;
  display: grid;
  place-items: center;
}

.modi-btn {
  background: var(--lightgrey);
  border: 1px solid var(--lightgrey);
}

.confirm-btn {
  background: var(--edit-color);
}

.mid-tabs-box .nav-item .nav-link {
  font-size: 14px;
  color: var(--darkgrey);
  border: 1px solid var(--lightgrey);
  border-radius: 6px;
  padding: 15px;
  background: var(--white);
  margin-right: 10px;
}

.mid-tabs-box {
  border: none;
}

.mid-tabs-box .nav-item .nav-link.active,
.mid-tabs-box .nav-item .nav-link:hover {
  background: var(--primary);
  color: var(--white);
  border-color: var(--primary);
}

/* .tab-content {
  background: var(--white);
  border-radius: 10px;
  padding: 25px 35px;
  margin-top: 25px;
} */

.create-Modal .modal-content {
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgba(189, 189, 189, 1);
}

.create-Modal .modal-body {
  width: 90%;
  margin: auto;
}

.create-Modal .modal-dialog {
  max-width: 65% !important;
}

.form-item-align-box {
  /* margin-top: 20px !important; */
  /* width: 90%; */
  margin: auto;
/*   padding-left: 2rem ;
  padding-right: 2rem; */
}
.table-correnti tr {
  border: none;
  padding: 20px;
  height: 25px;
}

.table-correnti td {
  padding-top: 0px;
  padding-bottom: 0px;
}

.tariffari-link-div {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline !important;
  cursor: pointer;
  /* meta */

  color: #83112f;
}

.tarrafari-table td {
  padding: 10px 8px !important;
  border: 0.5px solid #eaeaea !important;
}

.tarrafari-table th {
  padding: 10px 8px !important;
  background: #f9f8f9;
  border: 0.5px solid #eaeaea !important;
}

.tarrafari-Modal .modal-content {
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgba(189, 189, 189, 1);
}

.tarrafari-Modal .modal-body {
  width: 90%;
  margin: auto;
}

.tarrafari-Modal .modal-dialog {
  max-width: 80% !important;
}

.newTarrifa-Modal .modal-content {
  height: 700px;
  border-radius: 10px;
  border: 1px solid rgba(189, 189, 189, 1);
}

.newTarrifa-Modal .modal-body {
  width: 90%;
  margin: auto;
}

.newTarrifa-Modal .modal-dialog {
  max-width: 60% !important;
}

/* .form-item-align-box input[type="number"] {
  padding: 10px 20px;
  height: 50px;
} */

.tabs-title {
  height: 56px;
  background: #ffffff;
  border: 1px solid #c1c9d2;
  box-shadow: 0px 1px 2px rgba(55, 65, 81, 0.08);
  border-radius: 4px;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
  padding: 1rem;
  width: fit-content !important;
  height: fit-content !important;
  transition: all .2s ease;
}

.tabs-title.active {
  background: var(--edit-color);
  color:white;
}

.tabContent-div {
/*   background: #ffffff;
 */  border-radius: 16px;
  /* padding: 40px; */
}



@media (max-width:900px){
  .tabContent-div {
/*     background: #ffffff;
 */    border-radius: 16px;
    padding: 2rem 1rem;
  }
}

.tabs-select {
  height: 53px;
  width: 100%;
  max-width: 400px;
  padding: 10px;
  padding-left: 20px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
  background-size: 30px;
}

.tabs-select-div {
  display: none !important;
}

@media (max-width: 900px) {
  .newownerBtn-div {
    flex-direction: row-reverse;
  }
}

@media (max-width: 768px) {
  .tab-content {
    padding: 15px;
  }

  .form-item-align-box {
    /* margin-top: 20px !important; */
    /* width: 90%; */
    margin: auto;
/*     padding:2rem 1rem
 */  }
}

.tabs-title-div {
  display: flex;
}

@media (max-width: 1024px) {
  .tabs-title-div {
    display: none;
  }

  .tabs-select-div {
    display: inline !important;
  }
}