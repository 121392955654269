* {
    box-sizing: border-box;
}

.card-bacheca-container {
    padding: 0;
    background-color: white;
    border-radius: 10px ;
    border: 2px solid rgba(35, 39, 56, 0.1);
    width:49%;
    min-height: 300px;
    border: 2px solid #E9EAEC;
    border: 1.5px solid #E3EAF6;
    border-radius: 12px;
}

.card-bacheca-container .header {
    padding: .8rem 0;
    font-size: 14px;
    color:black;
    
    text-transform: uppercase;
}

.card-bacheca-container .header p {
    padding-left: 1rem !important;
}

.card-bacheca-container .body {
}


/* CARD ATTIVITA*/

.card-circle {
    font-size:.65rem;
    border-radius: 50%;
    padding:.58rem;
    color:white;
    min-width: 30px;
    max-height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-circle p {
    text-transform: uppercase;
}

.activity-name {
    max-width: 30ch;
    text-transform: capitalize !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.assigned-activity-bacheca{
    padding: .2rem 0;
    border-bottom: 1px solid rgba(205, 204, 204, .4);
}


@media (max-width:650px) {
    .card-bacheca-container  {
        width:100%;

    }

}

@media (max-width:600px) {

    .activity-name {
        max-width: 30ch;
    }
}

@media (max-width:410px) {

    .activity-name {
        max-width: 20ch;
    }
}

@media (min-width:900px) {

    .activity-name {
        max-width: 30ch;
    }
}

/* CARD GESTIONE*/

.card-gestione {
    padding:.4rem;
    border-radius: 4px;
    
    border-left: 6px solid rgb(100, 223, 188);
    border-top: 1px solid #E9EAEC;   
    border-bottom: 1px solid #E9EAEC;
    border-right: 1px solid #E9EAEC;  
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

}

@media (min-width:650px) and (max-width:715px) {
    
    .gestione-details {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 20ch;
    }
}

/* CARD UNITA'*/

.card-unità {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-right: .5rem;
    margin-left: .5rem;
    min-width: 100px;
    min-height: 150px;
    max-height: 150px;
    overflow-y: hidden;
    text-overflow: ellipsis;

}

.unit-img-container{
    height:80px;
    overflow: hidden;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

}

.unit-img-container img{
    object-fit: cover;
    height: 100%;
    width: 100%;
}


.unit-name {
    font-weight: bold !important;
    height: 45px; /* altezza del testo */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* numero di righe da visualizzare */
    -webkit-box-orient: vertical;
}

.unit-bottom{
    padding: .5rem;
}

/*CARD PERSONE*/

.card-persona {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-right: .5rem;
    margin-left: .5rem;
    min-width: 100px;

}

.person-circle{
    color: white;
    height:60px;
    width: 60px;
    overflow: hidden;
    background-color: #2F80ED;
    border-radius: 50%;
}

.person-bottom {
    padding: .6rem;
}

/*BUTTON BACHECA*/

.button-bacheca{
    background-color: white !important; 
    border: 1.5px solid #E3EAF6;
    border-radius: 10.6886px;
    color: #667085;   
    width: 100%;
    
}
.button-bacheca:hover,
.button-bacheca:active,
.button-bacheca:focus {
    border: 2px solid #E9EAEC;
    color: #667085;   
    
}


.card-att-bacheca {
    border-left: 6px solid rgb(146 54 150) !important;
}


