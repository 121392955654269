
.modulo {
    margin-bottom: 2rem;
}
.modulo > span {
   /*  background: #262d3c; */
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 50%;
    height: 35px;
    width: 35px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.traguardi-container {
    margin: 3rem 0;
}

/* .puntoForza {
    background: transparent;
}

.puntoForza1 {
    background: #afc3ee;
}

.puntoForza2 {
    background: #dde3f0;
}

.puntoForza3 {
    background: #f1f3f6;
} */

.traguardi-container {
    margin: 10rem 0;
}

.traguardi-container-small {
    margin: 6rem 0;
}

.traguardi-bg {
    background-image: url('https://images.pexels.com/photos/262438/pexels-photo-262438.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px
}

.traguardi-child {
    position: absolute;
    top: 30%;
}

.traguardo-box {
    padding: 2rem 3rem !important;
}

.traguardo-box > p {
    text-transform: uppercase;
}


.traguardo-box1 {
    background: rgb(156 167 183) !important;
}

.traguardo-box2 {
    background:  rgb(239 241 243) !important;
}

.modulo-feature > span {
    background: #262d3c;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 50%;
    height: 30px;
    width: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
}


.modulo-box {
    background: rgba(0, 0, 0, 0) linear-gradient(180deg, #5060ac 0%, #09133e 100%) 0% 0% no-repeat padding-box;
    color: var(--color-gray-lighter);
    border-radius: 1em;
    cursor: pointer;
    padding: 1em;
    position: relative;
    transition: all .2s ease-in-out;
}

.modulo-box-tech {
    background: rgba(0, 0, 0, 0) linear-gradient(180deg, #5076ac 0%, #09253e 100%) 0% 0% no-repeat padding-box;
}

.modulo-box:after {
    transition-property: height, border-radius;
    transition-duration: .2s;
    transition-timing-function: ease-out;
    content: "";
    display: block;
    height: 1em;
    border-radius: 0 0 1em 1em;
    left: 0;
    top: calc(100% - 1em);
    position: absolute;
    width: 100%;
    background: rgba(0, 0, 0, 0);
}

.modulo-parent {
    min-width: 310px;
    overflow: visible;
}

.modulo-box-description {
    /* width: 1000px; */
    background-color: #f5f5f5;
    border-radius: 0 1em 1em 1em;
    padding: 1em;
    position: relative;

}

/*PACCHETTI*/

.plans > .plan-features {
    padding-right: 0;
}



.plans .plan-features .feature:first-child {
    border-top: 1px solid #DCDCDC;
    border-bottom: 1px solid #DCDCDC;
}
.plan-features .feature:nth-child(odd) {
    background: transparent;
}

.plan-features .feature:nth-child(even) {
    background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.05)), to(rgba(255, 255, 255, 0.07)));
    background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.05) 0%, rgba(255, 255, 255, 0.07) 100%);
    background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.05) 0%, rgba(255, 255, 255, 0.07) 100%);
    background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.05) 0%, rgba(255, 255, 255, 0.07) 100%);
}

.plan-features .feature {
    width: 100%;
    min-height: 60px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.plan-features .plan-head {
    display: flex;
    align-items: center;
    height: 230px;
   
}

.plan-features .per-month {
    color: #0F1B4C;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 700;
}

.plan-container > .plan {
    border: 1px solid #0000001a;
    -webkit-box-shadow: 0 2px 5px 0 #0000001a;
    box-shadow: 0 2px 5px 0 #0000001a;
    border-radius: 10px;
    color: #0F1B4C;
    background-color: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.plan-container .plan .plan-head {
    padding: 15px 15px 0;
}

.plan-container .plan .plan-head h3 {
    font-weight: 900;
    line-height: 48px;
    margin-bottom: 0;
    padding-bottom: 0;
}

.plans .plan-container .plan p.subtitle {
    line-height: 20px;
    margin: 0 0 20px;
}

.plans .plan-container .plan p.subtitle.transparent {
    line-height: 20px;
    margin: 0 0 20px;
    color: transparent;
}

.plans .plan-container .plan .plan-head .prices .price {
    font-size: 30px;
    font-weight: 900;
    margin: 0 0 40px;
}

.plans .plan-container .plan .plan-head .prices .price.transparent {
    font-size: 30px;
    font-weight: 900;
    margin: 0 0 40px;
    color: transparent;
    height: unset;

}

.plans .plan-container .plan .plan-head .prices .price span {
    font-size: 18px;
}

.plan-container .plan .btn-container {
    display: block;
    height: 85px;
    margin-top: 1rem;

}

.plans .plan-container .plan .feature:nth-child(even) {
    background: rgba(0, 0, 0, 0.05);
}

.plan-container .plan .feature {
    font-size: 16px;
    font-weight: 400;
    padding: 0 15px;
    display: table;
    width: 100%;
    text-align: center;
    color: #000;
    line-height: 23px;
    min-height: 50px;
    padding: 15px 5px;
    margin: 0;
    min-height: 60px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.plans .plan-container .plan.premium {
    background-image: -webkit-linear-gradient(344.44deg, #003264 0%, #0064C8 84.28%);
    background-image: -o-linear-gradient(344.44deg, #003264 0%, #0064C8 84.28%);
    background-image: linear-gradient(105.56deg, #003264 0%, #0064C8 84.28%);
    color: #ffffff !important;
}

.plans .plan-container .plan.premium > .plan-features > .feature {
    color: #ffffff;
}

@media (min-width: 1200px){
    .plans .plan-container:nth-child(2) {
        padding-left: 0;
    }

    .plans .plan-container .plan .plan-head .prices .price.transparent {
        font-size: 30px;
        font-weight: 900;
        margin: 0 0 40px;
        color: transparent;
        height: 57px;
    
    }
}

/*BAND*/

.icon-band {
    background-color: transparent;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center; /* Centra verticalmente */
    height: 150px; /* Altezza della banda */
    
}

.icon-container {
    display: flex;
    animation: scroll-left 20s linear infinite;
}

@keyframes scroll-left {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%); /* Sposta solo la metà della larghezza totale */
    }
}

/* MODULO COLLEGATO */

.modulo-collegato {
    background: white;
    border-radius: 20px;
    border: 1px solid lightgray;
    padding: 2rem;
}

.custom-shape-divider-top-1715326080 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1715326080 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 68px;
}

.custom-shape-divider-top-1715326080 .shape-fill {
    /* fill: #FFFFFF; */
}


.competitors-table-td-padding {
    padding: 2rem !important; 
}