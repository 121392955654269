
table {
  width: 100%;
}
.contactDetails-table {
  margin-top: 20px;
}
.contactDetails-title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 20px;
  /* or 62% */

  color: #000000;
  margin-bottom: 48px;
  margin-top: auto;
  margin-bottom: auto;
}
