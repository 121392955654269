.newPassword-text {
  font-family: "Inria Serif";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  /* or 95% */

  display: flex;

  /* darkgray-text */

  color: #606f88;
}
.progressbar {
  margin-top: 18px;
  height: 5px;
  width: 100%;
}
.progressbar .progress-bar {
  border-radius: 2px;
}

.progressbar[type="1"] .progress-bar {
  background-color: #ef5350;
}
.progressbar[type="2"] .progress-bar {
  background-color: #ff981d;
}
.progressbar[type="3"] .progress-bar {
  background-color: #4daf56;
}

.progress-description[type="1"] {
  color: #ef5350;
}
.progress-description[type="2"] {
  color: #ef5350;
}
.progress-description[type="3"] {
  color: #4daf56;
}

.progress-description {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #606f88;
  margin-top: 12px;
}
.confirm-subtitle {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  /* meta */

  color: #606f88;
  margin-top: 16px;
  margin-bottom: 8px;
}
.newPassword-btn {
  width: 100%;
  height: 44px;
  border: none;
  background: rgba(131, 17, 47, 0.5);
  border-radius: 6px;
  font-family: "Inria Serif";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}
#readyNew-btn {
  background: #83112f;
}
.success-verify .modal-content {
  height: 418px;
  border-radius: 10px;
  border: 1px solid rgba(189, 189, 189, 1);
}

.success-verify .modal-dialog {
  max-width: 735px !important;
}
.success-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */

  /* black */

  color: #3a3033;
}
.success-text2 {
  margin-top: 29px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* black */

  color: #3a3033;
}
.passwordIcon {
  right: 15px;
}
.createNewPass-div {
  padding-left: 5%;
  padding-right: 5%;
}
@media (max-width: 400px) {
  .createNewPass-div {
    width: 100%;
  }
}