.chip-rm-container {
    background: rgb(203, 203, 204);
    border-radius: 6px;
    color: rgb(33, 34, 41);
    padding: .2rem .6rem .25rem;
    line-height: 0.98;
    margin-right: .5rem;
    cursor: pointer;
    min-width: fit-content;

}

.chip-rm-container-parent {
    min-width: fit-content;
}

.chip-rm-container.currentActive {
/*     background: linear-gradient(180deg, #2C2F84 0%, #126FB6 100%);
 */    color: white;
    background: linear-gradient(180deg, #262d3c 0%, #5c7194 100%);
}

.chip-rm-container > small {
    font-size: 10px;
    margin-bottom: 0.2px;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    text-transform: capitalize;
}

.chip-rm-container > p {
    font-size: 14px;
    font-weight: bold;
    margin-top: 0;
}

.close-chip-rm-btn {
    position:absolute;
    top: -10px;
    right: 1px;
    cursor: pointer;

}