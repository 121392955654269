

.mebtn {
  border-radius: 5px ;
  background: #fff;
  color: #000;
  border: 2px solid #ced4da;
  padding: .2rem .5rem;
}

.mebtn.active {
  background: #606f88; 
  color: #fff;
  border: none;
}

.mebtn.active-membri {
  background: #606f88; 
  color: #fff;
  border: none;
  border-radius: 5px 0 0 5px ;
  

}

.active-rel {
  border: 2px solid #ced4da;

}

.option-memb {
  position: absolute;
  top: 35px;
  right: 0;
}
.option-memb label {
  font-size: 14px;
}
.drop-mem {
  border-radius: 0 6px 6px 0;
  background: #fff;
}
.top-filter .active .drop-mem {
  background: #606f88;
  color: #fff;
}
.top-filter .active a {
  background: #606f88;
  border: 2px solid #606f88;
}
.midloop-table tbody::before {
  line-height: 1em;
  content: ".";
  color: white;
  display: block;
}
.midloop-table {
/*   border-collapse: separate;
 */  border-spacing: 1rem 0;
  cursor: pointer;
  border-radius: 5px;
}

.midloop-table thead {
  border: 1px solid #ced4da;
  border-radius: 5px !important;
}

.midloop-table thead  tr {
  border-radius: 5px !important;
}

.midloop-table tbody tr {
  margin-bottom: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px !important;
}


.drop-table tr th {
  color: #828282;
  font-size: 16px;
  background: var(--white);
  padding: 20px;
}
.drop-table tr th:first-child,
.midloop-table tr td:first-child,
.midloop-table .five-box tr td:nth-child(2) {
  border-radius: 5px 0 0 5px;
}
.drop-table tr th:last-child,
.midloop-table tr td:last-child {
  border-radius: 0 5px 5px 0;
}
.midloop-table tr td {
  color: #333;
  font-size: 16px;
  background: var(--white);
  padding: 10px;
}
.midloop-table tfoot tr td {
  background: #d0e1ef;
  color: #333;
  font-size: 16px;
  padding:1rem;
}

.midloop-table tbody tr {
  margin-bottom: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px !important;
}
.midloop-table .five-box tr td:first-child {
  background: transparent;
}
.midloop-table tfoot::before {
  line-height: 1em;
  content: ".";
  color: white;
  display: block;
}
.updwon-icon {
  position: absolute;
  top: 22px;
  left: -33px;
}
.name-al {
  display: grid;
  place-items: center;
}
.color1 {
  background: var(--primary);
}
.bg-green {
  background: #78ab8b;
}
.bal-box {
  background: var(--lightred);
}
.option-unit {
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: 10;
}
.date-filter-box input[type="date"],
.date-filter-box input[type="text"] {
  padding: 6px 0;
}
.date-filter-box .action-btn {
  background: var(--lightgrey);
}
.showrow-click {
  visibility: hidden;
  height: 0px;
  pointer-events: none;
  display: none;
}
.rate-half-box .modal-content {
  background: #f5f5f5;
}
.modal-90w {
  max-width: 90vw;
}
.date-rata-impor {
  border: 3px solid #d0e1ef;
}
.monthRateU-btn {
  border: 3px solid #d0e1ef !important;
  border-radius: 6px !important;
}
.dri-heading {
  position: absolute;
  top: -20px;
  z-index: 4;
  padding-bottom: 0px !important;
}
.rata-option-btn {
  background: #d0e1ef;
}
.top-edit-print-chat-btn button {
  border: 3px solid var(--lightggrey);
}
.input-file-box {
  display: grid;
  place-items: center;
  height: 300px;
  width: 100%;
}
.input-file-box .fal {
  background: var(--lightggrey);
  color: #728cbf;
  line-height: 54px;
}
.input-file-box .file-box {
  position: absolute;
  padding: 21%;
  cursor: pointer;
  z-index: 4;
  opacity: 0;
}
.cehck-row-full label {
  width: 100%;
  cursor: pointer;
}
.total-bal-green {
  border: 2px solid #219653;
  color: #219653;
  background: #e0fdec;
}
.text-green {
  color: #219653;
}
.filter-mob {
  top: 40px;
}
.blue-text-box h6 {
  background: #e6f3ff;
  color: #0085ff;
}
.balance-box {
  background: #dc5353;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.saldo-rate {
  background: #DC5353;
  border-radius: 10px;
  padding: 0.5rem;
}

.drop-rate,
.rate-card {
  border: 1px solid #ced4da;
  border-radius: 5px;
}

@media(max-width:767px ){
  .midloop-table tr td,
  .midloop-table tfoot tr td,
  .drop-table tr th  {

    padding: 10px .2rem !important;
  }

  .updwon-icon {
    position: absolute;
    top: 12px;
    left: -30px;
    color:#000;
}

}


.rateColor1 {
  background-color: rgb(22, 22, 80);
}

.rateColor2 {
  background-color: rgb(15, 114, 111);
}

.rateColor3 {
  background-color: rgb(167, 88, 19);
}