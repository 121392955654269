.support-article > .tips {
    padding: 1rem;
    border-radius: 20px;
    margin: 1rem 0;
    font-size: 14px;

}

.tips-pro {
    padding: 1rem;
    border-radius: 20px;
    margin: 1rem 0;
    font-size: 14px;

}

.tips-pro  > div {
    display: flex;
    align-items: center;
}

.tips-pro  > div > p {
    text-transform: uppercase;
    margin-left: 6px !important;
}

.tips.suggestion {
    background: #fff4d1;
    color:    #96620d
}

.tips.automatization {
    background: #e2f7e3;
    color: #1c7d1e;
}

.tips.info {
    background: #f7e2e5;
    color: #7d1c39;
}

.support-article > .tips  > div {
    display: flex;
    align-items: center;
}

.support-article > .tips  > div > p {
    text-transform: uppercase;
    margin-left: 6px !important;
}