.register-div {
  display: flex;
  justify-content: space-around;
  background: #fff;
  padding-bottom: 60px;
  padding-top: 60px;
  min-height: 90vh;
}
.register-logo {
  position: absolute;
  top: 50px;
  left: 3%;
}
.register-title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 38px;
  text-align: center;
  color: #606f88;
  margin-bottom: 30px;
}
.tabs-content-div {
  width: 1015px;
  margin: auto;
}
@media (max-width: 1000px) {
  .register-logo {
    width: 76px;
  }
  .register-title {
    font-size: 24px;
    margin-top: 45px;
  }
  .tabs-content-div {
    width: 100%;
  }
}